export const ClockSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 64 64"
  >
    <g filter="url(#filter0_ii_115_10591)">
      <path
        fill="url(#paint0_linear_115_10591)"
        d="M7.784 32.865C7.784 19.49 18.626 8.648 32 8.648S56.216 19.49 56.216 32.865C56.216 46.239 45.374 57.08 32 57.08S7.784 46.239 7.784 32.865z"
      ></path>
    </g>
    <g filter="url(#filter1_ii_115_10591)">
      <path
        fill="url(#paint1_linear_115_10591)"
        fillRule="evenodd"
        d="M7.957 32.865C7.957 46.143 18.72 56.908 32 56.908s24.043-10.765 24.043-24.043C56.043 19.585 45.28 8.82 32 8.82S7.957 19.586 7.957 32.865zM32 8.648c-13.374 0-24.216 10.842-24.216 24.217C7.784 46.239 18.626 57.08 32 57.08s24.216-10.842 24.216-24.216C56.216 19.49 45.374 8.648 32 8.648z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter2_d_115_10591)">
      <path
        fill="#E0E0E0"
        d="M30.27 13.838a1.73 1.73 0 113.46 0 1.73 1.73 0 11-3.46 0z"
      ></path>
    </g>
    <g filter="url(#filter3_d_115_10591)">
      <path
        fill="url(#paint2_linear_115_10591)"
        fillRule="evenodd"
        d="M30.443 13.838a1.557 1.557 0 103.114 0 1.557 1.557 0 00-3.114 0zM32 12.108a1.73 1.73 0 100 3.46 1.73 1.73 0 000-3.46z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter4_d_115_10591)">
      <path
        fill="#E0E0E0"
        d="M30.27 51.892a1.73 1.73 0 113.46 0 1.73 1.73 0 11-3.46 0z"
      ></path>
    </g>
    <g filter="url(#filter5_d_115_10591)">
      <path
        fill="url(#paint3_linear_115_10591)"
        fillRule="evenodd"
        d="M30.443 51.892a1.557 1.557 0 103.114 0 1.557 1.557 0 00-3.114 0zM32 50.162a1.73 1.73 0 100 3.46 1.73 1.73 0 000-3.46z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter6_d_115_10591)">
      <path
        fill="#E0E0E0"
        d="M49.297 32.865a1.73 1.73 0 113.46 0 1.73 1.73 0 11-3.46 0z"
      ></path>
    </g>
    <g filter="url(#filter7_d_115_10591)">
      <path
        fill="url(#paint4_linear_115_10591)"
        fillRule="evenodd"
        d="M49.47 32.865a1.557 1.557 0 103.114 0 1.557 1.557 0 00-3.114 0zm1.557-1.73a1.73 1.73 0 100 3.46 1.73 1.73 0 000-3.46z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter8_d_115_10591)">
      <path
        fill="#E0E0E0"
        d="M11.243 32.865a1.73 1.73 0 113.46 0 1.73 1.73 0 01-3.46 0z"
      ></path>
    </g>
    <g filter="url(#filter9_d_115_10591)">
      <path
        fill="url(#paint5_linear_115_10591)"
        fillRule="evenodd"
        d="M11.416 32.865a1.557 1.557 0 103.114 0 1.557 1.557 0 00-3.114 0zm1.557-1.73a1.73 1.73 0 100 3.46 1.73 1.73 0 000-3.46z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter10_bii_115_10591)">
      <path
        fill="#005EF5"
        fillOpacity="0.2"
        d="M29.405 5.621a2.595 2.595 0 115.19 0v27.676a2.595 2.595 0 11-5.19 0V5.621z"
      ></path>
    </g>
    <g filter="url(#filter11_bii_115_10591)">
      <path
        fill="url(#paint6_linear_115_10591)"
        fillRule="evenodd"
        d="M34.422 33.297V5.621a2.422 2.422 0 00-4.844 0v27.676a2.422 2.422 0 004.844 0zM32 3.027a2.595 2.595 0 00-2.595 2.594v27.676a2.595 2.595 0 105.19 0V5.621A2.595 2.595 0 0032 3.027z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter12_bii_115_10591)">
      <path
        fill="#005EF5"
        fillOpacity="0.2"
        d="M45.361 39.684a2.595 2.595 0 01-3.155 4.12L31.078 35.28a2.595 2.595 0 113.156-4.12l11.127 8.524z"
      ></path>
    </g>
    <g filter="url(#filter13_bii_115_10591)">
      <path
        fill="url(#paint7_linear_115_10591)"
        fillRule="evenodd"
        d="M31.184 35.143l11.127 8.523a2.422 2.422 0 102.945-3.845L34.13 31.298a2.422 2.422 0 00-2.945 3.845zm14.66 8.178a2.595 2.595 0 00-.483-3.637L34.234 31.16a2.595 2.595 0 10-3.156 4.12l11.128 8.523a2.595 2.595 0 003.637-.481z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter14_d_115_10591)">
      <path
        fill="#E0E0E0"
        d="M26.81 32.865a5.19 5.19 0 0110.378 0 5.19 5.19 0 01-10.378 0z"
      ></path>
    </g>
    <g filter="url(#filter15_d_115_10591)">
      <path
        fill="url(#paint8_linear_115_10591)"
        fillRule="evenodd"
        d="M26.984 32.865a5.016 5.016 0 1010.032 0 5.016 5.016 0 00-10.032 0zM32 27.675a5.19 5.19 0 100 10.38 5.19 5.19 0 000-10.38z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_ii_115_10591"
        width="52.432"
        height="52.433"
        x="5.784"
        y="6.648"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow_115_10591"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect1_innerShadow_115_10591"
          result="effect2_innerShadow_115_10591"
        ></feBlend>
      </filter>
      <filter
        id="filter1_ii_115_10591"
        width="52.432"
        height="52.433"
        x="5.784"
        y="6.648"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow_115_10591"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect1_innerShadow_115_10591"
          result="effect2_innerShadow_115_10591"
        ></feBlend>
      </filter>
      <filter
        id="filter2_d_115_10591"
        width="7.459"
        height="7.459"
        x="28.27"
        y="11.108"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10591"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10591"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter3_d_115_10591"
        width="7.459"
        height="7.459"
        x="28.27"
        y="11.108"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10591"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10591"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter4_d_115_10591"
        width="7.459"
        height="7.459"
        x="28.27"
        y="49.162"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10591"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10591"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter5_d_115_10591"
        width="7.459"
        height="7.459"
        x="28.27"
        y="49.162"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10591"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10591"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter6_d_115_10591"
        width="7.459"
        height="7.459"
        x="47.297"
        y="30.135"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10591"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10591"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter7_d_115_10591"
        width="7.459"
        height="7.459"
        x="47.297"
        y="30.135"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10591"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10591"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter8_d_115_10591"
        width="7.459"
        height="7.459"
        x="9.243"
        y="30.135"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10591"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10591"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter9_d_115_10591"
        width="7.459"
        height="7.459"
        x="9.243"
        y="30.135"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10591"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10591"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter10_bii_115_10591"
        width="21.189"
        height="48.865"
        x="21.405"
        y="-4.973"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10591"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10591"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10591"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10591"
          result="effect3_innerShadow_115_10591"
        ></feBlend>
      </filter>
      <filter
        id="filter11_bii_115_10591"
        width="21.189"
        height="48.865"
        x="21.405"
        y="-4.973"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10591"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10591"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10591"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10591"
          result="effect3_innerShadow_115_10591"
        ></feBlend>
      </filter>
      <filter
        id="filter12_bii_115_10591"
        width="32.317"
        height="29.713"
        x="22.061"
        y="22.625"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10591"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10591"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10591"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10591"
          result="effect3_innerShadow_115_10591"
        ></feBlend>
      </filter>
      <filter
        id="filter13_bii_115_10591"
        width="32.317"
        height="29.713"
        x="22.061"
        y="22.625"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10591"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10591"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10591"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10591"
          result="effect3_innerShadow_115_10591"
        ></feBlend>
      </filter>
      <filter
        id="filter14_d_115_10591"
        width="14.378"
        height="14.378"
        x="24.811"
        y="26.676"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10591"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10591"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter15_d_115_10591"
        width="14.378"
        height="14.378"
        x="24.811"
        y="26.676"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10591"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10591"
          result="shape"
        ></feBlend>
      </filter>
      <linearGradient
        id="paint0_linear_115_10591"
        x1="9.363"
        x2="62.246"
        y1="12.281"
        y2="21.581"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5"></stop>
        <stop offset="1" stopColor="#3787FF"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_115_10591"
        x1="-2.152"
        x2="93.075"
        y1="-168.348"
        y2="-1.01"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_115_10591"
        x1="32"
        x2="32"
        y1="12.108"
        y2="14.799"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_115_10591"
        x1="32"
        x2="32"
        y1="50.162"
        y2="52.853"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear_115_10591"
        x1="51.027"
        x2="51.027"
        y1="31.135"
        y2="33.826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint5_linear_115_10591"
        x1="12.973"
        x2="12.973"
        y1="31.135"
        y2="33.826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint6_linear_115_10591"
        x1="28.841"
        x2="44.981"
        y1="0.039"
        y2="5.217"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="0.766" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint7_linear_115_10591"
        x1="49.15"
        x2="34.871"
        y1="41.876"
        y2="48.168"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="0.766" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint8_linear_115_10591"
        x1="32"
        x2="32"
        y1="27.676"
        y2="35.748"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
  </svg>
)
