export const WhatsAppSvg = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="16" stroke="#D4D4D4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47101 20.7514L8.16406 25.5023L13.0479 24.2276C14.3986 24.9599 15.912 25.3436 17.4501 25.3437H17.454C22.5312 25.3437 26.6642 21.2301 26.6663 16.1752C26.6673 13.7422 25.6972 11.4084 23.9694 9.6876C22.2416 7.96681 19.8978 7.00001 17.4538 7C12.3757 7 8.24344 11.1127 8.2414 16.1678C8.23943 17.7767 8.66355 19.3577 9.47101 20.7514ZM13.5538 22.733L13.274 22.5679L10.376 23.3243L11.1497 20.5124L10.9674 20.2241C10.2006 19.0093 9.79475 17.6034 9.79688 16.1687C9.79856 11.9671 13.2333 8.54883 17.4568 8.54883C21.6857 8.55053 25.1124 11.9647 25.1108 16.1747C25.109 20.3767 21.6742 23.7953 17.454 23.7953H17.451C16.0797 23.7954 14.7337 23.4285 13.5538 22.733Z"
      fill="#8C8C8C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1513 12.3347C14.979 11.9531 14.7973 11.9455 14.6333 11.939C14.4991 11.9332 14.3455 11.9336 14.1922 11.9336C13.9563 11.9397 13.7337 12.0437 13.5782 12.2203C13.3672 12.4496 12.7725 13.004 12.7725 14.1314C12.7725 15.2588 13.5974 16.3484 13.7124 16.5016C13.8274 16.6547 15.3048 19.0419 17.6445 19.9605C19.5889 20.7238 19.9846 20.572 20.4067 20.5338C20.8288 20.4957 21.7685 19.9798 21.9603 19.4445C22.1522 18.9092 22.1522 18.4507 22.0946 18.355C22.0371 18.2593 21.8836 18.2022 21.6534 18.0876C21.4231 17.973 20.2916 17.4186 20.0805 17.3421C19.8695 17.2657 19.716 17.2276 19.5625 17.457C19.409 17.6865 18.9682 18.2022 18.8337 18.355C18.6993 18.5077 18.5651 18.5272 18.3349 18.4126C18.1047 18.298 17.3632 18.056 16.4839 17.2753C15.7996 16.6679 15.3376 15.9178 15.2033 15.6884C15.069 15.459 15.1889 15.3351 15.3044 15.2209C15.4077 15.1182 15.5346 14.9534 15.6497 14.8196C15.7648 14.6858 15.8029 14.5902 15.8797 14.4374C15.9565 14.2846 15.918 14.1506 15.8605 14.036C15.8029 13.9214 15.3559 12.7879 15.1513 12.3347Z"
      fill="#8C8C8C"
    />
  </svg>
);
