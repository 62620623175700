export const VisionBoardSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_ii_359_3249)">
      <path
        d="M4.54053 10.3784C4.54053 8.58718 5.99258 7.13513 7.78377 7.13513H40.2162C42.0074 7.13513 43.4594 8.58718 43.4594 10.3784C43.4594 12.1696 42.0074 13.6216 40.2162 13.6216H7.78377C5.99258 13.6216 4.54053 12.1696 4.54053 10.3784Z"
        fill="url(#paint0_linear_359_3249)"
      />
    </g>
    <g filter="url(#filter1_ii_359_3249)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2162 7.26486H7.78377C6.06422 7.26486 4.67026 8.65883 4.67026 10.3784C4.67026 12.0979 6.06422 13.4919 7.78377 13.4919H40.2162C41.9357 13.4919 43.3297 12.0979 43.3297 10.3784C43.3297 8.65883 41.9357 7.26486 40.2162 7.26486ZM7.78377 7.13513C5.99258 7.13513 4.54053 8.58718 4.54053 10.3784C4.54053 12.1696 5.99258 13.6216 7.78377 13.6216H40.2162C42.0074 13.6216 43.4594 12.1696 43.4594 10.3784C43.4594 8.58718 42.0074 7.13513 40.2162 7.13513H7.78377Z"
        fill="url(#paint1_linear_359_3249)"
      />
    </g>
    <g filter="url(#filter2_d_359_3249)">
      <path
        d="M22.7026 31.2972C22.7026 30.6703 23.2109 30.1621 23.8378 30.1621C24.4647 30.1621 24.9729 30.6703 24.9729 31.2972V40.3783C24.9729 41.0052 24.4647 41.5135 23.8378 41.5135C23.2109 41.5135 22.7026 41.0052 22.7026 40.3783V31.2972Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter3_d_359_3249)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8432 40.3783V31.2972C24.8432 30.742 24.393 30.2918 23.8378 30.2918C23.2825 30.2918 22.8324 30.742 22.8324 31.2972V40.3783C22.8324 40.9336 23.2825 41.3837 23.8378 41.3837C24.393 41.3837 24.8432 40.9336 24.8432 40.3783ZM23.8378 30.1621C23.2109 30.1621 22.7026 30.6703 22.7026 31.2972V40.3783C22.7026 41.0052 23.2109 41.5135 23.8378 41.5135C24.4647 41.5135 24.9729 41.0052 24.9729 40.3783V31.2972C24.9729 30.6703 24.4647 30.1621 23.8378 30.1621Z"
        fill="url(#paint2_linear_359_3249)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter4_d_359_3249)">
      <path
        d="M24.3652 33.4299C23.9219 32.9866 23.9219 32.2678 24.3652 31.8245C24.8085 31.3812 25.5272 31.3812 25.9705 31.8245L33.5384 39.3925C33.9817 39.8358 33.9817 40.5545 33.5384 40.9978C33.0951 41.4411 32.3764 41.4411 31.9331 40.9978L24.3652 33.4299Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter5_d_359_3249)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4467 39.4842L25.8788 31.9163C25.4861 31.5236 24.8495 31.5236 24.4569 31.9163C24.0643 32.3089 24.0643 32.9455 24.4569 33.3381L32.0248 40.9061C32.4175 41.2987 33.0541 41.2987 33.4467 40.9061C33.8393 40.5134 33.8393 39.8769 33.4467 39.4842ZM24.3652 31.8245C23.9219 32.2678 23.9219 32.9866 24.3652 33.4299L31.9331 40.9978C32.3764 41.4411 33.0951 41.4411 33.5384 40.9978C33.9817 40.5545 33.9817 39.8358 33.5384 39.3925L25.9705 31.8245C25.5272 31.3812 24.8085 31.3812 24.3652 31.8245Z"
        fill="url(#paint3_linear_359_3249)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter6_d_359_3249)">
      <path
        d="M14.4242 39.3923C13.9809 39.8356 13.9809 40.5543 14.4242 40.9976C14.8675 41.4409 15.5863 41.4409 16.0296 40.9976L23.5975 33.4297C24.0408 32.9864 24.0408 32.2676 23.5975 31.8243C23.1542 31.381 22.4355 31.381 21.9922 31.8243L14.4242 39.3923Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter7_d_359_3249)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5058 33.3379L15.9378 40.9059C15.5452 41.2985 14.9086 41.2985 14.516 40.9059C14.1233 40.5132 14.1233 39.8767 14.516 39.484L22.0839 31.9161C22.4766 31.5234 23.1132 31.5234 23.5058 31.9161C23.8984 32.3087 23.8984 32.9453 23.5058 33.3379ZM14.4242 40.9976C13.9809 40.5543 13.9809 39.8356 14.4242 39.3923L21.9922 31.8243C22.4355 31.381 23.1542 31.381 23.5975 31.8243C24.0408 32.2676 24.0408 32.9864 23.5975 33.4297L16.0296 40.9976C15.5863 41.4409 14.8675 41.4409 14.4242 40.9976Z"
        fill="url(#paint4_linear_359_3249)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter8_bii_359_3249)">
      <path
        d="M5.83789 18.1622C5.83789 14.4929 5.83789 12.6582 6.9778 11.5183C8.11771 10.3784 9.95236 10.3784 13.6217 10.3784H34.3784C38.0477 10.3784 39.8824 10.3784 41.0223 11.5183C42.1622 12.6582 42.1622 14.4929 42.1622 18.1622V28.2163C42.1622 31.8856 42.1622 33.7202 41.0223 34.8601C39.8824 36 38.0477 36 34.3784 36H13.6217C9.95236 36 8.11771 36 6.9778 34.8601C5.83789 33.7202 5.83789 31.8856 5.83789 28.2163V18.1622Z"
        fill="url(#paint5_linear_359_3249)"
        fillOpacity="0.4"
      />
    </g>
    <g filter="url(#filter9_bii_359_3249)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3784 10.5081H13.6217C11.7834 10.5081 10.4177 10.5084 9.3685 10.6495C8.32307 10.79 7.61137 11.0682 7.06953 11.6101C6.5277 12.1519 6.24951 12.8636 6.10895 13.909C5.9679 14.9582 5.96762 16.3239 5.96762 18.1622V28.2163C5.96762 30.0546 5.9679 31.4203 6.10895 32.4694C6.24951 33.5149 6.5277 34.2266 7.06953 34.7684C7.61137 35.3102 8.32307 35.5884 9.3685 35.729C10.4177 35.87 11.7834 35.8703 13.6217 35.8703H34.3784C36.2168 35.8703 37.5824 35.87 38.6316 35.729C39.677 35.5884 40.3887 35.3102 40.9306 34.7684C41.4724 34.2266 41.7506 33.5149 41.8912 32.4694C42.0322 31.4203 42.0325 30.0546 42.0325 28.2163V18.1622C42.0325 16.3239 42.0322 14.9582 41.8912 13.909C41.7506 12.8636 41.4724 12.1519 40.9306 11.6101C40.3887 11.0682 39.677 10.79 38.6316 10.6495C37.5824 10.5084 36.2168 10.5081 34.3784 10.5081ZM6.9778 11.5183C5.83789 12.6582 5.83789 14.4929 5.83789 18.1622V28.2163C5.83789 31.8856 5.83789 33.7202 6.9778 34.8601C8.11771 36 9.95236 36 13.6217 36H34.3784C38.0477 36 39.8824 36 41.0223 34.8601C42.1622 33.7202 42.1622 31.8856 42.1622 28.2163V18.1622C42.1622 14.4929 42.1622 12.6582 41.0223 11.5183C39.8824 10.3784 38.0477 10.3784 34.3784 10.3784H13.6217C9.95236 10.3784 8.11771 10.3784 6.9778 11.5183Z"
        fill="url(#paint6_linear_359_3249)"
      />
    </g>
    <g filter="url(#filter10_d_359_3249)">
      <path
        d="M12.8109 18.4865C11.8257 18.4865 11.0271 17.6878 11.0271 16.7027C11.0271 15.7175 11.8257 14.9189 12.8109 14.9189H21.5676C22.5528 14.9189 23.3514 15.7175 23.3514 16.7027C23.3514 17.6878 22.5528 18.4865 21.5676 18.4865H12.8109Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter11_d_359_3249)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5676 15.0486H12.8109C11.8974 15.0486 11.1568 15.7892 11.1568 16.7027C11.1568 17.6162 11.8974 18.3567 12.8109 18.3567H21.5676C22.4811 18.3567 23.2217 17.6162 23.2217 16.7027C23.2217 15.7892 22.4812 15.0486 21.5676 15.0486ZM11.0271 16.7027C11.0271 17.6878 11.8257 18.4865 12.8109 18.4865H21.5676C22.5528 18.4865 23.3514 17.6878 23.3514 16.7027C23.3514 15.7175 22.5528 14.9189 21.5676 14.9189H12.8109C11.8257 14.9189 11.0271 15.7175 11.0271 16.7027Z"
        fill="url(#paint7_linear_359_3249)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter12_d_359_3249)">
      <path
        d="M12.8109 24.973C11.8257 24.973 11.0271 24.1744 11.0271 23.1892C11.0271 22.2041 11.8257 21.4055 12.8109 21.4055H31.6217C32.6068 21.4055 33.4055 22.2041 33.4055 23.1892C33.4055 24.1744 32.6068 24.973 31.6217 24.973H12.8109Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter13_d_359_3249)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6217 21.5352H12.8109C11.8974 21.5352 11.1568 22.2757 11.1568 23.1892C11.1568 24.1027 11.8974 24.8433 12.8109 24.8433H31.6217C32.5352 24.8433 33.2757 24.1027 33.2757 23.1892C33.2757 22.2757 32.5352 21.5352 31.6217 21.5352ZM11.0271 23.1892C11.0271 24.1744 11.8257 24.973 12.8109 24.973H31.6217C32.6068 24.973 33.4055 24.1744 33.4055 23.1892C33.4055 22.2041 32.6068 21.4055 31.6217 21.4055H12.8109C11.8257 21.4055 11.0271 22.2041 11.0271 23.1892Z"
        fill="url(#paint8_linear_359_3249)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter14_d_359_3249)">
      <path
        d="M12.8109 31.4595C11.8257 31.4595 11.0271 30.6608 11.0271 29.6757C11.0271 28.6905 11.8257 27.8919 12.8109 27.8919H17.6757C18.6609 27.8919 19.4595 28.6905 19.4595 29.6757C19.4595 30.6608 18.6609 31.4595 17.6757 31.4595H12.8109Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter15_d_359_3249)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6757 28.0216H12.8109C11.8974 28.0216 11.1568 28.7622 11.1568 29.6757C11.1568 30.5892 11.8974 31.3297 12.8109 31.3297H17.6757C18.5893 31.3297 19.3298 30.5892 19.3298 29.6757C19.3298 28.7622 18.5893 28.0216 17.6757 28.0216ZM11.0271 29.6757C11.0271 30.6608 11.8257 31.4595 12.8109 31.4595H17.6757C18.6609 31.4595 19.4595 30.6608 19.4595 29.6757C19.4595 28.6905 18.6609 27.8919 17.6757 27.8919H12.8109C11.8257 27.8919 11.0271 28.6905 11.0271 29.6757Z"
        fill="url(#paint9_linear_359_3249)"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_ii_359_3249"
        x="2.54053"
        y="5.13513"
        width="42.9189"
        height="10.4865"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3249"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3249"
          result="effect2_innerShadow_359_3249"
        />
      </filter>
      <filter
        id="filter1_ii_359_3249"
        x="2.54053"
        y="5.13513"
        width="42.9189"
        height="10.4865"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3249"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3249"
          result="effect2_innerShadow_359_3249"
        />
      </filter>
      <filter
        id="filter2_d_359_3249"
        x="20.7026"
        y="29.1621"
        width="6.27026"
        height="15.3513"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_359_3249"
        x="20.7026"
        y="29.1621"
        width="6.27026"
        height="15.3513"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_359_3249"
        x="22.0327"
        y="30.4921"
        width="13.8381"
        height="13.8383"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_d_359_3249"
        x="22.0327"
        y="30.4921"
        width="13.8381"
        height="13.8383"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter6_d_359_3249"
        x="12.0918"
        y="30.4918"
        width="13.8381"
        height="13.8383"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter7_d_359_3249"
        x="12.0918"
        y="30.4918"
        width="13.8381"
        height="13.8383"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter8_bii_359_3249"
        x="-2.16211"
        y="2.37842"
        width="52.3242"
        height="41.6216"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3249"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3249"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3249"
          result="effect3_innerShadow_359_3249"
        />
      </filter>
      <filter
        id="filter9_bii_359_3249"
        x="-2.16211"
        y="2.37842"
        width="52.3242"
        height="41.6216"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3249"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3249"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3249"
          result="effect3_innerShadow_359_3249"
        />
      </filter>
      <filter
        id="filter10_d_359_3249"
        x="9.0271"
        y="13.9188"
        width="16.3242"
        height="7.56763"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter11_d_359_3249"
        x="9.0271"
        y="13.9188"
        width="16.3242"
        height="7.56763"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter12_d_359_3249"
        x="9.0271"
        y="20.4054"
        width="26.3784"
        height="7.56763"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter13_d_359_3249"
        x="9.0271"
        y="20.4054"
        width="26.3784"
        height="7.56763"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter14_d_359_3249"
        x="9.0271"
        y="26.8918"
        width="12.4324"
        height="7.56763"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <filter
        id="filter15_d_359_3249"
        x="9.0271"
        y="26.8918"
        width="12.4324"
        height="7.56763"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3249"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3249"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_359_3249"
        x1="5.80962"
        y1="7.62162"
        x2="26.5387"
        y2="29.495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#3787FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_359_3249"
        x1="-3.44373"
        y1="-16.5697"
        x2="-0.654853"
        y2="12.8348"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_359_3249"
        x1="23.8378"
        y1="30.1621"
        x2="23.8378"
        y2="38.9909"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_359_3249"
        x1="24.3652"
        y1="31.8245"
        x2="31.4999"
        y2="38.9593"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_359_3249"
        x1="14.4242"
        y1="40.9976"
        x2="21.559"
        y2="33.8628"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_359_3249"
        x1="23.3514"
        y1="7.78382"
        x2="24"
        y2="37.4273"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0061FF" stopOpacity="0" />
        <stop offset="1" stopColor="#005EF5" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_359_3249"
        x1="1.8896"
        y1="8.04918"
        x2="15.2907"
        y2="46.653"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_359_3249"
        x1="11.0271"
        y1="16.7027"
        x2="20.6127"
        y2="16.7027"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_359_3249"
        x1="11.0271"
        y1="23.1892"
        x2="28.4325"
        y2="23.1892"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_359_3249"
        x1="11.0271"
        y1="29.6757"
        x2="17.5857"
        y2="29.6757"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
