import React, { useState, useContext, useCallback, useEffect } from 'react'
import { getFeedback } from '../../../network/getFeedback'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import './styles.scss'
import { Link } from 'react-router-dom'
import { Btn } from '../../ui/Btn/Btn'
import { LayoutContext } from '../../../contexts/LayoutContext/LayoutContext'
import { Select } from '../../ui/Select/Select'

export const Form: React.FC = () => {
  const LayoutState = useContext(LayoutContext)
  const initialState = {
    title: '',
    phoneNumber: '',
    email: '',
    companyName: '',
    companySize: '',
    comment: '',
    isChecked: false,
    isCaptchaComplete: false
  }
  const [state, setState] = useState(initialState)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      setState({ ...state, isCaptchaComplete: true })
    }
  }, [executeRecaptcha])

  useEffect(() => {
    void handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const {
      title,
      phoneNumber,
      email,
      companyName,
      companySize,
      comment
    } = state
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getFeedback(
      title,
      phoneNumber,
      email,
      companyName.trim().length ? companyName : 'unknown',
      companySize.trim().length ? companySize : 'unknown',
      comment
    ).then((res) => {
      const isSuccessResponse = res.status === 201
      console.log(isSuccessResponse)
      setIsSuccess(isSuccessResponse)
      setIsError(!isSuccessResponse)
    }, () => {
      setIsError(true)
    })
  }

  const handleClosePopup = () => {
    LayoutState?.setPopupIsOpen(false)
  }

  const handleResend = () => {
    setIsSuccess(false)
    setIsError(false)
    setState(initialState)
    void handleReCaptchaVerify()
  }

  return (
    <>
      {isSuccess
        ? (
            <div className="form-res form-res--success">
              <div className="form-res__title">Ваша заявка отправлена!</div>
              <Btn onClick={handleClosePopup}>Отлично</Btn>
            </div>
          )
        : isError
          ? (
            <div className="form-res form-res--error">
              <div className="form-res__title">Произошла ошибка!</div>
              <Btn onClick={handleResend}>Попробовать еще раз</Btn>
            </div>
            )
          : (
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__title">Обратный звонок</div>
              <div className="form__fields">
                <input
                  type="text"
                  name="name"
                  className="form__input"
                  placeholder="Имя*"
                  onChange={(e) => setState({ ...state, title: e.target.value })}
                />
                <input
                  className="form__input"
                  placeholder="Номер телефона*"
                  type="tel"
                  onChange={(e) =>
                    setState({ ...state, phoneNumber: e.target.value })
                  }
                />
                <>
                  <input
                    className="form__input"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email*"
                    onChange={(e) => setState({ ...state, email: e.target.value })}
                  />
                  <input
                    className="form__input"
                    placeholder="Название компании"
                    type="text"
                    name="company"
                    onChange={(e) =>
                      setState({ ...state, companyName: e.target.value })
                    }
                  />
                  <Select
                    placeholder="Размер компании"
                    options={[
                      { value: 'до 500', label: 'до 500' },
                      { value: 'от 500 до 3000', label: 'от 500 до 3000' },
                      { value: 'от 3000 и более', label: 'от 3000 и более' }
                    ]}
                    onChange={(e) =>
                      setState({ ...state, companySize: e.value })
                    }
                  />
                  <textarea
                    className="form__input"
                    placeholder="Комментарий"
                    style={{ height: '82px', resize: 'vertical', paddingTop: 8 }}
                    onChange={(e) => setState({ ...state, comment: e.target.value })}
                  />
                </>

                <div className="form__checkbox">
                  <input
                    id='consent'
                    type="checkbox"
                    checked={state.isChecked}
                    onChange={() =>
                      setState({ ...state, isChecked: !state.isChecked })
                    }
                  />
                  <label htmlFor="consent">
                    Согласен(на) на <Link to={'/privacy'} target="_blank">обработку персональных данных*</Link>
                  </label>
                </div>
                <Btn
                  mod='form'
                  type='submit'
                  disabled={
                    !(state.title.length &&
                    state.phoneNumber.length &&
                    state.email.length &&
                    state.isChecked &&
                    state.isCaptchaComplete)
                  }
                >
                  Отправить запрос
                </Btn>
                <div className="form__footnote">
                  Никогда не отправляйте через эту форму пароли или данные кредитной
                  карты.
                </div>
                <div className="form__footnote">
                  Этот веб-сайт защищен reCAPTCHA и к нему применяются <a href="https://policies.google.com/privacy?hl=ru" target="_blank" rel="noreferrer">политика конфиденциальности</a> и <a href="https://policies.google.com/terms?hl=ru" target="_blank" rel="noreferrer">условия предоставления услуг</a> Google.
                </div>
              </div>
            </form>
            )}
    </>
  )
}
