import React from 'react'
import classNames from 'classnames'
import './styles.scss'
import { H2 } from '../../ui/H/H'
import { Inner } from '../../layout/Inner/Inner'

interface Props {
  title?: string
  descr?: string
  list: any
  mod?: string
}
export const Risks: React.FC<Props> = ({ title, descr, list, mod }) => {
  const modifiers = mod?.split(',').map((item) => item.trim())
  const baseName = 'risks_container'
  const className = classNames(baseName, modifiers?.map((item) => (item.startsWith('--') ? `${baseName}${item}` : `${item}__${baseName}`)))

  return (
    <section className={className}>
      <Inner>
        <div className="risks_head">
          <H2 mod="risks">{title}</H2>
          {descr &&
            <div className="risks_description">{descr}</div>
          }
        </div>
        <div className="risks_list">
          {list.map((item: any, index: number) => {
            return (
              <div
                className="risks_render_container"
                style={index !== 0 ? { borderTop: '1px solid #DDDFE0' } : {}}
                key={index}
              >
                <div className="risks_render_container_item">
                  {item.svg
                    ? <div className="risks_render_container_svg">
                        {item.svg}
                      </div>
                    : <div className="risks_render_container_index">
                        {`0${index + 1}`}
                      </div>
                  }

                  <div className="risks_render_container_title">{item.title}</div>
                </div>
                <div
                  className="risks_render_container_content"
                >
                  {item.content}
                </div>
              </div>
            )
          })}
        </div>
      </Inner>
    </section>
  )
}
