import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useWindow } from '../../../hooks/useWindow'
import 'swiper/css'
import './style.scss'
import rabota from './rabota-ru.jpg'
import zarplata from './zarplata-ru.jpg'
import avito from './avito.jpg'
import hh from './hh.jpg'
import vk from './vk-rabota.jpg'
import superJob from './super-job.jpg'
import bg from './intergations-bg.svg'
import { H2 } from '../../ui/H/H'
import { Inner } from '../../layout/Inner/Inner'

export const Intergations: React.FC = () => {
  const windowWidth = useWindow()
  const data = [
    {
      name: 'Работа.ру',
      img: rabota
    },
    {
      name: 'Зарплата.ру',
      img: zarplata
    },
    {
      name: 'Аvito',
      img: avito
    },
    {
      name: 'HeadHunter',
      img: hh
    },
    {
      name: 'VK Работа',
      img: vk
    },
    {
      name: 'SuperJob',
      img: superJob
    }
  ]
  return (
    <section className="integrations">
      <Inner mod="integrations">
        <div className="integrations__bg"><img src={bg} alt="" /></div>
        <H2 mod="integrations">Наш сервис уже интегрирован с&nbsp;популярными сайтами по&nbsp;поиску работы</H2>
        <Swiper
          className="integrations__slider"
          spaceBetween={windowWidth > 767 ? 24 : 16}
          slidesPerView="auto"
        >
          {data.map((item, index) => <SwiperSlide className="integrations__item" key={index}>
            <div className="integrations__img"><img src={item.img} alt="" /></div>
            <div className="integrations__name">{item.name}</div>
          </SwiperSlide>)}
          <SwiperSlide className="integrations__item integrations__item--text"><div className="integrations__name">+ возможность подключения дополнительных источников по запросу</div></SwiperSlide>
        </Swiper>
      </Inner>
    </section>
  )
}
