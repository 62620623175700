export const BurgerSvg = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="3.3"
      y1="5.2"
      x2="20.7"
      y2="5.2"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <line
      x1="3.3"
      y1="12.887"
      x2="15.7"
      y2="12.887"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <line
      x1="3.3"
      y1="20.2"
      x2="20.7"
      y2="20.2"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
