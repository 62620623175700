export const TermsAndDefinition = () => (
  <div>
    <div className="privacy_content_title">Термины и определения</div>
    <div className="privacy_content">
      <div>
        <strong>Оператор</strong> – ООО «АКСИС ПРО», организующее и/или
        осуществляющее обработку персональных данных пользователей, а также
        определяющее цели обработки персональных данных, состав персональных
        данных, подлежащих обработке, действия (операции), совершаемые с
        персональными данными.
      </div>
      <div>
        <strong>Персональные данные</strong> – любая информация, относящаяся
        прямо или косвенно к определенному или определяемому пользователю
        Веб-сайта <a href="https://www.dors.online">www.dors.online</a>.
      </div>
      <div>
        <strong>Пользователи</strong> - работники Оператора, бывшие работники
        Оператора, кандидаты на замещение вакантных должностей у Оператора,
        родственники работников Операторов; клиенты и контрагенты Оператора
        (физические лица); представители/работники клиентов и контрагентов
        Оператора.
      </div>
      <div>
        <strong>Автоматизированная обработка персональных данных</strong> –
        обработка персональных данных пользователей с помощью средств
        вычислительной техники.
      </div>
      <div>
        <strong>Распространение персональных данных</strong> – действия,
        направленные на раскрытие персональных данных неопределенному кругу лиц.
      </div>
      <div>
        <strong>Предоставление персональных данных</strong> – действия,
        направленные на раскрытие персональных данных определенному лицу или
        определенному кругу лиц.
      </div>
      <div>
        <strong>Блокирование персональных данных</strong> – временное
        прекращение обработки персональных данных (за исключением случаев, если
        обработка необходима для уточнения персональных данных).
      </div>
      <div>
        <strong>Уничтожение персональных данных</strong> – действия, в
        результате которых становится невозможным восстановить содержание
        персональных данных в информационной системе персональных данных и (или)
        в результате которых уничтожаются материальные носители персональных
        данных.
      </div>
      <div>
        <strong>Обезличивание персональных данных</strong> – действия, в
        результате которых становится невозможным без использования
        дополнительной информации определить принадлежность персональных данных
        конкретному пользователю.
      </div>
      <div>
        <strong>Информационная система персональных данных</strong> –
        совокупность содержащихся в базах данных персональных данных и
        обеспечивающих их обработку информационных технологий и технических
        средств.
      </div>
    </div>
  </div>
)
