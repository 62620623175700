import React from 'react'
import { CickStarter } from '../../../svg/CickStarter'
import { InvestorSvg } from '../../../svg/InvestorSvg'
import { RentableSvg } from '../../../svg/RentableSvg'
import './styles.scss'
import { Inner } from '../../layout/Inner/Inner'
/** @Блок Партнерство по автоматизации ваших HR процессов */
export const Partner: React.FC = () => {
  const content = [
    {
      svg: () => <CickStarter />,
      title: 'Кикстартер',
      content:
        'Мы берем разработку или доработку ПО на себя. Вы платите символическую сумму за внедрение системы и обучение ваших пользователей и получаете большую скидку за лицензии 50%. В замен вы участвуете в разработке ПО в качестве заказчиков и Бетта-тестеров'
    },
    {
      svg: () => <InvestorSvg />,
      title: 'Инвестор',
      content:
        'Вы оплачиваете полноценную разработку функциональности и получаете ПО в бессрочное использование. '
    },
    {
      svg: () => <RentableSvg />,
      title: 'Арендатор',
      content:
        'Вы ничего не платите за разработку, а просто арендуете лицензии.'
    }
  ]
  return (
    <section className="partner_container">
      <Inner>
        <div className="partner_title">
          Партнерство по автоматизации ваших HR процессов
        </div>
        <div className="partner_description">
          Вы делитесь с нами своим ТЗ или RFP, мы оцениваем рыночный потенциал
          ваших сценариев использования системы, необходимую срочность и
          трудоемкость и по итогам предлагаем вам один из трех вариантов
        </div>
        <div className="partner_render_container">
          {content.map((item, index) => {
            return (
              <div key={index} className="partner_render_item">
                <div className="partner_render_item_svg">{item.svg?.()}</div>
                <div className="partner_render_item_title">{item.title}</div>
                <div className="partner_render_item_content">{item.content}</div>
              </div>
            )
          })}
        </div>
      </Inner>
    </section>
  )
}
