export const PrioritySvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_359_3155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5136 8.44405C18.3785 8.94338 18.3785 10.1917 17.5136 10.691L11.919 13.9211C11.0541 14.4204 9.97307 13.7962 9.97307 12.7976V10.8648H9.56766C8.22426 10.8648 7.13523 11.9539 7.13523 13.2973V35.027C7.13523 36.3704 8.22426 37.4594 9.56766 37.4594H19.6217V40.3784H9.56766C6.61219 40.3784 4.21631 37.9825 4.21631 35.027V13.2973C4.21631 10.3418 6.61219 7.94592 9.56766 7.94592H9.97307V6.3375C9.97307 5.33884 11.0541 4.71468 11.919 5.21401L17.5136 8.44405Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter1_d_359_3155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1028 10.7351V12.7976C10.1028 13.6964 11.0758 14.2581 11.8541 13.8087L17.4487 10.5787C18.2271 10.1293 18.2271 9.0058 17.4487 8.5564L11.8541 5.32636C11.0758 4.87696 10.1028 5.43871 10.1028 6.3375V8.07565H9.56766C6.68384 8.07565 4.34604 10.4135 4.34604 13.2973V35.027C4.34604 37.9108 6.68384 40.2486 9.56766 40.2486H19.492V37.5892H9.56766C8.15262 37.5892 7.0055 36.442 7.0055 35.027V13.2973C7.0055 11.8822 8.15262 10.7351 9.56766 10.7351H10.1028ZM9.97307 7.94592H9.56766C6.61219 7.94592 4.21631 10.3418 4.21631 13.2973V35.027C4.21631 37.9825 6.61219 40.3784 9.56766 40.3784H19.6217V37.4594H9.56766C8.22426 37.4594 7.13523 36.3704 7.13523 35.027V13.2973C7.13523 11.9539 8.22426 10.8648 9.56766 10.8648H9.97307V12.7976C9.97307 13.7962 11.0541 14.4204 11.919 13.9211L17.5136 10.691C18.3785 10.1917 18.3785 8.94338 17.5136 8.44405L11.919 5.21401C11.0541 4.71468 9.97307 5.33884 9.97307 6.3375V7.94592Z"
        fill="url(#paint0_linear_359_3155)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter2_ii_359_3155)">
      <path
        d="M30.1621 11.1892C30.1621 10.3832 30.8155 9.72974 31.6216 9.72974C32.4276 9.72974 33.081 10.3832 33.081 11.1892V36.8108C33.081 37.6169 32.4276 38.2703 31.6216 38.2703C30.8155 38.2703 30.1621 37.6169 30.1621 36.8108V11.1892Z"
        fill="url(#paint1_linear_359_3155)"
      />
    </g>
    <g filter="url(#filter3_ii_359_3155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9513 36.8108V11.1892C32.9513 10.4548 32.356 9.85947 31.6216 9.85947C30.8872 9.85947 30.2918 10.4548 30.2918 11.1892V36.8108C30.2918 37.5452 30.8872 38.1405 31.6216 38.1405C32.356 38.1405 32.9513 37.5452 32.9513 36.8108ZM31.6216 9.72974C30.8155 9.72974 30.1621 10.3832 30.1621 11.1892V36.8108C30.1621 37.6169 30.8155 38.2703 31.6216 38.2703C32.4276 38.2703 33.081 37.6169 33.081 36.8108V11.1892C33.081 10.3832 32.4276 9.72974 31.6216 9.72974Z"
        fill="url(#paint2_linear_359_3155)"
      />
    </g>
    <g filter="url(#filter4_bii_359_3155)">
      <path
        d="M19.1353 9.72974C19.1353 7.91635 19.1353 7.00965 19.4315 6.29444C19.8265 5.34081 20.5842 4.58316 21.5378 4.18816C22.253 3.89191 23.1597 3.89191 24.9731 3.89191H38.2704C40.0838 3.89191 40.9905 3.89191 41.7057 4.18816C42.6593 4.58316 43.417 5.34081 43.812 6.29444C44.1082 7.00965 44.1082 7.91635 44.1082 9.72974C44.1082 11.5431 44.1082 12.4498 43.812 13.1651C43.417 14.1187 42.6593 14.8763 41.7057 15.2713C40.9905 15.5676 40.0838 15.5676 38.2704 15.5676H24.9731C23.1597 15.5676 22.253 15.5676 21.5378 15.2713C20.5842 14.8763 19.8265 14.1187 19.4315 13.1651C19.1353 12.4498 19.1353 11.5431 19.1353 9.72974Z"
        fill="url(#paint3_linear_359_3155)"
        fillOpacity="0.4"
      />
    </g>
    <g filter="url(#filter5_bii_359_3155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2704 4.02164H24.9731C24.0646 4.02164 23.3898 4.02171 22.8525 4.05837C22.3163 4.09495 21.9266 4.16753 21.5874 4.30801C20.6656 4.68985 19.9332 5.42225 19.5514 6.34408C19.4109 6.68325 19.3383 7.07296 19.3017 7.6091C19.2651 8.14642 19.265 8.82127 19.265 9.72974C19.265 10.6382 19.2651 11.3131 19.3017 11.8504C19.3383 12.3865 19.4109 12.7762 19.5514 13.1154C19.9332 14.0372 20.6656 14.7696 21.5874 15.1515C21.9266 15.292 22.3163 15.3645 22.8525 15.4011C23.3898 15.4378 24.0646 15.4379 24.9731 15.4379H38.2704C39.1789 15.4379 39.8537 15.4378 40.391 15.4011C40.9272 15.3645 41.3169 15.292 41.6561 15.1515C42.5779 14.7696 43.3103 14.0372 43.6921 13.1154C43.8326 12.7762 43.9052 12.3865 43.9418 11.8504C43.9784 11.3131 43.9785 10.6382 43.9785 9.72974C43.9785 8.82127 43.9784 8.14642 43.9418 7.6091C43.9052 7.07296 43.8326 6.68325 43.6921 6.34408C43.3103 5.42225 42.5779 4.68985 41.6561 4.30801C41.3169 4.16753 40.9272 4.09495 40.391 4.05837C39.8537 4.02171 39.1789 4.02164 38.2704 4.02164ZM19.4315 6.29444C19.1353 7.00965 19.1353 7.91635 19.1353 9.72974C19.1353 11.5431 19.1353 12.4498 19.4315 13.1651C19.8265 14.1187 20.5842 14.8763 21.5378 15.2713C22.253 15.5676 23.1597 15.5676 24.9731 15.5676H38.2704C40.0838 15.5676 40.9905 15.5676 41.7057 15.2713C42.6593 14.8763 43.417 14.1187 43.812 13.1651C44.1082 12.4498 44.1082 11.5431 44.1082 9.72974C44.1082 7.91635 44.1082 7.00965 43.812 6.29444C43.417 5.34081 42.6593 4.58316 41.7057 4.18816C40.9905 3.89191 40.0838 3.89191 38.2704 3.89191H24.9731C23.1597 3.89191 22.253 3.89191 21.5378 4.18816C20.5842 4.58316 19.8265 5.34081 19.4315 6.29444Z"
        fill="url(#paint4_linear_359_3155)"
      />
    </g>
    <g filter="url(#filter6_bii_359_3155)">
      <path
        d="M19.1353 24C19.1353 22.1866 19.1353 21.2799 19.4315 20.5647C19.8265 19.6111 20.5842 18.8534 21.5378 18.4584C22.253 18.1622 23.1597 18.1622 24.9731 18.1622H38.2704C40.0838 18.1622 40.9905 18.1622 41.7057 18.4584C42.6593 18.8534 43.417 19.6111 43.812 20.5647C44.1082 21.2799 44.1082 22.1866 44.1082 24C44.1082 25.8134 44.1082 26.7201 43.812 27.4353C43.417 28.3889 42.6593 29.1466 41.7057 29.5416C40.9905 29.8378 40.0838 29.8378 38.2704 29.8378H24.9731C23.1597 29.8378 22.253 29.8378 21.5378 29.5416C20.5842 29.1466 19.8265 28.3889 19.4315 27.4353C19.1353 26.7201 19.1353 25.8134 19.1353 24Z"
        fill="url(#paint5_linear_359_3155)"
        fillOpacity="0.4"
      />
    </g>
    <g filter="url(#filter7_bii_359_3155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2704 18.2919H24.9731C24.0646 18.2919 23.3898 18.292 22.8525 18.3286C22.3163 18.3652 21.9266 18.4378 21.5874 18.5783C20.6656 18.9601 19.9332 19.6925 19.5514 20.6143C19.4109 20.9535 19.3383 21.3432 19.3017 21.8794C19.2651 22.4167 19.265 23.0915 19.265 24C19.265 24.9085 19.2651 25.5833 19.3017 26.1206C19.3383 26.6568 19.4109 27.0465 19.5514 27.3857C19.9332 28.3075 20.6656 29.0399 21.5874 29.4217C21.9266 29.5622 22.3163 29.6348 22.8525 29.6714C23.3898 29.708 24.0646 29.7081 24.9731 29.7081H38.2704C39.1789 29.7081 39.8537 29.708 40.391 29.6714C40.9272 29.6348 41.3169 29.5622 41.6561 29.4217C42.5779 29.0399 43.3103 28.3075 43.6921 27.3857C43.8326 27.0465 43.9052 26.6568 43.9418 26.1206C43.9784 25.5833 43.9785 24.9085 43.9785 24C43.9785 23.0915 43.9784 22.4167 43.9418 21.8794C43.9052 21.3432 43.8326 20.9535 43.6921 20.6143C43.3103 19.6925 42.5779 18.9601 41.6561 18.5783C41.3169 18.4378 40.9272 18.3652 40.391 18.3286C39.8537 18.292 39.1789 18.2919 38.2704 18.2919ZM19.4315 20.5647C19.1353 21.2799 19.1353 22.1866 19.1353 24C19.1353 25.8134 19.1353 26.7201 19.4315 27.4353C19.8265 28.3889 20.5842 29.1466 21.5378 29.5416C22.253 29.8378 23.1597 29.8378 24.9731 29.8378H38.2704C40.0838 29.8378 40.9905 29.8378 41.7057 29.5416C42.6593 29.1466 43.417 28.3889 43.812 27.4353C44.1082 26.7201 44.1082 25.8134 44.1082 24C44.1082 22.1866 44.1082 21.2799 43.812 20.5647C43.417 19.6111 42.6593 18.8534 41.7057 18.4584C40.9905 18.1622 40.0838 18.1622 38.2704 18.1622H24.9731C23.1597 18.1622 22.253 18.1622 21.5378 18.4584C20.5842 18.8534 19.8265 19.6111 19.4315 20.5647Z"
        fill="url(#paint6_linear_359_3155)"
      />
    </g>
    <g filter="url(#filter8_bii_359_3155)">
      <path
        d="M19.1353 38.2703C19.1353 36.4569 19.1353 35.5502 19.4315 34.835C19.8265 33.8813 20.5842 33.1237 21.5378 32.7287C22.253 32.4324 23.1597 32.4324 24.9731 32.4324H38.2704C40.0838 32.4324 40.9905 32.4324 41.7057 32.7287C42.6593 33.1237 43.417 33.8813 43.812 34.835C44.1082 35.5502 44.1082 36.4569 44.1082 38.2703C44.1082 40.0837 44.1082 40.9904 43.812 41.7056C43.417 42.6592 42.6593 43.4169 41.7057 43.8119C40.9905 44.1081 40.0838 44.1081 38.2704 44.1081H24.9731C23.1597 44.1081 22.253 44.1081 21.5378 43.8119C20.5842 43.4169 19.8265 42.6592 19.4315 41.7056C19.1353 40.9904 19.1353 40.0837 19.1353 38.2703Z"
        fill="url(#paint7_linear_359_3155)"
        fillOpacity="0.4"
      />
    </g>
    <g filter="url(#filter9_bii_359_3155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2704 32.5622H24.9731C24.0646 32.5622 23.3898 32.5622 22.8525 32.5989C22.3163 32.6355 21.9266 32.7081 21.5874 32.8485C20.6656 33.2304 19.9332 33.9628 19.5514 34.8846C19.4109 35.2238 19.3383 35.6135 19.3017 36.1496C19.2651 36.6869 19.265 37.3618 19.265 38.2703C19.265 39.1787 19.2651 39.8536 19.3017 40.3909C19.3383 40.9271 19.4109 41.3168 19.5514 41.6559C19.9332 42.5778 20.6656 43.3102 21.5874 43.692C21.9266 43.8325 22.3163 43.9051 22.8525 43.9416C23.3898 43.9783 24.0646 43.9784 24.9731 43.9784H38.2704C39.1789 43.9784 39.8537 43.9783 40.391 43.9416C40.9272 43.9051 41.3169 43.8325 41.6561 43.692C42.5779 43.3102 43.3103 42.5778 43.6921 41.6559C43.8326 41.3168 43.9052 40.9271 43.9418 40.3909C43.9784 39.8536 43.9785 39.1787 43.9785 38.2703C43.9785 37.3618 43.9784 36.6869 43.9418 36.1496C43.9052 35.6135 43.8326 35.2238 43.6921 34.8846C43.3103 33.9628 42.5779 33.2304 41.6561 32.8485C41.3169 32.7081 40.9272 32.6355 40.391 32.5989C39.8537 32.5622 39.1789 32.5622 38.2704 32.5622ZM19.4315 34.835C19.1353 35.5502 19.1353 36.4569 19.1353 38.2703C19.1353 40.0837 19.1353 40.9904 19.4315 41.7056C19.8265 42.6592 20.5842 43.4169 21.5378 43.8119C22.253 44.1081 23.1597 44.1081 24.9731 44.1081H38.2704C40.0838 44.1081 40.9905 44.1081 41.7057 43.8119C42.6593 43.4169 43.417 42.6592 43.812 41.7056C44.1082 40.9904 44.1082 40.0837 44.1082 38.2703C44.1082 36.4569 44.1082 35.5502 43.812 34.835C43.417 33.8813 42.6593 33.1237 41.7057 32.7287C40.9905 32.4324 40.0838 32.4324 38.2704 32.4324H24.9731C23.1597 32.4324 22.253 32.4324 21.5378 32.7287C20.5842 33.1237 19.8265 33.8813 19.4315 34.835Z"
        fill="url(#paint8_linear_359_3155)"
      />
    </g>
    <g filter="url(#filter10_d_359_3155)">
      <path
        d="M28.8102 11.2421C27.9448 11.2421 27.2432 10.5405 27.2432 9.675C27.2432 8.80954 27.9448 8.10794 28.8102 8.10794H34.2949C35.1604 8.10794 35.862 8.80954 35.862 9.675C35.862 10.5405 35.1604 11.2421 34.2949 11.2421H28.8102Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter11_d_359_3155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2949 8.23767H28.8102C28.0164 8.23767 27.3729 8.88119 27.3729 9.675C27.3729 10.4688 28.0164 11.1123 28.8102 11.1123H34.2949C35.0888 11.1123 35.7323 10.4688 35.7323 9.675C35.7323 8.88119 35.0888 8.23767 34.2949 8.23767ZM27.2432 9.675C27.2432 10.5405 27.9448 11.2421 28.8102 11.2421H34.2949C35.1604 11.2421 35.862 10.5405 35.862 9.675C35.862 8.80954 35.1604 8.10794 34.2949 8.10794H28.8102C27.9448 8.10794 27.2432 8.80954 27.2432 9.675Z"
        fill="url(#paint9_linear_359_3155)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter12_d_359_3155)">
      <path
        d="M28.8102 25.5123C27.9448 25.5123 27.2432 24.8107 27.2432 23.9453C27.2432 23.0798 27.9448 22.3782 28.8102 22.3782H34.2949C35.1604 22.3782 35.862 23.0798 35.862 23.9453C35.862 24.8107 35.1604 25.5123 34.2949 25.5123H28.8102Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter13_d_359_3155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2949 22.5079H28.8102C28.0164 22.5079 27.3729 23.1515 27.3729 23.9453C27.3729 24.7391 28.0164 25.3826 28.8102 25.3826H34.2949C35.0888 25.3826 35.7323 24.7391 35.7323 23.9453C35.7323 23.1515 35.0888 22.5079 34.2949 22.5079ZM27.2432 23.9453C27.2432 24.8107 27.9448 25.5123 28.8102 25.5123H34.2949C35.1604 25.5123 35.862 24.8107 35.862 23.9453C35.862 23.0798 35.1604 22.3782 34.2949 22.3782H28.8102C27.9448 22.3782 27.2432 23.0798 27.2432 23.9453Z"
        fill="url(#paint10_linear_359_3155)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter14_d_359_3155)">
      <path
        d="M28.8102 39.7826C27.9448 39.7826 27.2432 39.081 27.2432 38.2155C27.2432 37.3501 27.9448 36.6485 28.8102 36.6485H34.2949C35.1604 36.6485 35.862 37.3501 35.862 38.2155C35.862 39.081 35.1604 39.7826 34.2949 39.7826H28.8102Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter15_d_359_3155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2949 36.7782H28.8102C28.0164 36.7782 27.3729 37.4217 27.3729 38.2155C27.3729 39.0093 28.0164 39.6529 28.8102 39.6529H34.2949C35.0888 39.6529 35.7323 39.0093 35.7323 38.2155C35.7323 37.4217 35.0888 36.7782 34.2949 36.7782ZM27.2432 38.2155C27.2432 39.081 27.9448 39.7826 28.8102 39.7826H34.2949C35.1604 39.7826 35.862 39.081 35.862 38.2155C35.862 37.3501 35.1604 36.6485 34.2949 36.6485H28.8102C27.9448 36.6485 27.2432 37.3501 27.2432 38.2155Z"
        fill="url(#paint11_linear_359_3155)"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_359_3155"
        x="2.21631"
        y="4.03827"
        width="19.4055"
        height="39.3401"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3155"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_359_3155"
        x="2.21631"
        y="4.03827"
        width="19.4055"
        height="39.3401"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3155"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_ii_359_3155"
        x="28.1621"
        y="7.72974"
        width="6.91895"
        height="32.5405"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3155"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3155"
          result="effect2_innerShadow_359_3155"
        />
      </filter>
      <filter
        id="filter3_ii_359_3155"
        x="28.1621"
        y="7.72974"
        width="6.91895"
        height="32.5405"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3155"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3155"
          result="effect2_innerShadow_359_3155"
        />
      </filter>
      <filter
        id="filter4_bii_359_3155"
        x="11.1353"
        y="-4.10809"
        width="40.9729"
        height="27.6757"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3155"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3155"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3155"
          result="effect3_innerShadow_359_3155"
        />
      </filter>
      <filter
        id="filter5_bii_359_3155"
        x="11.1353"
        y="-4.10809"
        width="40.9729"
        height="27.6757"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3155"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3155"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3155"
          result="effect3_innerShadow_359_3155"
        />
      </filter>
      <filter
        id="filter6_bii_359_3155"
        x="11.1353"
        y="10.1622"
        width="40.9729"
        height="27.6757"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3155"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3155"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3155"
          result="effect3_innerShadow_359_3155"
        />
      </filter>
      <filter
        id="filter7_bii_359_3155"
        x="11.1353"
        y="10.1622"
        width="40.9729"
        height="27.6757"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3155"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3155"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3155"
          result="effect3_innerShadow_359_3155"
        />
      </filter>
      <filter
        id="filter8_bii_359_3155"
        x="11.1353"
        y="24.4324"
        width="40.9729"
        height="27.6757"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3155"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3155"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3155"
          result="effect3_innerShadow_359_3155"
        />
      </filter>
      <filter
        id="filter9_bii_359_3155"
        x="11.1353"
        y="24.4324"
        width="40.9729"
        height="27.6757"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3155"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3155"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3155"
          result="effect3_innerShadow_359_3155"
        />
      </filter>
      <filter
        id="filter10_d_359_3155"
        x="25.2432"
        y="7.10797"
        width="12.6189"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3155"
          result="shape"
        />
      </filter>
      <filter
        id="filter11_d_359_3155"
        x="25.2432"
        y="7.10797"
        width="12.6189"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3155"
          result="shape"
        />
      </filter>
      <filter
        id="filter12_d_359_3155"
        x="25.2432"
        y="21.3782"
        width="12.6189"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3155"
          result="shape"
        />
      </filter>
      <filter
        id="filter13_d_359_3155"
        x="25.2432"
        y="21.3782"
        width="12.6189"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3155"
          result="shape"
        />
      </filter>
      <filter
        id="filter14_d_359_3155"
        x="25.2432"
        y="35.6485"
        width="12.6189"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3155"
          result="shape"
        />
      </filter>
      <filter
        id="filter15_d_359_3155"
        x="25.2432"
        y="35.6485"
        width="12.6189"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3155"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3155"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_359_3155"
        x1="21.0548"
        y1="9.56741"
        x2="10.1874"
        y2="9.56741"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_359_3155"
        x1="30.2573"
        y1="11.8703"
        x2="33.542"
        y2="11.9294"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#3787FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_359_3155"
        x1="29.5633"
        y1="-94.5716"
        x2="52.2903"
        y2="-90.4871"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_359_3155"
        x1="31.6217"
        y1="4.33914"
        x2="31.6217"
        y2="16.218"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#0061FF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_359_3155"
        x1="16.4208"
        y1="2.83048"
        x2="20.7283"
        y2="21.5509"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_359_3155"
        x1="31.6217"
        y1="18.6094"
        x2="31.6217"
        y2="30.4883"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#0061FF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_359_3155"
        x1="16.4208"
        y1="17.1007"
        x2="20.7283"
        y2="35.8212"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_359_3155"
        x1="31.6217"
        y1="32.8797"
        x2="31.6217"
        y2="44.7585"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#0061FF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_359_3155"
        x1="16.4208"
        y1="31.371"
        x2="20.7283"
        y2="50.0914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_359_3155"
        x1="27.2432"
        y1="9.675"
        x2="33.9467"
        y2="9.675"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_359_3155"
        x1="27.2432"
        y1="23.9453"
        x2="33.9467"
        y2="23.9453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_359_3155"
        x1="27.2432"
        y1="38.2155"
        x2="33.9467"
        y2="38.2155"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
