import React from 'react'
import './styles.scss'

interface Props {
  children?: React.ReactNode
}
export const Main: React.FC<Props> = ({ children }) => {
  return (
    <main className="main">
      {children}
    </main>
  )
}
