import React, { useContext } from 'react'
import { H2 } from '../../ui/H/H'
import { Btn } from '../../ui/Btn/Btn'
import { Inner } from '../../layout/Inner/Inner'
import { LayoutContext } from '../../../contexts/LayoutContext/LayoutContext'
import './style.scss'

export const PromoBanner: React.FC = () => {
  const LayoutState = useContext(LayoutContext)
  return (
    <section className="promo-banner">
      <Inner>
        <div className="promo-banner__container">
          <div className="promo-banner__text">
            <H2 mod="promo-banner">Хотите публиковать вакансию сразу на&nbsp;нескольких работных сайтах в&nbsp;1&nbsp;клик? </H2>
            <p>Отправьте запрос, и мы расскажем, как расширить возможности вашей&nbsp;ATS</p>
          </div>
          <Btn
            onClick={() => LayoutState?.setPopupIsOpen(true)}
            mod="--white, promo-banner"
          >Отправить запрос</Btn>
        </div>
      </Inner>
    </section>
  )
}
