export const PuzzleSvg = () => (<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_649_10711)">
<g filter="url(#filter0_ii_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M1.23828 8.36382C1.23828 5.40147 3.63975 3 6.60211 3H26.9846V11.4505C27.7193 10.8616 28.6518 10.5094 29.6666 10.5094C32.0364 10.5094 33.9576 12.4305 33.9576 14.8004C33.9576 17.1703 32.0364 19.0915 29.6666 19.0915C28.6518 19.0915 27.7193 18.7392 26.9846 18.1503V25.5281H17.4614C18.0503 24.7934 18.4025 23.8609 18.4025 22.8462C18.4025 20.4763 16.4813 18.5551 14.1115 18.5551C11.7416 18.5551 9.8204 20.4763 9.8204 22.8462C9.8204 23.8609 10.1727 24.7934 10.7616 25.5281H1.23828V8.36382Z" fill="#D9D9D9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1.23828 8.36382C1.23828 5.40147 3.63975 3 6.60211 3H26.9846V11.4505C27.7193 10.8616 28.6518 10.5094 29.6666 10.5094C32.0364 10.5094 33.9576 12.4305 33.9576 14.8004C33.9576 17.1703 32.0364 19.0915 29.6666 19.0915C28.6518 19.0915 27.7193 18.7392 26.9846 18.1503V25.5281H17.4614C18.0503 24.7934 18.4025 23.8609 18.4025 22.8462C18.4025 20.4763 16.4813 18.5551 14.1115 18.5551C11.7416 18.5551 9.8204 20.4763 9.8204 22.8462C9.8204 23.8609 10.1727 24.7934 10.7616 25.5281H1.23828V8.36382Z" fill="url(#paint0_linear_649_10711)"/>
</g>
<g filter="url(#filter1_ii_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M26.9846 3H6.60211C3.63975 3 1.23828 5.40147 1.23828 8.36382V25.5281H10.7616C10.7056 25.4583 10.6518 25.3868 10.6003 25.3135C10.1089 24.6156 9.8204 23.7646 9.8204 22.8462C9.8204 20.4763 11.7416 18.5551 14.1115 18.5551C16.4813 18.5551 18.4025 20.4763 18.4025 22.8462C18.4025 23.7646 18.114 24.6156 17.6226 25.3135C17.5711 25.3868 17.5173 25.4583 17.4614 25.5281H26.9846V18.1503C27.0544 18.2063 27.126 18.26 27.1992 18.3116C27.8971 18.803 28.7481 19.0915 29.6666 19.0915C32.0364 19.0915 33.9576 17.1703 33.9576 14.8004C33.9576 12.4305 32.0364 10.5094 29.6666 10.5094C28.7481 10.5094 27.8971 10.7979 27.1992 11.2892C27.126 11.3408 27.0544 11.3946 26.9846 11.4505V3ZM26.7701 25.3135V17.7034L27.1188 17.9829C27.8168 18.5424 28.7023 18.8769 29.6666 18.8769C31.9179 18.8769 33.7431 17.0518 33.7431 14.8004C33.7431 12.549 31.9179 10.7239 29.6666 10.7239C28.7023 10.7239 27.8168 11.0584 27.1188 11.6179L26.7701 11.8975V3.21455H6.60211C3.75824 3.21455 1.45283 5.51996 1.45283 8.36382V25.3135H10.3409C9.8762 24.6047 9.60585 23.7568 9.60585 22.8462C9.60585 20.3578 11.6231 18.3405 14.1115 18.3405C16.5998 18.3405 18.6171 20.3578 18.6171 22.8462C18.6171 23.7568 18.3467 24.6047 17.882 25.3135H26.7701Z" fill="url(#paint1_linear_649_10711)"/>
</g>
<g filter="url(#filter2_ii_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M1.23828 53.4199C1.23828 56.3822 3.63975 58.7837 6.60211 58.7837H26.9846V50.3332C26.25 50.9221 25.3175 51.2743 24.3027 51.2743C21.9328 51.2743 20.0117 49.3532 20.0117 46.9833C20.0117 44.6134 21.9328 42.6922 24.3027 42.6922C25.3175 42.6922 26.25 43.0445 26.9846 43.6334V36.2556H17.4614C18.0503 35.521 18.4025 34.5885 18.4025 33.5737C18.4025 31.2038 16.4813 29.2826 14.1115 29.2826C11.7416 29.2826 9.8204 31.2038 9.8204 33.5737C9.8204 34.5885 10.1727 35.521 10.7616 36.2556H1.23828V53.4199Z" fill="#D9D9D9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1.23828 53.4199C1.23828 56.3822 3.63975 58.7837 6.60211 58.7837H26.9846V50.3332C26.25 50.9221 25.3175 51.2743 24.3027 51.2743C21.9328 51.2743 20.0117 49.3532 20.0117 46.9833C20.0117 44.6134 21.9328 42.6922 24.3027 42.6922C25.3175 42.6922 26.25 43.0445 26.9846 43.6334V36.2556H17.4614C18.0503 35.521 18.4025 34.5885 18.4025 33.5737C18.4025 31.2038 16.4813 29.2826 14.1115 29.2826C11.7416 29.2826 9.8204 31.2038 9.8204 33.5737C9.8204 34.5885 10.1727 35.521 10.7616 36.2556H1.23828V53.4199Z" fill="url(#paint2_linear_649_10711)"/>
</g>
<g filter="url(#filter3_ii_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M26.9846 36.2556V43.6334C26.9149 43.5774 26.8433 43.5236 26.7701 43.4721C26.0722 42.9807 25.2211 42.6922 24.3027 42.6922C21.9328 42.6922 20.0117 44.6134 20.0117 46.9833C20.0117 49.3532 21.9328 51.2743 24.3027 51.2743C25.2211 51.2743 26.0722 50.9858 26.7701 50.4945C26.8433 50.4429 26.9149 50.3891 26.9846 50.3332V58.7837H6.60211C3.63975 58.7837 1.23828 56.3822 1.23828 53.4199V36.2556H10.7616C10.7056 36.1859 10.6518 36.1143 10.6003 36.0411C10.1089 35.3431 9.8204 34.4921 9.8204 33.5737C9.8204 31.2038 11.7416 29.2826 14.1115 29.2826C16.4813 29.2826 18.4025 31.2038 18.4025 33.5737C18.4025 34.4921 18.114 35.3431 17.6226 36.0411C17.5711 36.1143 17.5173 36.1859 17.4614 36.2556H26.9846ZM1.45283 36.4702H11.2085L10.929 36.1214C10.3695 35.4235 10.035 34.538 10.035 33.5737C10.035 31.3223 11.8601 29.4972 14.1115 29.4972C16.3629 29.4972 18.188 31.3223 18.188 33.5737C18.188 34.538 17.8535 35.4235 17.294 36.1214L17.0144 36.4702H26.7701V43.2127C26.0613 42.748 25.2134 42.4777 24.3027 42.4777C21.8144 42.4777 19.7971 44.4949 19.7971 46.9833C19.7971 49.4717 21.8144 51.4889 24.3027 51.4889C25.2134 51.4889 26.0613 51.2185 26.7701 50.7538V58.5691H6.60211C3.75824 58.5691 1.45283 56.2637 1.45283 53.4199V36.4702Z" fill="url(#paint3_linear_649_10711)"/>
</g>
<g filter="url(#filter4_ii_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M63.9951 53.4199C63.9951 56.3822 61.5937 58.7837 58.6313 58.7837H38.2488V50.3332C37.5141 50.9221 36.5816 51.2743 35.5668 51.2743C33.197 51.2743 31.2758 49.3532 31.2758 46.9833C31.2758 44.6134 33.197 42.6922 35.5668 42.6922C36.5816 42.6922 37.5141 43.0445 38.2488 43.6334V36.2556H47.772C47.1831 36.9903 46.8309 37.9228 46.8309 38.9375C46.8309 41.3074 48.7521 43.2286 51.1219 43.2286C53.4918 43.2286 55.413 41.3074 55.413 38.9375C55.413 37.9228 55.0607 36.9903 54.4718 36.2556H63.9951V53.4199Z" fill="#D9D9D9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M63.9951 53.4199C63.9951 56.3822 61.5937 58.7837 58.6313 58.7837H38.2488V50.3332C37.5141 50.9221 36.5816 51.2743 35.5668 51.2743C33.197 51.2743 31.2758 49.3532 31.2758 46.9833C31.2758 44.6134 33.197 42.6922 35.5668 42.6922C36.5816 42.6922 37.5141 43.0445 38.2488 43.6334V36.2556H47.772C47.1831 36.9903 46.8309 37.9228 46.8309 38.9375C46.8309 41.3074 48.7521 43.2286 51.1219 43.2286C53.4918 43.2286 55.413 41.3074 55.413 38.9375C55.413 37.9228 55.0607 36.9903 54.4718 36.2556H63.9951V53.4199Z" fill="url(#paint4_linear_649_10711)"/>
</g>
<g filter="url(#filter5_ii_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M38.2488 58.7837H58.6313C61.5937 58.7837 63.9951 56.3822 63.9951 53.4199V36.2556H54.4718C54.5278 36.3254 54.5816 36.3969 54.6331 36.4702C55.1245 37.1681 55.413 38.0191 55.413 38.9375C55.413 41.3074 53.4918 43.2286 51.1219 43.2286C48.7521 43.2286 46.8309 41.3074 46.8309 38.9375C46.8309 38.0191 47.1194 37.1681 47.6108 36.4702C47.6623 36.3969 47.7161 36.3254 47.772 36.2556H38.2488V43.6334C38.179 43.5774 38.1074 43.5236 38.0342 43.4721C37.3363 42.9807 36.4853 42.6922 35.5668 42.6922C33.197 42.6922 31.2758 44.6134 31.2758 46.9833C31.2758 49.3532 33.197 51.2743 35.5668 51.2743C36.4853 51.2743 37.3363 50.9858 38.0342 50.4945C38.1074 50.4429 38.179 50.3891 38.2488 50.3332V58.7837ZM38.4633 36.4702V44.0803L38.1146 43.8008C37.4166 43.2413 36.5311 42.9068 35.5668 42.9068C33.3155 42.9068 31.4903 44.7319 31.4903 46.9833C31.4903 49.2347 33.3155 51.0598 35.5668 51.0598C36.5311 51.0598 37.4166 50.7253 38.1146 50.1658L38.4633 49.8862V58.5691H58.6313C61.4752 58.5691 63.7806 56.2637 63.7806 53.4199V36.4702H54.8925C55.3572 37.179 55.6276 38.0269 55.6276 38.9375C55.6276 41.4259 53.6103 43.4432 51.1219 43.4432C48.6336 43.4432 46.6163 41.4259 46.6163 38.9375C46.6163 38.0269 46.8867 37.179 47.3514 36.4702H38.4633Z" fill="url(#paint5_linear_649_10711)"/>
</g>
<g filter="url(#filter6_d_649_10711)">
<path d="M25.3755 14.8003C25.3755 12.4305 27.2967 10.5093 29.6665 10.5093C32.0364 10.5093 33.9576 12.4305 33.9576 14.8003C33.9576 17.1702 32.0364 19.0914 29.6665 19.0914C27.2967 19.0914 25.3755 17.1702 25.3755 14.8003Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter7_d_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M25.59 14.8003C25.59 17.0517 27.4152 18.8768 29.6665 18.8768C31.9179 18.8768 33.7431 17.0517 33.7431 14.8003C33.7431 12.5489 31.9179 10.7238 29.6665 10.7238C27.4152 10.7238 25.59 12.5489 25.59 14.8003ZM29.6665 10.5093C27.2967 10.5093 25.3755 12.4305 25.3755 14.8003C25.3755 17.1702 27.2967 19.0914 29.6665 19.0914C32.0364 19.0914 33.9576 17.1702 33.9576 14.8003C33.9576 12.4305 32.0364 10.5093 29.6665 10.5093Z" fill="url(#paint6_linear_649_10711)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter8_d_649_10711)">
<path d="M9.82031 33.5738C9.82031 31.2039 11.7415 29.2827 14.1114 29.2827C16.4813 29.2827 18.4024 31.2039 18.4024 33.5738C18.4024 35.9437 16.4813 37.8648 14.1114 37.8648C11.7415 37.8648 9.82031 35.9437 9.82031 33.5738Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter9_d_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M10.0349 33.5738C10.0349 35.8252 11.86 37.6503 14.1114 37.6503C16.3628 37.6503 18.1879 35.8252 18.1879 33.5738C18.1879 31.3224 16.3628 29.4973 14.1114 29.4973C11.86 29.4973 10.0349 31.3224 10.0349 33.5738ZM14.1114 29.2827C11.7415 29.2827 9.82031 31.2039 9.82031 33.5738C9.82031 35.9437 11.7415 37.8648 14.1114 37.8648C16.4813 37.8648 18.4024 35.9437 18.4024 33.5738C18.4024 31.2039 16.4813 29.2827 14.1114 29.2827Z" fill="url(#paint7_linear_649_10711)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter10_d_649_10711)">
<path d="M31.2759 46.9833C31.2759 44.6134 33.1971 42.6923 35.5669 42.6923C37.9368 42.6923 39.858 44.6134 39.858 46.9833C39.858 49.3532 37.9368 51.2744 35.5669 51.2744C33.1971 51.2744 31.2759 49.3532 31.2759 46.9833Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter11_d_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M31.4904 46.9833C31.4904 49.2347 33.3155 51.0598 35.5669 51.0598C37.8183 51.0598 39.6434 49.2347 39.6434 46.9833C39.6434 44.7319 37.8183 42.9068 35.5669 42.9068C33.3155 42.9068 31.4904 44.7319 31.4904 46.9833ZM35.5669 42.6923C33.1971 42.6923 31.2759 44.6134 31.2759 46.9833C31.2759 49.3532 33.1971 51.2744 35.5669 51.2744C37.9368 51.2744 39.858 49.3532 39.858 46.9833C39.858 44.6134 37.9368 42.6923 35.5669 42.6923Z" fill="url(#paint8_linear_649_10711)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter12_bii_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M60.4721 21.9186C62.5993 23.9803 62.6524 27.3761 60.5907 29.5033L46.4054 44.1396L40.3372 38.2585C40.2488 39.1958 39.8528 40.1106 39.1465 40.8393C37.4972 42.5411 34.7806 42.5836 33.0788 40.9342C31.377 39.2849 31.3345 36.5683 32.9839 34.8665C33.6901 34.1378 34.592 33.7134 35.5262 33.5957L30.2283 28.4611L36.8561 21.6226C36.9738 22.5567 37.3983 23.4587 38.127 24.1649C39.8287 25.8142 42.5454 25.7717 44.1947 24.07C45.844 22.3682 45.8015 19.6516 44.0997 18.0022C43.3711 17.296 42.4563 16.8999 41.5189 16.8116L48.1467 9.97305L60.4721 21.9186Z" fill="url(#paint9_linear_649_10711)"/>
</g>
<g filter="url(#filter13_bii_649_10711)">
<path fillRule="evenodd" clipRule="evenodd" d="M46.4054 44.1396L60.5907 29.5033C62.6524 27.3761 62.5993 23.9803 60.4721 21.9186L48.1467 9.97305L41.5189 16.8116C41.6079 16.8199 41.6967 16.8311 41.7852 16.8451C42.6284 16.978 43.4403 17.3631 44.0997 18.0022C45.8015 19.6516 45.844 22.3682 44.1947 24.07C42.5454 25.7717 39.8287 25.8142 38.127 24.1649C37.4675 23.5257 37.0572 22.7263 36.898 21.8877C36.8812 21.7997 36.8673 21.7113 36.8561 21.6226L30.2283 28.4611L35.5262 33.5957C35.4375 33.6069 35.349 33.6208 35.261 33.6375C34.4225 33.7967 33.623 34.207 32.9839 34.8665C31.3345 36.5683 31.377 39.2849 33.0788 40.9342C34.7806 42.5836 37.4972 42.5411 39.1465 40.8393C39.7857 40.1798 40.1708 39.3679 40.3037 38.5248C40.3176 38.4363 40.3288 38.3475 40.3372 38.2585L46.4054 44.1396ZM30.5317 28.4563L35.9965 33.7527L35.553 33.8085C34.6655 33.9203 33.809 34.3234 33.1379 35.0158C31.5711 36.6325 31.6114 39.2133 33.2281 40.7802C34.8448 42.3471 37.4256 42.3067 38.9925 40.69C39.6635 39.9976 40.0396 39.1289 40.1236 38.2383L40.1656 37.7933L46.4006 43.8363L60.4367 29.354C62.4159 27.3118 62.3649 24.0519 60.3227 22.0727L48.1514 10.2764L41.9657 16.6588C42.7981 16.8184 43.5952 17.2144 44.2491 17.8482C46.0359 19.58 46.0806 22.4324 44.3488 24.2193C42.617 26.0061 39.7645 26.0508 37.9776 24.319C37.3237 23.6852 36.903 22.901 36.7174 22.074L30.5317 28.4563Z" fill="#005EF5"/>
</g>
</g>
<defs>
<filter id="filter0_ii_649_10711" x="-0.761719" y="1" width="36.7192" height="26.5281" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10711"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10711" result="effect2_innerShadow_649_10711"/>
</filter>
<filter id="filter1_ii_649_10711" x="-0.761719" y="1" width="36.7192" height="26.5281" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10711"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10711" result="effect2_innerShadow_649_10711"/>
</filter>
<filter id="filter2_ii_649_10711" x="-0.761719" y="27.2826" width="29.7466" height="33.5011" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10711"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10711" result="effect2_innerShadow_649_10711"/>
</filter>
<filter id="filter3_ii_649_10711" x="-0.761719" y="27.2826" width="29.7466" height="33.5011" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10711"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10711" result="effect2_innerShadow_649_10711"/>
</filter>
<filter id="filter4_ii_649_10711" x="29.2759" y="34.2556" width="36.7192" height="26.5281" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10711"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10711" result="effect2_innerShadow_649_10711"/>
</filter>
<filter id="filter5_ii_649_10711" x="29.2759" y="34.2556" width="36.7192" height="26.5281" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10711"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10711" result="effect2_innerShadow_649_10711"/>
</filter>
<filter id="filter6_d_649_10711" x="23.3755" y="9.50928" width="12.582" height="12.5822" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10711"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10711" result="shape"/>
</filter>
<filter id="filter7_d_649_10711" x="23.3755" y="9.50928" width="12.582" height="12.5822" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10711"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10711" result="shape"/>
</filter>
<filter id="filter8_d_649_10711" x="7.82031" y="28.2827" width="12.582" height="12.5822" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10711"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10711" result="shape"/>
</filter>
<filter id="filter9_d_649_10711" x="7.82031" y="28.2827" width="12.582" height="12.5822" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10711"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10711" result="shape"/>
</filter>
<filter id="filter10_d_649_10711" x="29.2759" y="41.6923" width="12.582" height="12.5822" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10711"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10711" result="shape"/>
</filter>
<filter id="filter11_d_649_10711" x="29.2759" y="41.6923" width="12.582" height="12.5822" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10711"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10711" result="shape"/>
</filter>
<filter id="filter12_bii_649_10711" x="22.2285" y="1.97302" width="47.8745" height="50.1666" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_649_10711"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_649_10711" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10711"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10711" result="effect3_innerShadow_649_10711"/>
</filter>
<filter id="filter13_bii_649_10711" x="22.2285" y="1.97302" width="47.8745" height="50.1666" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_649_10711"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_649_10711" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10711"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10711" result="effect3_innerShadow_649_10711"/>
</filter>
<linearGradient id="paint0_linear_649_10711" x1="12.5157" y1="4.6896" x2="36.7406" y2="8.88125" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint1_linear_649_10711" x1="7.24579" y1="-79.3288" x2="51.9051" y2="-2.1171" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_649_10711" x1="10.1123" y1="57.0941" x2="29.3879" y2="54.4696" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint3_linear_649_10711" x1="5.9655" y1="141.112" x2="55.1388" y2="74.2147" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_649_10711" x1="55.1211" y1="57.0941" x2="35.8455" y2="54.4696" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint5_linear_649_10711" x1="59.2679" y1="141.112" x2="10.0946" y2="74.2147" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_649_10711" x1="29.6665" y1="10.5093" x2="29.6665" y2="17.1843" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear_649_10711" x1="14.1114" y1="29.2827" x2="14.1114" y2="35.9577" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_649_10711" x1="35.5669" y1="42.6923" x2="35.5669" y2="49.3672" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint9_linear_649_10711" x1="57.3991" y1="42.3252" x2="40.8819" y2="17.7595" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5" stopOpacity="0.71"/>
<stop offset="1" stopColor="#0061FF" stopOpacity="0.1"/>
</linearGradient>
<clipPath id="clip0_649_10711">
<rect width="64" height="64" fill="white"/>
</clipPath>
</defs>
</svg>
)
