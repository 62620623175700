export const VkSvg = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3316 24.0225C11.33 24.0225 7.33644 19.2225 7.17004 11.2354H10.6772C10.7924 17.0977 13.378 19.5809 15.426 20.0929V11.2354H18.7284V16.2913C20.7508 16.0737 22.8756 13.7698 23.5924 11.2354H26.8948C26.6248 12.5498 26.0863 13.7943 25.3133 14.8911C24.5401 15.9879 23.5492 16.9135 22.402 17.6097C23.6824 18.246 24.8134 19.1465 25.7202 20.2519C26.6271 21.3573 27.2892 22.6425 27.6628 24.0225H24.0276C23.6921 22.8239 23.0104 21.7508 22.0677 20.938C21.1251 20.125 19.9635 19.6084 18.7284 19.4529V24.0225H18.3316Z"
      fill="#8C8C8C"
    />
    <circle cx="17" cy="17" r="16" stroke="#D5D5D5" />
  </svg>
);
