import React from 'react'
import computer from './computer.png'
import littleComputer from './littleComputer.png'

import { SettingSvg } from '../../../svg/SettingSvg'
import { FireSvg } from '../../../svg/FireSvg'
import { RepairSvg } from '../../../svg/RepairSvg'
import './styles.scss'
import { useWindow } from '../../../hooks/useWindow'
/** @Блок  О нас | 1 по списку */
export const About: React.FC = () => {
  const windowWidth = useWindow()
  const content = [
    {
      title: 'Необходимость в срочном решении',
      content:
        'Если Вы хотите сделать АB тестирование разных подходов к HR сервисам: протестировать новую модель компетенций, шкалу, дерево KPI или орг. структуру',
      svg: () => <SettingSvg />
    },
    {
      title: 'Редкий, уникальный HR Процесс',
      content:
        'Для таких процессов крайне трудно найти готовое решение на рынке HR ПО. Мы поможем его разработать и интегрировать в вашу HR экосистему',
      svg: () => <FireSvg />
    },
    {
      title: 'Рефакторинг HR процесса',
      content:
        'Если ваша HRIS на ремонте или не успевает за вашими потребностями, вы можете временно “арендовать” HR сервис у нас',
      svg: () => <RepairSvg />
    }
  ]
  return (
    <section className="about">
      <div className="about_container">
        <div className="about_title">
          Расширение возможностей вашей HR-системы
        </div>
        <div className="about_description">
          Набор HR API сервисов и инструментов, позволяющих усилить вашу HR
          систему и снять ограничения ее текущей функциональности.
        </div>
      </div>
      <div className="about_content_container">
        {windowWidth > 1362
          ? (
              windowWidth > 1024
                ? <img style={{ height: '644px' }} src={computer} />
                : <img style={{ height: '531px' }} src={computer} />
            )
          : <img style={{ width: '80%' }} src={littleComputer} />}
        <div className="about_block">
          <h2 className="about_block_title">Когда необходим Dors?</h2>
          <div className="about_mapping_content">
            {content.map((item, index) => {
              return (
                <div className="about_item_content" key={index}>
                  <div>{item.svg?.()}</div>
                  <div className="about_item_content_title">{item.title}</div>
                  <div className="about_item_content_description">
                    {item.content}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
