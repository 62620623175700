export const LinkedInSvg = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="16" stroke="#D4D4D4" />
    <path
      d="M25.666 24.3332H22.3532V19.1362C22.3532 17.8969 22.3311 16.3015 20.6303 16.3015C18.9048 16.3015 18.6408 17.6517 18.6408 19.0459V24.3328H15.3281V13.6456H18.5083V15.1061H18.5529C19.2004 13.997 20.4089 13.3322 21.6907 13.3798C25.0483 13.3798 25.6674 15.5922 25.6674 18.4704L25.666 24.3332Z"
      fill="#8C8C8C"
    />
    <path
      d="M11.5898 12.1848C11.5896 12.1848 11.5895 12.1848 11.5894 12.1848C10.5348 12.1848 9.66699 11.3155 9.66699 10.2591C9.66699 9.20259 10.5348 8.33325 11.5894 8.33325C12.644 8.33325 13.5117 9.20239 13.5119 10.2587C13.5119 10.2588 13.5119 10.2589 13.5119 10.2591C13.5119 11.3153 12.6443 12.1847 11.5898 12.1848Z"
      fill="#8C8C8C"
    />
    <path
      d="M13.2459 24.3333H9.92969V13.6458H13.2459V24.3333Z"
      fill="#8C8C8C"
    />
  </svg>
);
