import React, { useState } from 'react'
import { LeftArrowSvg } from '../../../svg/LeftArrowSvg'
import { LeftWhyAreWeSvg } from '../../../svg/LeftWhyAreWeSvg'
import { RightArrowSvg } from '../../../svg/RightArrowSvg'
import { RightWhyAreWeSvg } from '../../../svg/RightWhyAreWeSvg'
import { useWindow } from '../../../hooks/useWindow'
import './styles.scss'

export const WhyAreWe: React.FC = () => {
  const list = [
    'Мы накопили уникальный отраслевой опыт. Начали автоматизировать SOFT HR процессы 20 лет назад и сделали за это время десятки внедрений. В нашем портфеле есть проекты, которые до сих пор по масштабу и сложности являются уникальными не только для российского, но и для международного рынка. Это позволило нам стать 3 раза “лучшим международным партнером”',
    'Мы знаем, что «под капотом» у лучших мировых аналогов HR ПО и куда они двигаются. Это оградит вас от рисков разработки технологически отсталых ТЗ с очень коротким сроком годности',
    'Мы готовы страховать ваши финансовые риски и предлагаем сдельные формы партнерства, при которых цена ошибки сводится к минимуму',
    'Вишенкой на торте является тот факт, что мы являемся HR консультантами с богатым опытом разработки и оптимизации HR процессов и можем увидеть ошибки в ТЗ до того, как вы его попытаетесь автоматизировать'
  ]
  const selectedStyles = {
    padding: 5,
    border: '1px solid #888888',
    borderRadius: 40
  }

  const [index, setIndex] = useState(0)
  const windowWidth = useWindow()
  return (
    <section className="whyAreWe_container">
      <div className="whyAreWe_title">Почему мы?</div>
      <div className="whyAreWe_container_ask">
        {windowWidth > 1024 && (
          <div style={{ marginRight: '-48px' }}>
            <LeftWhyAreWeSvg />
          </div>
        )}
        <div className="whyAreWe_container_ask_content">
          <div>{list[index]}</div>
        </div>

        {windowWidth > 1024 && (
          <div style={{ marginLeft: '-58px' }}>
            <RightWhyAreWeSvg />
          </div>
        )}
      </div>
      <div className="whyAreWe_container_ask_btns">
        <div
          onClick={() => {
            if (index === 0) {
              setIndex(3)
            } else {
              setIndex(index - 1)
            }
          }}
        >
          <LeftArrowSvg />
        </div>
        <div
          onClick={() => setIndex(0)}
          style={
            index === 0
              ? selectedStyles
              : { padding: 5, border: '1px solid #ffffff' }
          }
        >
          <div className="btn_item" />
        </div>
        <div
          onClick={() => setIndex(1)}
          style={
            index === 1
              ? selectedStyles
              : { padding: 5, border: '1px solid #ffffff' }
          }
        >
          <div className="btn_item" />
        </div>
        <div
          onClick={() => setIndex(2)}
          style={
            index === 2
              ? selectedStyles
              : { padding: 5, border: '1px solid #ffffff' }
          }
        >
          <div className="btn_item" />
        </div>
        <div
          onClick={() => setIndex(3)}
          style={
            index === 3
              ? selectedStyles
              : { padding: 5, border: '1px solid #ffffff' }
          }
        >
          <div className="btn_item" />
        </div>
        <div
          onClick={() => {
            if (index === 3) {
              setIndex(0)
            } else {
              setIndex(index + 1)
            }
          }}
        >
          {<RightArrowSvg />}
        </div>
      </div>
    </section>
  )
}
