import React from 'react'
import classNames from 'classnames'
import './styles.scss'

interface Props {
  children?: React.ReactNode
  mod?: string
}
export const Inner: React.FC<Props> = ({ children, mod }) => {
  const modifiers = mod?.split(',').map((item) => item.trim())
  const baseName = 'inner'
  const className = classNames(baseName, modifiers?.map((item) => (item.startsWith('--') ? `${baseName}${item}` : `${item}__${baseName}`)))

  return (
    <div className={className}>
      {children}
    </div>
  )
}
