export const CollectionOfInformation = () => (
  <div>
    <div className="privacy_content_title">Сбор информации</div>
    <div className="privacy_content">
      <div>
        Когда пользователь посещает Сайт{' '}
        <a href="https://www.dors.online">www.dors.online</a> , Оператор
        определяет имя домена пользователя, провайдера и страну (например,
        “aol.com”), а также выбранные переходы с одной страницы на другую (так
        называемую "активность потока переходов").
      </div>
      <div>
        Сайт <a href="https://www.dors.online">www.dors.online</a> собирает
        только те персональные данные о пользователях, которую пользователи
        предоставили добровольно при посещении или взаимодействии с формами и
        продуктами, размещёнными на Сайте{' '}
        <a href="https://www.dors.online">www.dors.online</a> .
      </div>
      <div>
        Сайт <a href="https://www.dors.online">www.dors.online</a> применяет
        технологию "cookie" ("куки") для создания статистической отчетности и
        аналитики. "Куки" представляет собой небольшой объем данных, отсылаемый
        веб-Сайтом, который браузер компьютера пользователя сохраняет на жестком
        диске компьютера пользователя.
      </div>
      <div>
        В "cookie" содержится информация, которая может быть необходимой для
        Сайта <a href="https://www.dors.online">www.dors.online</a> , - для
        сохранения пользовательских установок вариантов просмотра и сбора
        статистической информации по Сайту{' '}
        <a href="https://www.dors.online">www.dors.online</a>, т.е. какие
        страницы были посещены, что было загружено, имя домена
        интернет-провайдера и страна посетителя, а также адреса сторонних
        веб-Сайтов, с которых совершен переход на Сайт{' '}
        <a href="https://www.dors.online">www.dors.online</a> и далее. Однако
        вся эта информация никак не связана с пользователем как с личностью и
        поэтому не подпадает под понятие персональных данных. "Cookies" не
        записывают адрес электронной почты и какие-либо личные сведения
        относительно пользователей. Также данную технологию на Сайте
        <a href="https://www.dors.online"> www.dors.online</a> использует
        установленный счетчик компании Google / LiveInternet / Яндекс и т.п.
      </div>
      <div>
        Кроме того, Оператор использует стандартные журналы учета веб-сервера
        для подсчета количества посетителей и оценки технических возможностей
        Сайт <a href="https://www.dors.online">www.dors.online</a> для того,
        чтобы определить, сколько человек посещает Сайт{' '}
        <a href="https://www.dors.online">www.dors.online</a>, а также для
        организации страницы наиболее удобным для пользователей способом,
        обеспечения соответствия Сайта{' '}
        <a href="https://www.dors.online">www.dors.online</a>
        используемым браузерам, и оформления содержания страниц Сайта
        максимально полезным для посетителей. Оператор записывает сведения по
        перемещениям на Сайте при помощи продукта «Метрика» от компании Яндекс.
      </div>
    </div>
  </div>
)
