export const DorsSvg = () => (
  <svg
    width="100"
    height="27"
    viewBox="0 0 100 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0924 0H12.7711V6.23077H18.0924C22.2068 6.23077 25.5422 9.48531 25.5422 13.5C25.5422 17.5147 22.2068 20.7692 18.0924 20.7692H12.7711V27H18.0924C25.7334 27 31.9277 20.9558 31.9277 13.5C31.9277 6.04416 25.7334 0 18.0924 0Z"
      fill="url(#paint0_linear_359_3065)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7711 0H18.0924C25.7334 0 31.9277 6.04416 31.9277 13.5C31.9277 20.9558 25.7334 27 18.0924 27H12.7814C19.8299 26.9946 25.5422 21.4174 25.5422 14.5385C25.5422 14.3532 25.5364 14.1729 25.525 13.9975C25.5364 13.8331 25.5422 13.6672 25.5422 13.5C25.5422 9.48531 22.2068 6.23077 18.0924 6.23077H12.7711V0Z"
      fill="url(#paint1_linear_359_3065)"
    />
    <path
      d="M12.7711 6.23077H7.4498C3.33539 6.23077 0 9.48531 0 13.5C0 17.5147 3.33539 20.7692 7.4498 20.7692H12.7711V6.23077Z"
      fill="#005EF5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7711 0H18.0924C23.7735 0 28.6549 3.34119 30.7853 8.11961C27.7972 5.95418 24.0958 4.67308 20.0879 4.67308C17.4821 4.67308 15.0059 5.21459 12.7711 6.18858V0Z"
      fill="#005EF5"
    />
    <path
      d="M42.7715 20.7917V6.20833H48.5865C49.6477 6.20833 50.6027 6.38194 51.4516 6.72917C52.3146 7.07639 53.0504 7.57639 53.6587 8.22917C54.2671 8.88194 54.734 9.65278 55.0594 10.5417C55.3849 11.4306 55.5476 12.4167 55.5476 13.5C55.5476 14.5833 55.3849 15.5764 55.0594 16.4792C54.734 17.3681 54.2671 18.1389 53.6587 18.7917C53.0645 19.4306 52.3359 19.9236 51.4728 20.2708C50.6097 20.6181 49.6477 20.7917 48.5865 20.7917H42.7715ZM45.0635 18.625H48.4804C49.2303 18.625 49.8952 18.5069 50.4753 18.2708C51.0696 18.0347 51.5648 17.7014 51.9609 17.2708C52.3712 16.8264 52.6825 16.2847 52.8947 15.6458C53.107 15.0069 53.2131 14.2917 53.2131 13.5C53.2131 12.7083 53.107 12 52.8947 11.375C52.6825 10.7361 52.3712 10.1944 51.9609 9.75C51.5506 9.30555 51.0554 8.96528 50.4753 8.72917C49.8952 8.49306 49.2303 8.375 48.4804 8.375H45.0635V18.625Z"
      fill="black"
    />
    <path
      d="M64.8653 21C63.8184 21 62.8492 20.8125 61.9578 20.4375C61.0806 20.0625 60.3095 19.5417 59.6446 18.875C58.9937 18.1944 58.4844 17.4028 58.1165 16.5C57.7628 15.5833 57.5859 14.5833 57.5859 13.5C57.5859 12.4167 57.7628 11.4236 58.1165 10.5208C58.4844 9.60417 58.9937 8.8125 59.6446 8.14583C60.3095 7.46528 61.0806 6.9375 61.9578 6.5625C62.8492 6.1875 63.8184 6 64.8653 6C65.9123 6 66.8744 6.1875 67.7516 6.5625C68.643 6.9375 69.4141 7.46528 70.0649 8.14583C70.7299 8.82639 71.2392 9.625 71.5929 10.5417C71.9608 11.4444 72.1447 12.4306 72.1447 13.5C72.1447 14.5694 71.9608 15.5625 71.5929 16.4792C71.2392 17.3819 70.7299 18.1736 70.0649 18.8542C69.4141 19.5347 68.643 20.0625 67.7516 20.4375C66.8744 20.8125 65.9123 21 64.8653 21ZM64.8653 18.8125C65.5869 18.8125 66.2448 18.6806 66.8391 18.4167C67.4474 18.1528 67.9709 17.7847 68.4095 17.3125C68.8481 16.8264 69.1877 16.2639 69.4282 15.625C69.6829 14.9722 69.8102 14.2639 69.8102 13.5C69.8102 12.7361 69.6829 12.0347 69.4282 11.3958C69.1877 10.7431 68.8481 10.1806 68.4095 9.70833C67.9709 9.22222 67.4474 8.84722 66.8391 8.58333C66.2448 8.31944 65.5869 8.1875 64.8653 8.1875C64.1438 8.1875 63.4788 8.31944 62.8704 8.58333C62.2762 8.84722 61.7527 9.21528 61.2999 9.6875C60.8613 10.1597 60.5218 10.7222 60.2812 11.375C60.0407 12.0139 59.9204 12.7222 59.9204 13.5C59.9204 14.2639 60.0407 14.9722 60.2812 15.625C60.5218 16.2778 60.8613 16.8403 61.2999 17.3125C61.7527 17.7847 62.2762 18.1528 62.8704 18.4167C63.4788 18.6806 64.1438 18.8125 64.8653 18.8125Z"
      fill="black"
    />
    <path
      d="M75.2481 20.7917V6.20833H81.5724C82.4214 6.20833 83.1995 6.41667 83.9069 6.83333C84.6285 7.23611 85.2015 7.79167 85.626 8.5C86.0504 9.19444 86.2627 9.97917 86.2627 10.8542C86.2627 11.6458 86.0504 12.3819 85.626 13.0625C85.2015 13.7431 84.6356 14.2917 83.9282 14.7083C83.2207 15.1111 82.4355 15.3125 81.5724 15.3125H77.5401V20.7917H75.2481ZM84.0767 20.7917L80.9659 15.3125C80.9659 15.3125 81.6272 15.3356 82.2247 15.2723C82.8222 15.209 83.4096 14.9618 83.4096 14.9618L86.8569 20.8125L84.0767 20.7917ZM77.5401 13.1458H81.7847C82.195 13.1458 82.5628 13.0486 82.8882 12.8542C83.2137 12.6458 83.4683 12.3681 83.6523 12.0208C83.8362 11.6597 83.9282 11.2639 83.9282 10.8333C83.9282 10.3472 83.8079 9.92361 83.5674 9.5625C83.341 9.1875 83.0156 8.89583 82.5911 8.6875C82.1808 8.47917 81.7139 8.375 81.1904 8.375H77.5401V13.1458Z"
      fill="black"
    />
    <path
      d="M94.5886 21C93.7821 21 93.0323 20.8958 92.339 20.6875C91.6599 20.4792 91.0373 20.1667 90.4714 19.75C89.9196 19.3194 89.4244 18.7917 88.9858 18.1667L90.5563 16.3958C91.2496 17.3681 91.9145 18.0417 92.5512 18.4167C93.1879 18.7917 93.9448 18.9792 94.822 18.9792C95.3597 18.9792 95.8478 18.8958 96.2864 18.7292C96.725 18.5625 97.0717 18.3333 97.3263 18.0417C97.581 17.75 97.7083 17.4167 97.7083 17.0417C97.7083 16.7917 97.6659 16.5556 97.581 16.3333C97.4961 16.1111 97.3617 15.9097 97.1778 15.7292C97.008 15.5486 96.7816 15.3819 96.4986 15.2292C96.2298 15.0764 95.9115 14.9444 95.5436 14.8333C95.1758 14.7083 94.7513 14.6042 94.2703 14.5208C93.5062 14.3681 92.8413 14.1667 92.2753 13.9167C91.7094 13.6667 91.2354 13.3542 90.8534 12.9792C90.4714 12.6042 90.1884 12.1806 90.0045 11.7083C89.8206 11.2222 89.7286 10.6806 89.7286 10.0833C89.7286 9.5 89.8559 8.95833 90.1106 8.45833C90.3794 7.95833 90.7402 7.52778 91.193 7.16667C91.6599 6.79167 92.2046 6.50694 92.8271 6.3125C93.4496 6.10417 94.1217 6 94.8433 6C95.6073 6 96.3076 6.09722 96.9443 6.29167C97.581 6.48611 98.1469 6.77778 98.6421 7.16667C99.1373 7.54167 99.5476 8.00694 99.8731 8.5625L98.2601 10.125C97.9772 9.66667 97.6588 9.28472 97.3051 8.97917C96.9514 8.65972 96.5623 8.42361 96.1379 8.27083C95.7134 8.10417 95.2607 8.02083 94.7796 8.02083C94.2278 8.02083 93.7468 8.10417 93.3365 8.27083C92.9262 8.4375 92.6007 8.67361 92.3602 8.97917C92.1338 9.27083 92.0206 9.61806 92.0206 10.0208C92.0206 10.3125 92.0772 10.5833 92.1904 10.8333C92.3036 11.0694 92.4734 11.2847 92.6998 11.4792C92.9403 11.6597 93.2586 11.8264 93.6548 11.9792C94.051 12.1181 94.5249 12.2431 95.0767 12.3542C95.8549 12.5208 96.5482 12.7361 97.1566 13C97.7649 13.25 98.2814 13.5486 98.7058 13.8958C99.1303 14.2431 99.4486 14.6319 99.6608 15.0625C99.8872 15.4931 100 15.9583 100 16.4583C100 17.3889 99.7811 18.1944 99.3425 18.875C98.9039 19.5556 98.2814 20.0833 97.4749 20.4583C96.6684 20.8194 95.7063 21 94.5886 21Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_359_3065"
        x1="21.0634"
        y1="25.1827"
        x2="2.32666"
        y2="14.4092"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00368C" />
        <stop offset="1" stopColor="#005EF5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_359_3065"
        x1="15.9639"
        y1="5.24011"
        x2="15.9639"
        y2="27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#003A98" />
        <stop offset="1" stopColor="#005EF5" />
      </linearGradient>
    </defs>
  </svg>
);
