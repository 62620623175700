export const MegafonSvg = () => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
    >
      <g filter="url(#filter0_d_116_10727)">
        <path
          fill="#E0E0E0"
          d="M10.81 38.054a2.595 2.595 0 015.19 0v13.838a2.595 2.595 0 11-5.19 0V38.054z"
        ></path>
      </g>
      <g filter="url(#filter1_d_116_10727)">
        <path
          fill="url(#paint0_linear_116_10727)"
          fillRule="evenodd"
          d="M15.827 51.892V38.054a2.422 2.422 0 10-4.843 0v13.838a2.422 2.422 0 004.843 0zm-2.422-16.433a2.595 2.595 0 00-2.594 2.595v13.838a2.595 2.595 0 005.189 0V38.054a2.595 2.595 0 00-2.595-2.595z"
          clipRule="evenodd"
          shapeRendering="crispEdges"
        ></path>
      </g>
      <g filter="url(#filter2_dii_116_10727)">
        <path
          fill="url(#paint1_linear_116_10727)"
          d="M5.622 27.675a5.19 5.19 0 015.189-5.189h10.378v15.568H10.811a5.19 5.19 0 01-5.19-5.19v-5.188z"
        ></path>
      </g>
      <g filter="url(#filter3_dii_116_10727)">
        <path
          fill="url(#paint2_linear_116_10727)"
          fillRule="evenodd"
          d="M21.016 22.66H10.811a5.016 5.016 0 00-5.016 5.015v5.19a5.016 5.016 0 005.016 5.016h10.205V22.659zm-10.205-.174a5.19 5.19 0 00-5.19 5.19v5.189a5.19 5.19 0 005.19 5.189h10.378V22.486H10.811z"
          clipRule="evenodd"
          shapeRendering="crispEdges"
        ></path>
      </g>
      <g filter="url(#filter4_dii_116_10727)">
        <path
          fill="url(#paint3_linear_116_10727)"
          d="M40.649 15.784a3.243 3.243 0 116.486 0v29.405a3.243 3.243 0 11-6.486 0V15.784z"
        ></path>
      </g>
      <g filter="url(#filter5_dii_116_10727)">
        <path
          fill="url(#paint4_linear_116_10727)"
          fillRule="evenodd"
          d="M46.962 45.19V15.783a3.07 3.07 0 10-6.14 0v29.405a3.07 3.07 0 006.14 0zm-3.07-32.65a3.243 3.243 0 00-3.243 3.244v29.405a3.243 3.243 0 106.486 0V15.784a3.243 3.243 0 00-3.243-3.244z"
          clipRule="evenodd"
          shapeRendering="crispEdges"
        ></path>
      </g>
      <g filter="url(#filter6_bii_116_10727)">
        <path
          fill="#005EF5"
          fillOpacity="0.2"
          d="M17.73 23.812a3.46 3.46 0 013.032-3.433l5.297-.66c2.533-.316 5.01-.98 7.362-1.975l5.447-2.305c2.281-.965 4.808.71 4.808 3.186v23.29c0 2.477-2.527 4.151-4.808 3.186l-5.447-2.305a27.673 27.673 0 00-7.362-1.974l-5.297-.66a3.46 3.46 0 01-3.032-3.434V23.812z"
        ></path>
      </g>
      <g filter="url(#filter7_bii_116_10727)">
        <path
          fill="url(#paint5_linear_116_10727)"
          fillRule="evenodd"
          d="M43.503 41.915v-23.29c0-2.353-2.4-3.943-4.567-3.027l-5.448 2.305c-2.366 1.001-4.858 1.67-7.408 1.988l-5.297.66a3.287 3.287 0 00-2.88 3.26v12.917a3.287 3.287 0 002.88 3.262l5.297.66c2.55.317 5.042.986 7.408 1.987l5.448 2.305c2.167.917 4.567-.674 4.567-3.027zM20.762 20.38a3.46 3.46 0 00-3.032 3.433v12.916a3.46 3.46 0 003.032 3.433l5.297.66c2.533.316 5.01.98 7.362 1.975l5.447 2.305c2.281.965 4.808-.71 4.808-3.186v-23.29c0-2.477-2.527-4.15-4.808-3.186l-5.447 2.305a27.673 27.673 0 01-7.362 1.975l-5.297.66z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter8_d_116_10727)">
        <path
          fill="#E0E0E0"
          d="M50.954 32.287a2.09 2.09 0 110-4.18h4.58a2.09 2.09 0 110 4.18h-4.58z"
        ></path>
      </g>
      <g filter="url(#filter9_d_116_10727)">
        <path
          fill="url(#paint6_linear_116_10727)"
          fillRule="evenodd"
          d="M55.535 28.28h-4.58a1.916 1.916 0 100 3.834h4.58a1.916 1.916 0 100-3.833zm-6.67 1.917a2.09 2.09 0 002.09 2.09h4.58a2.09 2.09 0 100-4.18h-4.58a2.09 2.09 0 00-2.09 2.09z"
          clipRule="evenodd"
          shapeRendering="crispEdges"
        ></path>
      </g>
      <g filter="url(#filter10_d_116_10727)">
        <path
          fill="#E0E0E0"
          d="M52.319 21.913a2.09 2.09 0 11-2.105-3.61l2.662-1.553a2.09 2.09 0 112.105 3.61l-2.662 1.553z"
        ></path>
      </g>
      <g filter="url(#filter11_d_116_10727)">
        <path
          fill="url(#paint7_linear_116_10727)"
          fillRule="evenodd"
          d="M52.964 16.9L50.3 18.451a1.917 1.917 0 001.93 3.311l2.663-1.553a1.916 1.916 0 10-1.93-3.31zm-3.503 4.26a2.09 2.09 0 002.858.753l2.662-1.553a2.09 2.09 0 00-2.105-3.61l-2.662 1.553a2.09 2.09 0 00-.753 2.857z"
          clipRule="evenodd"
          shapeRendering="crispEdges"
        ></path>
      </g>
      <g filter="url(#filter12_d_116_10727)">
        <path
          fill="#E0E0E0"
          d="M52.319 38.242a2.09 2.09 0 10-2.105 3.61l2.662 1.552a2.09 2.09 0 102.105-3.61l-2.662-1.552z"
        ></path>
      </g>
      <g filter="url(#filter13_d_116_10727)">
        <path
          fill="url(#paint8_linear_116_10727)"
          fillRule="evenodd"
          d="M52.964 43.255L50.3 41.702a1.917 1.917 0 011.93-3.311l2.663 1.553a1.916 1.916 0 11-1.93 3.31zm-3.503-4.261a2.09 2.09 0 012.858-.752l2.662 1.552a2.09 2.09 0 01-2.105 3.61l-2.662-1.553a2.09 2.09 0 01-.753-2.857z"
          clipRule="evenodd"
          shapeRendering="crispEdges"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_116_10727"
          width="9.189"
          height="23.027"
          x="8.811"
          y="34.459"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_116_10727"
          width="9.189"
          height="23.027"
          x="8.811"
          y="34.459"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_dii_116_10727"
          width="19.568"
          height="20.567"
          x="3.622"
          y="20.486"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_116_10727"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2" dy="-2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_116_10727"
            result="effect3_innerShadow_116_10727"
          ></feBlend>
        </filter>
        <filter
          id="filter3_dii_116_10727"
          width="19.568"
          height="20.567"
          x="3.622"
          y="20.486"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_116_10727"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2" dy="-2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_116_10727"
            result="effect3_innerShadow_116_10727"
          ></feBlend>
        </filter>
        <filter
          id="filter4_dii_116_10727"
          width="10.486"
          height="40.892"
          x="38.649"
          y="10.54"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_116_10727"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2" dy="-2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_116_10727"
            result="effect3_innerShadow_116_10727"
          ></feBlend>
        </filter>
        <filter
          id="filter5_dii_116_10727"
          width="10.486"
          height="40.892"
          x="38.649"
          y="10.54"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_116_10727"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2" dy="-2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_116_10727"
            result="effect3_innerShadow_116_10727"
          ></feBlend>
        </filter>
        <filter
          id="filter6_bii_116_10727"
          width="41.946"
          height="46.216"
          x="9.73"
          y="7.162"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="4"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_116_10727"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_116_10727"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_116_10727"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2" dy="-2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_116_10727"
            result="effect3_innerShadow_116_10727"
          ></feBlend>
        </filter>
        <filter
          id="filter7_bii_116_10727"
          width="41.946"
          height="46.216"
          x="9.73"
          y="7.162"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="4"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_116_10727"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_116_10727"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_116_10727"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2" dy="-2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="effect2_innerShadow_116_10727"
            result="effect3_innerShadow_116_10727"
          ></feBlend>
        </filter>
        <filter
          id="filter8_d_116_10727"
          width="12.759"
          height="8.179"
          x="46.865"
          y="27.108"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter9_d_116_10727"
          width="12.759"
          height="8.179"
          x="46.865"
          y="27.108"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter10_d_116_10727"
          width="10.842"
          height="9.732"
          x="47.176"
          y="15.465"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter11_d_116_10727"
          width="10.842"
          height="9.732"
          x="47.176"
          y="15.465"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter12_d_116_10727"
          width="10.842"
          height="9.732"
          x="47.176"
          y="36.956"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter13_d_116_10727"
          width="10.842"
          height="9.732"
          x="47.176"
          y="36.956"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_116_10727"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_116_10727"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_116_10727"
          x1="13.405"
          x2="13.405"
          y1="35.459"
          y2="50.258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_116_10727"
          x1="6.129"
          x2="23.128"
          y1="23.654"
          y2="26.643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005EF5"></stop>
          <stop offset="1" stopColor="#3787FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_116_10727"
          x1="-6.688"
          x2="20.029"
          y1="1.73"
          y2="36.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_116_10727"
          x1="40.86"
          x2="48.154"
          y1="15.232"
          y2="15.464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005EF5"></stop>
          <stop offset="1" stopColor="#3787FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_116_10727"
          x1="35.52"
          x2="63.84"
          y1="-35.315"
          y2="-28.674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_116_10727"
          x1="15.781"
          x2="35.343"
          y1="16.078"
          y2="52.212"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="0.766" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_116_10727"
          x1="48.865"
          x2="55.678"
          y1="30.197"
          y2="30.197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_116_10727"
          x1="49.461"
          x2="54.34"
          y1="21.16"
          y2="18.315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_116_10727"
          x1="49.461"
          x2="54.34"
          y1="38.994"
          y2="41.839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
)
