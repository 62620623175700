export const ColleaguesSearchSvg = () => (<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_ii_649_10602)">
<path d="M4.74879 26.5198C4.85691 26.352 5.32238 25.6628 6.25163 24.716C8.41094 22.5158 11.7965 20.2664 16.5078 20.2664C21.2191 20.2664 24.6047 22.5158 26.764 24.716C27.4779 25.4434 28.0621 26.1657 28.5242 26.8067C29.3992 28.0202 28.885 29.6253 27.5744 30.3469C26.3163 31.0395 24.7399 30.6948 23.8858 29.5402L23.3431 28.8068C21.86 27.2955 19.6418 25.8335 16.5078 25.8335C13.3738 25.8335 11.1556 27.2955 9.67246 28.8068C9.47069 29.0124 9.21681 29.3001 8.95362 29.6106C8.08481 30.6359 6.58267 30.8913 5.45506 30.1603C4.26333 29.3877 3.91735 27.7852 4.69794 26.5987C4.71523 26.5724 4.73175 26.5462 4.74879 26.5198Z" fill="url(#paint0_linear_649_10602)"/>
</g>
<g filter="url(#filter1_ii_649_10602)">
<path fillRule="evenodd" clipRule="evenodd" d="M6.18999 24.6555C8.36003 22.4443 11.7665 20.1799 16.5079 20.1799C21.2492 20.1799 24.6558 22.4443 26.8258 24.6555C27.5432 25.3865 28.1302 26.1122 28.5945 26.7562C29.5054 28.0195 28.9636 29.6809 27.6162 30.4227C26.3202 31.1362 24.6962 30.7811 23.8163 29.5917L23.2773 28.8631C21.805 27.3644 19.609 25.9201 16.5079 25.9201C13.4039 25.9201 11.2066 27.3672 9.73428 28.8674C9.53477 29.0707 9.28253 29.3565 9.01969 29.6666C8.12409 30.7235 6.57392 30.9887 5.4081 30.2329C4.17659 29.4345 3.81848 27.7783 4.62578 26.5512C4.63712 26.534 4.6479 26.5171 4.65905 26.4997C4.66462 26.491 4.6703 26.4821 4.67618 26.473C4.7871 26.3009 5.25616 25.607 6.18999 24.6555ZM16.5079 20.3529C11.8267 20.3529 8.46203 22.5873 6.31344 24.7766C5.38877 25.7188 4.92689 26.4033 4.82158 26.5667C4.81629 26.5749 4.81092 26.5833 4.80548 26.5918C4.79399 26.6097 4.7822 26.6282 4.77028 26.6463C4.01639 27.7922 4.35024 29.3409 5.5022 30.0878C6.59161 30.794 8.04571 30.5484 8.88773 29.5548C9.15128 29.2438 9.40679 28.9541 9.61083 28.7462C11.1048 27.224 13.3439 25.7471 16.5079 25.7471C19.6719 25.7471 21.911 27.224 23.405 28.7462L23.4092 28.7505L23.9554 29.4888C24.7838 30.6086 26.3126 30.9429 27.5328 30.2712C28.8066 29.5699 29.2933 28.021 28.4542 26.8573C27.9942 26.2193 27.4128 25.5005 26.7024 24.7766C24.5538 22.5873 21.1891 20.3529 16.5079 20.3529Z" fill="url(#paint1_linear_649_10602)"/>
</g>
<g filter="url(#filter2_ii_649_10602)">
<path d="M10.0381 11.0368C10.0381 7.3296 13.0433 4.32434 16.7505 4.32434V4.32434C20.4577 4.32434 23.463 7.3296 23.463 11.0368V11.0368C23.463 14.744 20.4577 17.7492 16.7505 17.7492V17.7492C13.0433 17.7492 10.0381 14.744 10.0381 11.0368V11.0368Z" fill="url(#paint2_linear_649_10602)"/>
</g>
<g filter="url(#filter3_ii_649_10602)">
<path fillRule="evenodd" clipRule="evenodd" d="M10.2111 11.0368C10.2111 14.6484 13.1389 17.5762 16.7505 17.5762C20.3622 17.5762 23.29 14.6484 23.29 11.0368C23.29 7.42513 20.3622 4.49731 16.7505 4.49731C13.1389 4.49731 10.2111 7.42513 10.2111 11.0368ZM16.7505 4.32434C13.0433 4.32434 10.0381 7.3296 10.0381 11.0368C10.0381 14.744 13.0433 17.7492 16.7505 17.7492C20.4577 17.7492 23.463 14.744 23.463 11.0368C23.463 7.3296 20.4577 4.32434 16.7505 4.32434Z" fill="url(#paint3_linear_649_10602)"/>
</g>
<g filter="url(#filter4_ii_649_10602)">
<path d="M35.0637 26.5198C35.1718 26.352 35.6373 25.6628 36.5666 24.716C38.7259 22.5158 42.1115 20.2664 46.8227 20.2664C51.534 20.2664 54.9196 22.5158 57.0789 24.716C57.7929 25.4434 58.377 26.1657 58.8392 26.8067C59.7142 28.0202 59.1999 29.6253 57.8894 30.3469C56.6313 31.0395 55.0548 30.6948 54.2007 29.5402L53.6581 28.8068C52.175 27.2955 49.9568 25.8335 46.8227 25.8335C43.6887 25.8335 41.4705 27.2955 39.9874 28.8068C39.7856 29.0124 39.5318 29.3001 39.2686 29.6106C38.3998 30.6359 36.8976 30.8913 35.77 30.1603C34.5783 29.3877 34.2323 27.7852 35.0129 26.5987C35.0302 26.5724 35.0467 26.5462 35.0637 26.5198Z" fill="url(#paint4_linear_649_10602)"/>
</g>
<g filter="url(#filter5_ii_649_10602)">
<path fillRule="evenodd" clipRule="evenodd" d="M36.5049 24.6555C38.675 22.4443 42.0815 20.1799 46.8228 20.1799C51.5642 20.1799 54.9707 22.4443 57.1407 24.6555C57.8582 25.3865 58.4451 26.1122 58.9094 26.7562C59.8203 28.0195 59.2785 29.6809 57.9312 30.4227C56.6351 31.1362 55.0112 30.7811 54.1313 29.5917L53.5922 28.8631C52.1199 27.3644 49.9239 25.9201 46.8228 25.9201C43.7188 25.9201 41.5215 27.3672 40.0492 28.8674C39.8497 29.0707 39.5975 29.3565 39.3346 29.6666C38.439 30.7235 36.8889 30.9887 35.723 30.2329C34.4915 29.4345 34.1334 27.7783 34.9407 26.5512C34.9521 26.534 34.9628 26.5171 34.974 26.4997C34.9796 26.491 34.9852 26.4821 34.9911 26.473C35.102 26.3009 35.5711 25.607 36.5049 24.6555ZM46.8228 20.3529C42.1417 20.3529 38.777 22.5873 36.6284 24.7766C35.7037 25.7188 35.2418 26.4033 35.1365 26.5667C35.1312 26.5749 35.1259 26.5833 35.1204 26.5918C35.1089 26.6097 35.0971 26.6282 35.0852 26.6463C34.3313 27.7922 34.6652 29.3409 35.8171 30.0878C36.9065 30.794 38.3607 30.5484 39.2027 29.5548C39.4662 29.2438 39.7217 28.9541 39.9258 28.7462C41.4197 27.224 43.6588 25.7471 46.8228 25.7471C49.9868 25.7471 52.2259 27.224 53.7199 28.7462L53.7241 28.7505L54.2703 29.4888C55.0987 30.6086 56.6276 30.9429 57.8477 30.2712C59.1215 29.5699 59.6082 28.021 58.7691 26.8573C58.3091 26.2193 57.7277 25.5005 57.0173 24.7766C54.8687 22.5873 51.504 20.3529 46.8228 20.3529Z" fill="url(#paint5_linear_649_10602)"/>
</g>
<g filter="url(#filter6_ii_649_10602)">
<path d="M40.353 11.0368C40.353 7.3296 43.3583 4.32434 47.0655 4.32434V4.32434C50.7726 4.32434 53.7779 7.3296 53.7779 11.0368V11.0368C53.7779 14.744 50.7726 17.7492 47.0655 17.7492V17.7492C43.3583 17.7492 40.353 14.744 40.353 11.0368V11.0368Z" fill="url(#paint6_linear_649_10602)"/>
</g>
<g filter="url(#filter7_ii_649_10602)">
<path fillRule="evenodd" clipRule="evenodd" d="M40.526 11.0368C40.526 14.6484 43.4538 17.5762 47.0655 17.5762C50.6771 17.5762 53.6049 14.6484 53.6049 11.0368C53.6049 7.42513 50.6771 4.49731 47.0655 4.49731C43.4538 4.49731 40.526 7.42513 40.526 11.0368ZM47.0655 4.32434C43.3583 4.32434 40.353 7.3296 40.353 11.0368C40.353 14.744 43.3583 17.7492 47.0655 17.7492C50.7726 17.7492 53.7779 14.744 53.7779 11.0368C53.7779 7.3296 50.7726 4.32434 47.0655 4.32434Z" fill="url(#paint7_linear_649_10602)"/>
</g>
<g filter="url(#filter8_d_649_10602)">
<path d="M29.7953 57.0811H26.5251C24.9069 57.0811 23.5952 55.7694 23.5952 54.1512C23.5952 53.4275 23.3273 52.7294 22.8432 52.1914L14.916 43.3822C14.0875 42.4615 14.1789 41.0394 15.1184 40.2324C15.9898 39.4839 17.2896 39.5292 18.1068 40.3364L20.2827 42.4857C20.8291 43.0254 21.7554 42.6384 21.7554 41.8704V26.5592C21.7554 25.5041 22.6108 24.6487 23.666 24.6487C24.7211 24.6487 25.5765 25.5041 25.5765 26.5592V32.1976C25.9283 31.6764 26.5254 31.3753 27.1536 31.4023L27.3426 31.4104C28.4916 31.4597 29.3977 32.4056 29.3977 33.5556V34.4343C29.6613 34.0003 30.1323 33.7354 30.64 33.7354H31.0451C32.2456 33.7354 33.2188 34.7085 33.2188 35.909V37.0718C33.5727 36.5474 34.1708 36.233 34.8035 36.233C35.8752 36.233 36.7569 37.1018 36.7569 38.1736V38.9078V45.5062C36.7569 46.4922 36.4759 47.4578 35.947 48.29L33.4259 52.256C33.1057 52.7596 32.9357 53.344 32.9357 53.9407C32.9357 55.6751 31.5297 57.0811 29.7953 57.0811Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter9_d_649_10602)">
<path fillRule="evenodd" clipRule="evenodd" d="M21.669 26.5592C21.669 25.4562 22.5631 24.5621 23.666 24.5621C24.769 24.5621 25.6631 25.4562 25.6631 26.5592V31.946C26.0385 31.526 26.5857 31.2912 27.1574 31.3158L27.3464 31.3239C28.5416 31.3752 29.4842 32.3592 29.4842 33.5555V34.1711C29.7737 33.8423 30.1935 33.6488 30.6401 33.6488H31.0452C32.2934 33.6488 33.3053 34.6607 33.3053 35.9089V36.8193C33.6818 36.3944 34.2291 36.1465 34.8036 36.1465C35.9223 36.1465 36.8434 37.0533 36.8434 38.1735V45.5061C36.8434 46.5086 36.5578 47.4903 36.02 48.3363L33.4989 52.3023C33.1876 52.7921 33.0223 53.3604 33.0223 53.9406C33.0223 55.7228 31.5776 57.1675 29.7954 57.1675H26.5251C24.8592 57.1675 23.5088 55.8171 23.5088 54.1512C23.5088 53.4488 23.2488 52.7713 22.779 52.2492L14.8517 43.44C13.9908 42.4832 14.0858 41.0054 15.0622 40.1667C15.9677 39.3889 17.3184 39.4359 18.1677 40.2748L20.3436 42.4241C20.8353 42.9098 21.669 42.5615 21.669 41.8704V26.5592ZM33.3053 37.0985V39.3271H33.1324V35.9089C33.1324 34.7562 32.1979 33.8218 31.0452 33.8218H30.6401C30.1705 33.8218 29.7344 34.0627 29.4842 34.4589V37.2302H29.3112V33.5555C29.3112 32.4518 28.4416 31.5441 27.3389 31.4967L27.15 31.4886C26.5605 31.4633 25.9998 31.7414 25.6631 32.2244V37.2302H25.4901V26.5592C25.4901 25.5518 24.6734 24.7351 23.666 24.7351C22.6586 24.7351 21.8419 25.5518 21.8419 26.5592V41.8704C21.8419 42.7151 20.823 43.1408 20.222 42.5472L18.0461 40.3978C17.2609 39.6223 16.0121 39.5788 15.1749 40.2979C14.2721 41.0733 14.1843 42.4397 14.9803 43.3243L22.9076 52.1335C23.406 52.6874 23.6817 53.4061 23.6817 54.1512C23.6817 55.7215 24.9548 56.9946 26.5251 56.9946H29.7954C31.482 56.9946 32.8493 55.6273 32.8493 53.9406C32.8493 53.3275 33.024 52.727 33.3529 52.2096L35.874 48.2435C36.3942 47.4252 36.6704 46.4757 36.6704 45.5061V38.1735C36.6704 37.1503 35.8283 36.3195 34.8036 36.3195C34.208 36.3195 33.6447 36.6112 33.3053 37.0985Z" fill="url(#paint8_linear_649_10602)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter10_bii_649_10602)">
<path d="M32.8647 47.1351C32.8647 42.3586 36.7369 38.4865 41.5134 38.4865V38.4865C46.2899 38.4865 50.162 42.3586 50.162 47.1351V47.1351C50.162 51.9116 46.2899 55.7837 41.5134 55.7837V55.7837C36.7369 55.7837 32.8647 51.9116 32.8647 47.1351V47.1351Z" fill="#005EF5" fillOpacity="0.2"/>
</g>
<g filter="url(#filter11_bii_649_10602)">
<path fillRule="evenodd" clipRule="evenodd" d="M33.0377 47.1351C33.0377 51.8161 36.8324 55.6108 41.5134 55.6108C46.1944 55.6108 49.9891 51.8161 49.9891 47.1351C49.9891 42.4541 46.1944 38.6594 41.5134 38.6594C36.8324 38.6594 33.0377 42.4541 33.0377 47.1351ZM41.5134 38.4865C36.7369 38.4865 32.8647 42.3586 32.8647 47.1351C32.8647 51.9116 36.7369 55.7837 41.5134 55.7837C46.2899 55.7837 50.162 51.9116 50.162 47.1351C50.162 42.3586 46.2899 38.4865 41.5134 38.4865Z" fill="#005EF5" fillOpacity="0.2"/>
</g>
<path fillRule="evenodd" clipRule="evenodd" d="M46.4399 44.3521C46.7827 44.6846 46.7911 45.2322 46.4586 45.575L41.627 50.5566C40.9475 51.2572 39.8232 51.2572 39.1437 50.5566L36.5683 47.9012C36.2357 47.5584 36.2441 47.0108 36.5869 46.6783C36.9298 46.3457 37.4774 46.3541 37.8099 46.6969L40.3853 49.3523L45.2169 44.3708C45.5495 44.0279 46.097 44.0195 46.4399 44.3521Z" fill="white"/>
<defs>
<filter id="filter0_ii_649_10602" x="2.27979" y="18.2664" width="28.6782" height="14.4363" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10602"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10602" result="effect2_innerShadow_649_10602"/>
</filter>
<filter id="filter1_ii_649_10602" x="2.19336" y="18.1799" width="28.8521" height="14.6093" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10602"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10602" result="effect2_innerShadow_649_10602"/>
</filter>
<filter id="filter2_ii_649_10602" x="8.03809" y="2.32434" width="17.4248" height="17.4249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10602"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10602" result="effect2_innerShadow_649_10602"/>
</filter>
<filter id="filter3_ii_649_10602" x="8.03809" y="2.32434" width="17.4248" height="17.4249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10602"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10602" result="effect2_innerShadow_649_10602"/>
</filter>
<filter id="filter4_ii_649_10602" x="32.5947" y="18.2664" width="28.6782" height="14.4363" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10602"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10602" result="effect2_innerShadow_649_10602"/>
</filter>
<filter id="filter5_ii_649_10602" x="32.5083" y="18.1799" width="28.8521" height="14.6093" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10602"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10602" result="effect2_innerShadow_649_10602"/>
</filter>
<filter id="filter6_ii_649_10602" x="38.353" y="2.32434" width="17.4248" height="17.4249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10602"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10602" result="effect2_innerShadow_649_10602"/>
</filter>
<filter id="filter7_ii_649_10602" x="38.353" y="2.32434" width="17.4248" height="17.4249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_649_10602"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_649_10602" result="effect2_innerShadow_649_10602"/>
</filter>
<filter id="filter8_d_649_10602" x="12.3496" y="23.6487" width="26.4072" height="36.4324" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10602"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10602" result="shape"/>
</filter>
<filter id="filter9_d_649_10602" x="12.2632" y="23.5621" width="26.5801" height="36.6053" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10602"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10602" result="shape"/>
</filter>
<filter id="filter10_bii_649_10602" x="24.8647" y="30.4865" width="33.2974" height="33.2972" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_649_10602"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_649_10602" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10602"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10602" result="effect3_innerShadow_649_10602"/>
</filter>
<filter id="filter11_bii_649_10602" x="24.8647" y="30.4865" width="33.2974" height="33.2972" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_649_10602"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_649_10602" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10602"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10602" result="effect3_innerShadow_649_10602"/>
</filter>
<linearGradient id="paint0_linear_649_10602" x1="3.92123" y1="21.1264" x2="29.7633" y2="31.7678" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint1_linear_649_10602" x1="-0.782938" y1="-17.8731" x2="10.076" y2="27.2491" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_649_10602" x1="10.4759" y1="5.33121" x2="25.1345" y2="7.90918" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint3_linear_649_10602" x1="7.28396" y1="-44.7368" x2="33.6797" y2="1.64703" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_649_10602" x1="34.2362" y1="21.1264" x2="60.0782" y2="31.7678" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint5_linear_649_10602" x1="29.532" y1="-17.8731" x2="40.3909" y2="27.2491" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_649_10602" x1="40.7908" y1="5.33121" x2="55.4494" y2="7.90918" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint7_linear_649_10602" x1="37.5989" y1="-44.7368" x2="63.9947" y2="1.64703" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_649_10602" x1="25.0813" y1="24.6486" x2="25.0813" y2="49.8738" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
</defs>
</svg>
)
