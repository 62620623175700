export const MeasuresToEnsureTheSecurityOfPersonalData = () => (
  <div>
    <div className="privacy_content_title">
      Меры по обеспечению безопасности персональных данных
    </div>
    <div className="privacy_content">
      <div>
        Охрана полученных Оператором персональных данных пользователей
        обеспечивается путем реализации правовых, организационных и технических
        мер, необходимых для выполнения в полном объеме требований действующего
        законодательства в области защиты персональных данных.
      </div>
      <div>
        Оператор ни при каких обстоятельствах не продает и не отдает в
        пользование личную (персональную) информацию о пользователе каким бы то
        ни было третьим сторонам.
      </div>
      <div>
        Оператор не раскрывает предоставленную личную (персональную) информацию
        третьим лицам, за исключением случаев предусмотренных законодательством
        Российской Федерации.
      </div>
      <div>
        Оператор обеспечивает сохранность персональных данных и принимает все
        возможные меры, исключающие доступ к персональным данным
        неуполномоченных лиц.
      </div>
      <div>
        При выявлении неточностей в переданных персональных данных, пользователь
        может актуализировать их самостоятельно, путем направления Оператору
        уведомление на адрес электронной почты Оператора.
      </div>
      <div>
        Срок обработки персональных данных является неограниченным во времени.
        Пользователь может в любой момент отозвать свое согласие на обработку
        персональных данных, направив Оператору соответствующее уведомление
        посредством электронной почты на электронный адрес Оператора.
      </div>
    </div>
  </div>
)
