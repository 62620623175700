import React from 'react'
import { HomePage, PrivacyPage, MultipostingPage } from './pages'
import './global/globalStyles.scss'
import './global/variables.css'
import { HashRouter, Route, Routes } from 'react-router-dom'
import ScrollToTop from './hooks/scrollToTop'
import { LayoutProvider } from './contexts/LayoutContext/LayoutContext'
import { Wrapper } from './components/layout/Wrapper/Wrapper'
import { Header } from './components/common/Header/Header'
import { Footer } from './components/common/Footer/Footer'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

function App () {
  return (
    <div className="App">
      <GoogleReCaptchaProvider
        reCaptchaKey='6LcoZTgmAAAAAMEL2rfUq0cwoGXnP7lzzJe2Ynz2'
        language='ru'
      >
        <LayoutProvider>
          <Wrapper>
            <HashRouter>
              <ScrollToTop />
              <Header />
              <Routes>
                <Route path="*" element={<HomePage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/solutions/multiposting" element={<MultipostingPage />} />
              </Routes>
              <Footer />
            </HashRouter>
          </Wrapper>
        </LayoutProvider>
      </GoogleReCaptchaProvider>
    </div>
  )
}

export default App as React.FC
