export const ComputerSvgLight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 64 64"
  >
    <g filter="url(#filter0_d_115_10610)">
      <path
        fill="#E0E0E0"
        fillRule="evenodd"
        d="M27.541 43.676a1.73 1.73 0 00-1.524 2.548l2.592 4.826a.865.865 0 01-.762 1.274h-6.442a1.514 1.514 0 000 3.028H42.162a1.514 1.514 0 000-3.028h-5.677a.865.865 0 01-.785-1.228l2.298-4.964a1.73 1.73 0 00-1.57-2.456h-8.887z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter1_d_115_10610)">
      <path
        fill="url(#paint0_linear_115_10610)"
        fillRule="evenodd"
        d="M27.541 43.849a1.557 1.557 0 00-1.372 2.293l2.593 4.826a1.038 1.038 0 01-.915 1.53h-6.442a1.34 1.34 0 100 2.68h20.757a1.34 1.34 0 000-2.68h-5.677c-.758 0-1.26-.787-.942-1.474l2.298-4.965a1.557 1.557 0 00-1.413-2.21h-8.887zm-1.524 2.375a1.73 1.73 0 011.524-2.548h8.887a1.73 1.73 0 011.57 2.456L35.7 51.096a.865.865 0 00.785 1.228h5.677a1.514 1.514 0 010 3.028H21.405a1.514 1.514 0 010-3.028h6.442a.865.865 0 00.762-1.274l-2.592-4.826z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter2_ii_115_10610)">
      <path
        fill="url(#paint1_linear_115_10610)"
        d="M7.784 19.892c0-2.418 0-3.627.395-4.58a5.19 5.19 0 012.808-2.81c.954-.394 2.163-.394 4.58-.394h32.865c2.418 0 3.627 0 4.58.395a5.19 5.19 0 012.81 2.808c.394.954.394 2.163.394 4.58 0 2.419 0 3.628-.395 4.581a5.19 5.19 0 01-2.808 2.808c-.954.395-2.163.395-4.58.395H15.567c-2.418 0-3.627 0-4.58-.395a5.19 5.19 0 01-2.81-2.808c-.394-.954-.394-2.162-.394-4.58z"
      ></path>
    </g>
    <g filter="url(#filter3_ii_115_10610)">
      <path
        fill="url(#paint2_linear_115_10610)"
        fillRule="evenodd"
        d="M48.432 12.28H15.568c-1.212 0-2.111.001-2.828.05-.715.049-1.234.145-1.687.333a5.016 5.016 0 00-2.714 2.714c-.188.453-.284.972-.333 1.687-.05.717-.05 1.616-.05 2.828 0 1.211 0 2.11.05 2.827.049.715.145 1.235.333 1.687a5.016 5.016 0 002.714 2.715c.453.187.972.284 1.687.332.716.05 1.616.05 2.828.05h32.864c1.212 0 2.111 0 2.828-.05.715-.048 1.234-.145 1.687-.332a5.016 5.016 0 002.714-2.715c.188-.452.285-.972.333-1.687.05-.716.05-1.616.05-2.827 0-1.212 0-2.111-.05-2.828-.048-.715-.145-1.234-.333-1.687a5.016 5.016 0 00-2.714-2.714c-.453-.188-.972-.284-1.687-.333-.716-.049-1.616-.05-2.828-.05zM8.18 15.312c-.395.954-.395 2.163-.395 4.58 0 2.419 0 3.628.395 4.581a5.19 5.19 0 002.808 2.808c.954.395 2.163.395 4.58.395h32.865c2.418 0 3.627 0 4.58-.395a5.19 5.19 0 002.81-2.808c.394-.954.394-2.162.394-4.58s0-3.627-.395-4.58a5.19 5.19 0 00-2.808-2.81c-.954-.394-2.163-.394-4.58-.394H15.567c-2.418 0-3.627 0-4.58.395a5.19 5.19 0 00-2.81 2.808z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter4_bii_115_10610)">
      <path
        fill="url(#paint3_linear_115_10610)"
        fillOpacity="0.24"
        d="M7.784 31.135c0-4.892 0-7.338 1.52-8.858 1.52-1.52 3.966-1.52 8.858-1.52h27.676c4.892 0 7.338 0 8.858 1.52 1.52 1.52 1.52 3.966 1.52 8.858v9.081c0 4.893 0 7.339-1.52 8.859-1.52 1.52-3.966 1.52-8.858 1.52H18.162c-4.892 0-7.338 0-8.858-1.52-1.52-1.52-1.52-3.966-1.52-8.859v-9.08z"
      ></path>
    </g>
    <g filter="url(#filter5_bii_115_10610)">
      <path
        fill="url(#paint4_linear_115_10610)"
        fillRule="evenodd"
        d="M45.838 20.93H18.162c-2.45 0-4.272 0-5.67.188-1.395.188-2.344.559-3.066 1.281-.722.723-1.093 1.671-1.28 3.065-.189 1.4-.19 3.22-.19 5.671v9.081c0 2.451.001 4.272.19 5.671.187 1.394.558 2.343 1.28 3.066.722.722 1.671 1.093 3.065 1.28 1.4.188 3.22.189 5.671.189h27.676c2.45 0 4.272 0 5.67-.189 1.395-.187 2.344-.558 3.066-1.28.723-.723 1.093-1.672 1.28-3.066.189-1.399.19-3.22.19-5.67v-9.082c0-2.45-.001-4.272-.19-5.67-.187-1.395-.557-2.343-1.28-3.066-.722-.722-1.671-1.093-3.065-1.28-1.4-.189-3.22-.19-5.671-.19zM9.304 22.277c-1.52 1.52-1.52 3.966-1.52 8.858v9.081c0 4.893 0 7.339 1.52 8.859 1.52 1.52 3.966 1.52 8.858 1.52h27.676c4.892 0 7.338 0 8.858-1.52 1.52-1.52 1.52-3.966 1.52-8.859v-9.08c0-4.893 0-7.34-1.52-8.86-1.52-1.52-3.966-1.52-8.858-1.52H18.162c-4.892 0-7.338 0-8.858 1.52z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter6_d_115_10610)">
      <path
        fill="#E0E0E0"
        d="M11.243 16.865a2.162 2.162 0 014.324 0 2.162 2.162 0 01-4.324 0z"
      ></path>
    </g>
    <g filter="url(#filter7_d_115_10610)">
      <path
        fill="url(#paint5_linear_115_10610)"
        fillRule="evenodd"
        d="M11.416 16.865a1.99 1.99 0 103.979 0 1.99 1.99 0 00-3.979 0zm1.99-2.162a2.162 2.162 0 100 4.324 2.162 2.162 0 000-4.324z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter8_d_115_10610)">
      <path
        fill="#E0E0E0"
        d="M17.297 16.865a2.162 2.162 0 014.324 0 2.162 2.162 0 01-4.324 0z"
      ></path>
    </g>
    <g filter="url(#filter9_d_115_10610)">
      <path
        fill="url(#paint6_linear_115_10610)"
        fillRule="evenodd"
        d="M17.47 16.865a1.99 1.99 0 103.979 0 1.99 1.99 0 00-3.979 0zm1.99-2.162a2.162 2.162 0 100 4.324 2.162 2.162 0 000-4.324z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter10_d_115_10610)">
      <path
        fill="#E0E0E0"
        d="M23.351 16.865a2.162 2.162 0 014.324 0 2.162 2.162 0 01-4.324 0z"
      ></path>
    </g>
    <g filter="url(#filter11_d_115_10610)">
      <path
        fill="url(#paint7_linear_115_10610)"
        fillRule="evenodd"
        d="M23.524 16.865a1.99 1.99 0 103.979 0 1.99 1.99 0 00-3.979 0zm1.99-2.162a2.162 2.162 0 100 4.324 2.162 2.162 0 000-4.324z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter12_d_115_10610)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M48.2 36.766c.776-.145.968-1.167.299-1.584l-9.743-6.07a.865.865 0 00-1.322.763l.386 11.473c.027.788 1.007 1.132 1.52.534l1.492-1.739a.865.865 0 011.406.13l1.71 2.963a.865.865 0 001.181.317l1.625-.938a.865.865 0 00.316-1.181l-1.71-2.963a.865.865 0 01.59-1.282l2.25-.423z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter13_d_115_10610)">
      <path
        fill="url(#paint8_linear_115_10610)"
        fillRule="evenodd"
        d="M48.407 35.33l-9.743-6.072a.692.692 0 00-1.057.61l.386 11.474a.692.692 0 001.216.427l1.492-1.738a1.038 1.038 0 011.687.157l1.71 2.962c.191.33.614.444.945.253l1.624-.938a.692.692 0 00.253-.945l-1.71-2.962a1.038 1.038 0 01.707-1.54l2.252-.422a.692.692 0 00.238-1.267zm.092-.148c.669.417.477 1.439-.298 1.584l-2.252.423a.865.865 0 00-.59 1.282l1.711 2.963a.865.865 0 01-.316 1.181l-1.624.938a.865.865 0 01-1.182-.317l-1.71-2.962a.865.865 0 00-1.406-.13l-1.491 1.738c-.514.598-1.494.254-1.52-.534l-.387-11.473a.865.865 0 011.322-.764l9.743 6.071z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_d_115_10610"
        width="27.784"
        height="15.676"
        x="17.892"
        y="42.676"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10610"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10610"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter1_d_115_10610"
        width="27.784"
        height="15.676"
        x="17.892"
        y="42.676"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10610"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10610"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter2_ii_115_10610"
        width="52.432"
        height="19.567"
        x="5.784"
        y="10.108"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow_115_10610"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect1_innerShadow_115_10610"
          result="effect2_innerShadow_115_10610"
        ></feBlend>
      </filter>
      <filter
        id="filter3_ii_115_10610"
        width="52.432"
        height="19.567"
        x="5.784"
        y="10.108"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow_115_10610"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect1_innerShadow_115_10610"
          result="effect2_innerShadow_115_10610"
        ></feBlend>
      </filter>
      <filter
        id="filter4_bii_115_10610"
        width="64.432"
        height="45.838"
        x="-0.216"
        y="12.757"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10610"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10610"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10610"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10610"
          result="effect3_innerShadow_115_10610"
        ></feBlend>
      </filter>
      <filter
        id="filter5_bii_115_10610"
        width="64.432"
        height="45.838"
        x="-0.216"
        y="12.757"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10610"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10610"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10610"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10610"
          result="effect3_innerShadow_115_10610"
        ></feBlend>
      </filter>
      <filter
        id="filter6_d_115_10610"
        width="8.324"
        height="8.324"
        x="9.243"
        y="13.703"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10610"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10610"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter7_d_115_10610"
        width="8.324"
        height="8.324"
        x="9.243"
        y="13.703"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10610"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10610"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter8_d_115_10610"
        width="8.324"
        height="8.324"
        x="15.297"
        y="13.703"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10610"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10610"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter9_d_115_10610"
        width="8.324"
        height="8.324"
        x="15.297"
        y="13.703"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10610"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10610"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter10_d_115_10610"
        width="8.324"
        height="8.324"
        x="21.351"
        y="13.703"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10610"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10610"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter11_d_115_10610"
        width="8.324"
        height="8.324"
        x="21.351"
        y="13.703"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10610"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10610"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter12_d_115_10610"
        width="13.473"
        height="16.69"
        x="36.433"
        y="28.979"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10610"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10610"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter13_d_115_10610"
        width="13.473"
        height="16.69"
        x="36.433"
        y="28.979"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10610"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10610"
          result="shape"
        ></feBlend>
      </filter>
      <linearGradient
        id="paint0_linear_115_10610"
        x1="19.892"
        x2="38.39"
        y1="53.838"
        y2="53.838"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_115_10610"
        x1="9.363"
        x2="51.321"
        y1="13.275"
        y2="36.233"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5"></stop>
        <stop offset="1" stopColor="#3787FF"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_115_10610"
        x1="-2.152"
        x2="10.451"
        y1="-44.784"
        y2="24.117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_115_10610"
        x1="32"
        x2="32"
        y1="50.595"
        y2="20.757"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5"></stop>
        <stop offset="1" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear_115_10610"
        x1="2.519"
        x2="16.507"
        y1="18.044"
        y2="64.177"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="0.766" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint5_linear_115_10610"
        x1="13.405"
        x2="13.405"
        y1="14.703"
        y2="18.066"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint6_linear_115_10610"
        x1="19.459"
        x2="19.459"
        y1="14.703"
        y2="18.066"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint7_linear_115_10610"
        x1="25.514"
        x2="25.514"
        y1="14.703"
        y2="18.066"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint8_linear_115_10610"
        x1="37.379"
        x2="43.887"
        y1="28.254"
        y2="48.708"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
  </svg>
)
