export const pieChartPublications = [
  { value: 250, name: 'HH.ru', color: '#013385' },
  { value: 182, name: 'Avito', color: '#02B7F8' },
  { value: 200, name: 'SuperJob', color: '#005EF5' },
  { value: 150, name: 'Zarplata.ru', color: '#94EFFF' },
  { value: 80, name: 'Rabota', color: '#A9BFF8' },
  {
    value: 70,
    name: 'Карьерный сайт',
    color: {
      image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAYAAACOqiAdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ5SURBVHgB7drNShxBFMXx8sTJLEIEAyNkfP8nyC7PkMeICShEMLgYP1rnVEFfe7q+bq3PH8RZXAb6R4N1C89+/Pz19u/+IbCr3WW4+PollHr4/xhu7+7j52+XF/Gn1OHwHG7+3oVpmuJ38rtLvby8ht9/buPv7edNuN5fBeCsObs5Pw/7/e74+1N2dprews1x9vD03JxlfDY+I4Bw/X0XtttNcRZCS3nQaAZ+EJoPLcIJzY/GIDQ/Gp8NpUGhWadofMYsnNCsHBpbwQnNKqGxBZzQrBoam+GEZrXQWIQTmtWDxiA0qxeNQWgpD1r8Tn4Qmg+N3w2h+dEYhDZ2O1RcuYRm5f6AZuGEZpVOHSs4oVm1o9oCTmhW63w7wwnN6lkKIpzQrN5NCkKzPOsnhJby7uwQ2tjtEIQ2djsEofnRqiuX0KxTtCKc0KwcGlvBCc0qobEFnNCsGhqb4YRmtdBYhBOa1YPGIDSrF41BaCkPGg0gND8aLSA0P1p844TmR+PLBqH50eLL1hoUWv7UUV25hFY+qhVXLqHVz7fZlUto7aVgtXIJrW+TgtAsz/oJoaW8OzuENnY7BKGN3Q5BaGO3QxDa2O1QduUSWn72o8UKTmj52VOLBZzQ8rM5ixlOaPnZkkWEE1p+tmYBoeVnWxYQ2nq2ZymA0MZuhyC0sdshCG3sdghCG7sdKv5HptBSpVNHFk5oqdpRbQUntFTrfLuAE1qqZymY4YSW6t2kIpzQUp71E0JLeXd2CM2PRgcIzY8W4YQ2gHZ82SA0PxpfNrQGmdCWaHzZUBtkQlujMZQGmdDyaAy5QSa0MhrD6SATWh2N4eMgE1objb0DP7p2C7K0A20AAAAASUVORK5CYII=',
      repeat: 'repeat'
    }
  }
]

export const pieChartResume = [
  { value: 150, name: 'HH.ru', color: '#013385' },
  { value: 80, name: 'Avito', color: '#02B7F8' },
  { value: 70, name: 'SuperJob', color: '#005EF5' },
  { value: 33, name: 'Zarplata.ru', color: '#94EFFF' },
  { value: 25, name: 'Rabota', color: '#A9BFF8' },
  {
    value: 13,
    name: 'Карьерный сайт',
    color: {
      image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAYAAACOqiAdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ5SURBVHgB7drNShxBFMXx8sTJLEIEAyNkfP8nyC7PkMeICShEMLgYP1rnVEFfe7q+bq3PH8RZXAb6R4N1C89+/Pz19u/+IbCr3WW4+PollHr4/xhu7+7j52+XF/Gn1OHwHG7+3oVpmuJ38rtLvby8ht9/buPv7edNuN5fBeCsObs5Pw/7/e74+1N2dprews1x9vD03JxlfDY+I4Bw/X0XtttNcRZCS3nQaAZ+EJoPLcIJzY/GIDQ/Gp8NpUGhWadofMYsnNCsHBpbwQnNKqGxBZzQrBoam+GEZrXQWIQTmtWDxiA0qxeNQWgpD1r8Tn4Qmg+N3w2h+dEYhDZ2O1RcuYRm5f6AZuGEZpVOHSs4oVm1o9oCTmhW63w7wwnN6lkKIpzQrN5NCkKzPOsnhJby7uwQ2tjtEIQ2djsEofnRqiuX0KxTtCKc0KwcGlvBCc0qobEFnNCsGhqb4YRmtdBYhBOa1YPGIDSrF41BaCkPGg0gND8aLSA0P1p844TmR+PLBqH50eLL1hoUWv7UUV25hFY+qhVXLqHVz7fZlUto7aVgtXIJrW+TgtAsz/oJoaW8OzuENnY7BKGN3Q5BaGO3QxDa2O1QduUSWn72o8UKTmj52VOLBZzQ8rM5ixlOaPnZkkWEE1p+tmYBoeVnWxYQ2nq2ZymA0MZuhyC0sdshCG3sdghCG7sdKv5HptBSpVNHFk5oqdpRbQUntFTrfLuAE1qqZymY4YSW6t2kIpzQUp71E0JLeXd2CM2PRgcIzY8W4YQ2gHZ82SA0PxpfNrQGmdCWaHzZUBtkQlujMZQGmdDyaAy5QSa0MhrD6SATWh2N4eMgE1objb0DP7p2C7K0A20AAAAASUVORK5CYII=',
      repeat: 'repeat'
    }
  }
]

export const pieChartJobs = [
  { value: 420, name: 'HH.ru', color: '#013385' },
  { value: 369, name: 'Avito', color: '#02B7F8' },
  { value: 400, name: 'SuperJob', color: '#005EF5' },
  { value: 300, name: 'Zarplata.ru', color: '#94EFFF' },
  { value: 340, name: 'Rabota', color: '#A9BFF8' },
  {
    value: 380,
    name: 'Карьерный сайт',
    color: {
      image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAYAAACOqiAdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ5SURBVHgB7drNShxBFMXx8sTJLEIEAyNkfP8nyC7PkMeICShEMLgYP1rnVEFfe7q+bq3PH8RZXAb6R4N1C89+/Pz19u/+IbCr3WW4+PollHr4/xhu7+7j52+XF/Gn1OHwHG7+3oVpmuJ38rtLvby8ht9/buPv7edNuN5fBeCsObs5Pw/7/e74+1N2dprews1x9vD03JxlfDY+I4Bw/X0XtttNcRZCS3nQaAZ+EJoPLcIJzY/GIDQ/Gp8NpUGhWadofMYsnNCsHBpbwQnNKqGxBZzQrBoam+GEZrXQWIQTmtWDxiA0qxeNQWgpD1r8Tn4Qmg+N3w2h+dEYhDZ2O1RcuYRm5f6AZuGEZpVOHSs4oVm1o9oCTmhW63w7wwnN6lkKIpzQrN5NCkKzPOsnhJby7uwQ2tjtEIQ2djsEofnRqiuX0KxTtCKc0KwcGlvBCc0qobEFnNCsGhqb4YRmtdBYhBOa1YPGIDSrF41BaCkPGg0gND8aLSA0P1p844TmR+PLBqH50eLL1hoUWv7UUV25hFY+qhVXLqHVz7fZlUto7aVgtXIJrW+TgtAsz/oJoaW8OzuENnY7BKGN3Q5BaGO3QxDa2O1QduUSWn72o8UKTmj52VOLBZzQ8rM5ixlOaPnZkkWEE1p+tmYBoeVnWxYQ2nq2ZymA0MZuhyC0sdshCG3sdghCG7sdKv5HptBSpVNHFk5oqdpRbQUntFTrfLuAE1qqZymY4YSW6t2kIpzQUp71E0JLeXd2CM2PRgcIzY8W4YQ2gHZ82SA0PxpfNrQGmdCWaHzZUBtkQlujMZQGmdDyaAy5QSa0MhrD6SATWh2N4eMgE1objb0DP7p2C7K0A20AAAAASUVORK5CYII=',
      repeat: 'repeat'
    }
  }
]

export const pieChartResponses = [
  { value: 1100, name: 'HH.ru', color: '#013385' },
  { value: 430, name: 'Avito', color: '#02B7F8' },
  { value: 620, name: 'SuperJob', color: '#005EF5' },
  { value: 110, name: 'Zarplata.ru', color: '#94EFFF' },
  { value: 90, name: 'Rabota', color: '#A9BFF8' },
  {
    value: 70,
    name: 'Карьерный сайт',
    color: {
      image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAYAAACOqiAdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ5SURBVHgB7drNShxBFMXx8sTJLEIEAyNkfP8nyC7PkMeICShEMLgYP1rnVEFfe7q+bq3PH8RZXAb6R4N1C89+/Pz19u/+IbCr3WW4+PollHr4/xhu7+7j52+XF/Gn1OHwHG7+3oVpmuJ38rtLvby8ht9/buPv7edNuN5fBeCsObs5Pw/7/e74+1N2dprews1x9vD03JxlfDY+I4Bw/X0XtttNcRZCS3nQaAZ+EJoPLcIJzY/GIDQ/Gp8NpUGhWadofMYsnNCsHBpbwQnNKqGxBZzQrBoam+GEZrXQWIQTmtWDxiA0qxeNQWgpD1r8Tn4Qmg+N3w2h+dEYhDZ2O1RcuYRm5f6AZuGEZpVOHSs4oVm1o9oCTmhW63w7wwnN6lkKIpzQrN5NCkKzPOsnhJby7uwQ2tjtEIQ2djsEofnRqiuX0KxTtCKc0KwcGlvBCc0qobEFnNCsGhqb4YRmtdBYhBOa1YPGIDSrF41BaCkPGg0gND8aLSA0P1p844TmR+PLBqH50eLL1hoUWv7UUV25hFY+qhVXLqHVz7fZlUto7aVgtXIJrW+TgtAsz/oJoaW8OzuENnY7BKGN3Q5BaGO3QxDa2O1QduUSWn72o8UKTmj52VOLBZzQ8rM5ixlOaPnZkkWEE1p+tmYBoeVnWxYQ2nq2ZymA0MZuhyC0sdshCG3sdghCG7sdKv5HptBSpVNHFk5oqdpRbQUntFTrfLuAE1qqZymY4YSW6t2kIpzQUp71E0JLeXd2CM2PRgcIzY8W4YQ2gHZ82SA0PxpfNrQGmdCWaHzZUBtkQlujMZQGmdDyaAy5QSa0MhrD6SATWh2N4eMgE1objb0DP7p2C7K0A20AAAAASUVORK5CYII=',
      repeat: 'repeat'
    }
  }
]

export const barLineChartData = {
  lineName: 'Среднее количество откликов на 1 вакансию',
  lineColor: '#01B7F9',
  line: [30, 40, 20, 10, 8, 2],
  barName: 'Среднее количество откликов в день',
  barColor: '#005EF5',
  bar: [
    { label: 'HH.ru', value: 62 },
    { label: 'Avito', value: 42 },
    { label: 'SuperJob', value: 26 },
    { label: 'Zarplata.ru', value: 14 },
    { label: 'Rabota', value: 10 },
    { label: 'Карьерный сайт', value: 5 }
  ]
}
