export const ListSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 64 64"
  >
    <g filter="url(#filter0_ii_115_10652)">
      <path
        fill="url(#paint0_linear_115_10652)"
        d="M7.784 17.73c0-2.418 0-3.627.395-4.58a5.19 5.19 0 012.808-2.81c.954-.394 2.163-.394 4.58-.394h32.865c2.418 0 3.627 0 4.58.395a5.189 5.189 0 012.81 2.808c.394.954.394 2.163.394 4.58 0 2.418 0 3.627-.395 4.581a5.189 5.189 0 01-2.808 2.808c-.954.395-2.163.395-4.58.395H15.567c-2.418 0-3.627 0-4.58-.395a5.19 5.19 0 01-2.81-2.808c-.394-.954-.394-2.163-.394-4.58z"
      ></path>
    </g>
    <g filter="url(#filter1_ii_115_10652)">
      <path
        fill="url(#paint1_linear_115_10652)"
        fillRule="evenodd"
        d="M48.432 10.119H15.569c-1.212 0-2.111 0-2.828.049-.715.049-1.234.145-1.687.333a5.016 5.016 0 00-2.714 2.714c-.188.453-.284.972-.333 1.687-.05.716-.05 1.616-.05 2.828 0 1.21 0 2.11.05 2.827.049.715.145 1.235.333 1.687a5.017 5.017 0 002.714 2.715c.453.187.972.284 1.687.332.716.05 1.616.05 2.828.05h32.864c1.212 0 2.112 0 2.828-.05.715-.048 1.235-.145 1.687-.332a5.017 5.017 0 002.714-2.715c.188-.452.285-.972.333-1.687.05-.716.05-1.616.05-2.827 0-1.212 0-2.112-.05-2.828-.048-.715-.145-1.234-.333-1.687a5.016 5.016 0 00-2.714-2.714c-.453-.188-.972-.284-1.687-.333-.716-.05-1.616-.05-2.828-.05zM8.18 13.149c-.395.954-.395 2.163-.395 4.58 0 2.418 0 3.627.395 4.581a5.19 5.19 0 002.808 2.808c.954.395 2.163.395 4.58.395h32.865c2.418 0 3.627 0 4.58-.395a5.189 5.189 0 002.81-2.808c.394-.954.394-2.163.394-4.58 0-2.418 0-3.627-.395-4.58a5.189 5.189 0 00-2.808-2.81c-.954-.394-2.163-.394-4.58-.394H15.567c-2.418 0-3.627 0-4.58.395a5.19 5.19 0 00-2.81 2.808z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter2_d_115_10652)">
      <path
        fill="#E0E0E0"
        d="M11.243 14.703a2.162 2.162 0 014.324 0 2.162 2.162 0 01-4.324 0z"
      ></path>
    </g>
    <g filter="url(#filter3_d_115_10652)">
      <path
        fill="url(#paint2_linear_115_10652)"
        fillRule="evenodd"
        d="M11.416 14.703a1.99 1.99 0 103.979 0 1.99 1.99 0 00-3.979 0zm1.99-2.162a2.162 2.162 0 100 4.324 2.162 2.162 0 000-4.324z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter4_d_115_10652)">
      <path
        fill="#E0E0E0"
        d="M17.297 14.703a2.162 2.162 0 014.324 0 2.162 2.162 0 01-4.324 0z"
      ></path>
    </g>
    <g filter="url(#filter5_d_115_10652)">
      <path
        fill="url(#paint3_linear_115_10652)"
        fillRule="evenodd"
        d="M17.47 14.703a1.99 1.99 0 103.979 0 1.99 1.99 0 00-3.979 0zm1.99-2.162a2.162 2.162 0 100 4.324 2.162 2.162 0 000-4.324z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter6_d_115_10652)">
      <path
        fill="#E0E0E0"
        d="M23.351 14.703a2.162 2.162 0 014.324 0 2.162 2.162 0 11-4.324 0z"
      ></path>
    </g>
    <g filter="url(#filter7_d_115_10652)">
      <path
        fill="url(#paint4_linear_115_10652)"
        fillRule="evenodd"
        d="M23.524 14.703a1.99 1.99 0 103.979 0 1.99 1.99 0 00-3.979 0zm1.99-2.162a2.162 2.162 0 100 4.324 2.162 2.162 0 000-4.324z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter8_bii_115_10652)">
      <path
        fill="#005EF5"
        fillOpacity="0.2"
        d="M7.784 28.973c0-4.892 0-7.338 1.52-8.858 1.52-1.52 3.966-1.52 8.858-1.52h27.676c4.892 0 7.338 0 8.858 1.52 1.52 1.52 1.52 3.966 1.52 8.858V42.38c0 4.892 0 7.338-1.52 8.858-1.52 1.52-3.966 1.52-8.858 1.52H18.162c-4.892 0-7.338 0-8.858-1.52-1.52-1.52-1.52-3.966-1.52-8.858V28.973z"
      ></path>
    </g>
    <g filter="url(#filter9_bii_115_10652)">
      <path
        fill="#005EF5"
        fillOpacity="0.2"
        fillRule="evenodd"
        d="M45.838 18.768H18.162c-2.45 0-4.272 0-5.67.188-1.395.188-2.344.559-3.066 1.28-.722.723-1.093 1.672-1.28 3.066-.189 1.4-.19 3.22-.19 5.671V42.38c0 2.45.001 4.272.19 5.67.187 1.394.558 2.343 1.28 3.066.722.722 1.671 1.093 3.065 1.28 1.4.188 3.22.189 5.671.189h27.676c2.451 0 4.272 0 5.67-.189 1.395-.187 2.344-.558 3.066-1.28.723-.723 1.093-1.672 1.28-3.066.189-1.398.19-3.22.19-5.67V28.973c0-2.451-.001-4.272-.19-5.67-.187-1.395-.557-2.344-1.28-3.066-.722-.722-1.671-1.093-3.065-1.28-1.4-.189-3.22-.19-5.671-.19zM9.304 20.115c-1.52 1.52-1.52 3.966-1.52 8.858V42.38c0 4.892 0 7.338 1.52 8.858 1.52 1.52 3.966 1.52 8.858 1.52h27.676c4.892 0 7.338 0 8.858-1.52 1.52-1.52 1.52-3.966 1.52-8.858V28.973c0-4.892 0-7.338-1.52-8.858-1.52-1.52-3.966-1.52-8.858-1.52H18.162c-4.892 0-7.338 0-8.858 1.52z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter10_d_115_10652)">
      <path
        fill="#fff"
        d="M21.838 28.54a2.378 2.378 0 110-4.757h26.81a2.378 2.378 0 010 4.757h-26.81z"
      ></path>
    </g>
    <g filter="url(#filter11_d_115_10652)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M48.649 23.957H21.838a2.205 2.205 0 100 4.41h26.81a2.205 2.205 0 000-4.41zm-29.19 2.205a2.378 2.378 0 002.379 2.379h26.81a2.378 2.378 0 000-4.757h-26.81a2.378 2.378 0 00-2.379 2.378z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter12_d_115_10652)">
      <path
        fill="#fff"
        d="M15.351 28.54a2.378 2.378 0 110-4.757 2.378 2.378 0 110 4.757z"
      ></path>
    </g>
    <g filter="url(#filter13_d_115_10652)">
      <path
        fill="url(#paint5_linear_115_10652)"
        fillRule="evenodd"
        d="M15.351 28.368a2.205 2.205 0 100-4.411 2.205 2.205 0 000 4.41zm-2.378-2.206a2.378 2.378 0 104.757 0 2.378 2.378 0 00-4.757 0z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter14_d_115_10652)">
      <path
        fill="#fff"
        d="M21.838 38.054a2.378 2.378 0 010-4.757h26.81a2.378 2.378 0 010 4.757h-26.81z"
      ></path>
    </g>
    <g filter="url(#filter15_d_115_10652)">
      <path
        fill="url(#paint6_linear_115_10652)"
        fillRule="evenodd"
        d="M48.649 33.47H21.838a2.205 2.205 0 100 4.411h26.81a2.205 2.205 0 000-4.41zm-29.19 2.206a2.378 2.378 0 002.379 2.378h26.81a2.378 2.378 0 000-4.757h-26.81a2.378 2.378 0 00-2.379 2.379z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter16_d_115_10652)">
      <path
        fill="#fff"
        d="M15.351 38.054a2.378 2.378 0 010-4.757 2.378 2.378 0 110 4.757z"
      ></path>
    </g>
    <g filter="url(#filter17_d_115_10652)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.351 37.881a2.205 2.205 0 100-4.41 2.205 2.205 0 000 4.41zm-2.378-2.205a2.378 2.378 0 104.757 0 2.378 2.378 0 00-4.757 0z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter18_d_115_10652)">
      <path
        fill="#fff"
        d="M21.838 47.567a2.378 2.378 0 010-4.757h26.81a2.378 2.378 0 010 4.757h-26.81z"
      ></path>
    </g>
    <g filter="url(#filter19_d_115_10652)">
      <path
        fill="url(#paint7_linear_115_10652)"
        fillRule="evenodd"
        d="M48.649 42.984H21.838a2.205 2.205 0 100 4.41h26.81a2.205 2.205 0 000-4.41zm-29.19 2.205a2.378 2.378 0 002.379 2.378h26.81a2.378 2.378 0 000-4.756h-26.81a2.378 2.378 0 00-2.379 2.378z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter20_d_115_10652)">
      <path
        fill="#fff"
        d="M15.351 47.567a2.378 2.378 0 010-4.757 2.378 2.378 0 010 4.757z"
      ></path>
    </g>
    <g filter="url(#filter21_d_115_10652)">
      <path
        fill="url(#paint8_linear_115_10652)"
        fillRule="evenodd"
        d="M15.351 47.394a2.205 2.205 0 100-4.41 2.205 2.205 0 000 4.41zm-2.378-2.205a2.378 2.378 0 104.757 0 2.378 2.378 0 00-4.757 0z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_ii_115_10652"
        width="52.432"
        height="19.567"
        x="5.784"
        y="7.946"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow_115_10652"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect1_innerShadow_115_10652"
          result="effect2_innerShadow_115_10652"
        ></feBlend>
      </filter>
      <filter
        id="filter1_ii_115_10652"
        width="52.432"
        height="19.567"
        x="5.784"
        y="7.946"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow_115_10652"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect1_innerShadow_115_10652"
          result="effect2_innerShadow_115_10652"
        ></feBlend>
      </filter>
      <filter
        id="filter2_d_115_10652"
        width="8.324"
        height="8.324"
        x="9.243"
        y="11.54"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter3_d_115_10652"
        width="8.324"
        height="8.324"
        x="9.243"
        y="11.54"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter4_d_115_10652"
        width="8.324"
        height="8.324"
        x="15.297"
        y="11.54"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter5_d_115_10652"
        width="8.324"
        height="8.324"
        x="15.297"
        y="11.54"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter6_d_115_10652"
        width="8.324"
        height="8.324"
        x="21.351"
        y="11.54"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter7_d_115_10652"
        width="8.324"
        height="8.324"
        x="21.351"
        y="11.54"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter8_bii_115_10652"
        width="64.432"
        height="50.162"
        x="-0.216"
        y="10.595"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10652"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10652"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10652"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10652"
          result="effect3_innerShadow_115_10652"
        ></feBlend>
      </filter>
      <filter
        id="filter9_bii_115_10652"
        width="64.432"
        height="50.162"
        x="-0.216"
        y="10.595"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10652"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10652"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10652"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10652"
          result="effect3_innerShadow_115_10652"
        ></feBlend>
      </filter>
      <filter
        id="filter10_d_115_10652"
        width="35.568"
        height="8.757"
        x="17.459"
        y="22.784"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter11_d_115_10652"
        width="35.568"
        height="8.757"
        x="17.459"
        y="22.784"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter12_d_115_10652"
        width="8.757"
        height="8.757"
        x="10.973"
        y="22.784"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter13_d_115_10652"
        width="8.757"
        height="8.757"
        x="10.973"
        y="22.784"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter14_d_115_10652"
        width="35.568"
        height="8.757"
        x="17.459"
        y="32.297"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter15_d_115_10652"
        width="35.568"
        height="8.757"
        x="17.459"
        y="32.297"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter16_d_115_10652"
        width="8.757"
        height="8.757"
        x="10.973"
        y="32.297"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter17_d_115_10652"
        width="8.757"
        height="8.757"
        x="10.973"
        y="32.297"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter18_d_115_10652"
        width="35.568"
        height="8.757"
        x="17.459"
        y="41.81"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter19_d_115_10652"
        width="35.568"
        height="8.757"
        x="17.459"
        y="41.81"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter20_d_115_10652"
        width="8.757"
        height="8.757"
        x="10.973"
        y="41.81"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter21_d_115_10652"
        width="8.757"
        height="8.757"
        x="10.973"
        y="41.81"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10652"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10652"
          result="shape"
        ></feBlend>
      </filter>
      <linearGradient
        id="paint0_linear_115_10652"
        x1="9.363"
        x2="51.321"
        y1="11.113"
        y2="34.071"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5"></stop>
        <stop offset="1" stopColor="#3787FF"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_115_10652"
        x1="-2.152"
        x2="10.451"
        y1="-46.946"
        y2="21.954"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_115_10652"
        x1="13.405"
        x2="13.405"
        y1="12.54"
        y2="15.904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_115_10652"
        x1="19.459"
        x2="19.459"
        y1="12.54"
        y2="15.904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear_115_10652"
        x1="25.514"
        x2="25.514"
        y1="12.54"
        y2="15.904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint5_linear_115_10652"
        x1="12.973"
        x2="16.673"
        y1="26.162"
        y2="26.162"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint6_linear_115_10652"
        x1="19.459"
        x2="44.012"
        y1="35.676"
        y2="35.676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint7_linear_115_10652"
        x1="19.459"
        x2="44.012"
        y1="45.189"
        y2="45.189"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint8_linear_115_10652"
        x1="12.973"
        x2="16.673"
        y1="45.189"
        y2="45.189"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
  </svg>
)
