import React from 'react'
import { BestPractiesSvg } from '../../../svg/BestPractiesSvg'
import { IternalMarketingSvg } from '../../../svg/IternalMarketingSvg'
import { LeasonsSvg } from '../../../svg/LeansonsSvg'
import { PrioritySvg } from '../../../svg/PrioritySvg'
import { RequirementsSvg } from '../../../svg/RequirementsSvg'
import { VisionBoardSvg } from '../../../svg/VisionBoardSvg'
import './styles.scss'
import { Inner } from '../../layout/Inner/Inner'

export const Details: React.FC = () => {
  const content = [
    {
      svg: () => <PrioritySvg />,
      title: 'Priorities',
      content:
        'Нам также необходимо понимать приоритеты по срокам, стоимости и безопасности (качеству) разработки. Есть ли какие-либо лимиты по этим пунктам.'
    },
    {
      svg: () => <RequirementsSvg />,
      title: 'Requirements',
      content:
        'Скорее всего у вас уже есть ТЗ или RFP. Если нет, то мы поможем вам его разработать. Если есть, то нам его надо внимательно изучить и получить от вас комментарии.'
    },
    {
      svg: () => <LeasonsSvg />,
      title: 'Lessons Learned',
      content:
        'Есть ли у вас негативный опыт реализации подобного рода проектов? С чем он связан? Какие уроки?'
    },
    {
      svg: () => <BestPractiesSvg />,
      title: 'Best Practices',
      content:
        'Нужен ли вам анализ рынка ПО под разработанное вами ТЗ. Если он у вас есть, мы бы предложили сравнить его с нашим для верификации'
    },
    {
      svg: () => <VisionBoardSvg />,
      title: 'Vision board',
      content:
        'Какие критерии эффективности проекта ставит перед вами руководство: получить новую функциональность, снизить затраты, получить довольного клиента (высокий NPS) для HR сервисов, повысить вовлеченность персонала (EX индекс)'
    },
    {
      svg: () => <IternalMarketingSvg />,
      title: 'Internal Marketing',
      content:
        'Надо ли “продавать” проект внутри организации вашим потенциальным спонсорам. Что вам для этого нужно: ТЭО или презентация?'
    }
  ]
  return (
    <section className="details_container">
      <Inner>
        <div>
          <div>
            <div className="details_title">
              Каких деталей обычно не хватает чтобы сделать выгодное предложение
            </div>
          </div>
        </div>
        <div>
          <div className="details_render_container">
            {content.map((item, index) => {
              return (
                <div key={index} className="details_render_item">
                  <div className="details_render_item_header">
                    {item.svg?.()} {item.title}
                  </div>
                  <div className="details_render_item_content">
                    {item.content}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Inner>
    </section>
  )
}
