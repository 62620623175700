import React, { createContext, useState } from 'react'
import useModalOverflow from '../../hooks/useModalOverflow'

interface LayoutContextType {
  popupIsOpen: boolean
  setPopupIsOpen: (isOpen: boolean) => void
  headerMenuIsOpen: boolean
  setHeaderMenuIsOpen: (isOpen: boolean) => void
}

export const LayoutContext = createContext<LayoutContextType | null>(null)

interface LayoutProviderProps {
  children?: React.ReactNode
}
export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [popupIsOpen, setPopupIsOpen] = useState(false)
  const [headerMenuIsOpen, setHeaderMenuIsOpen] = useState(false)

  useModalOverflow(popupIsOpen || headerMenuIsOpen)

  return (
    <LayoutContext.Provider
      value={{
        popupIsOpen,
        setPopupIsOpen,
        headerMenuIsOpen,
        setHeaderMenuIsOpen
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
