import React, { useState, useRef, useContext } from 'react'
import useOutsideClick from '../../../hooks/useOutsideClick'
import classNames from 'classnames'
import { BurgerSvg } from '../../../svg/BurgerSvg'
import { DorsSvg } from '../../../svg/DorsSvg'
import { PhoneSvg } from '../../../svg/PhoneSvg'
import { useWindow } from '../../../hooks/useWindow'
import { CrossSvg } from '../../../svg/CrossSvg'
import { Link, NavLink } from 'react-router-dom'
import { Inner } from '../../layout/Inner/Inner'
import { EmailSvg } from '../../../svg/EmailSvg'
import { VkSvg } from '../../../svg/VkSvg'
import { YouTubeSvg } from '../../../svg/YouTubeSvg'
import { WhatsAppSvg } from '../../../svg/WhatsAppSvg'
import { LinkedInSvg } from '../../../svg/LinkedInSvg'
import { Btn } from '../../ui/Btn/Btn'
import { LayoutContext } from '../../../contexts/LayoutContext/LayoutContext'
import './styles.scss'
import { Popup } from '../Popup/Popup'
import { Form } from '../Form/Form'

export const Header: React.FC = () => {
  const windowWidth = useWindow()

  const headerContactRef = useRef<HTMLDivElement>(null)
  const [headerPopupIsOpen, setHeaderPopupIsOpen] = useState(false)
  const handleOutsideClick = () => {
    setHeaderPopupIsOpen(false)
  }
  useOutsideClick(headerContactRef, handleOutsideClick)

  const LayoutState = useContext(LayoutContext)

  return (
    <>
      <header className="header">
        <Inner mod="header">
          <Link to={'/'} className="header__logo">
            <DorsSvg />
          </Link>
          { windowWidth <= 1024 &&
            <button className="header__burger-button" onClick={() => LayoutState?.setHeaderMenuIsOpen(!LayoutState?.headerMenuIsOpen)}>
              <div className={classNames('header__burger-icon', { active: LayoutState?.headerMenuIsOpen })}>
                <CrossSvg />
              </div>
              <div className={classNames('header__burger-icon', { active: !LayoutState?.headerMenuIsOpen })}>
                <BurgerSvg />
              </div>
            </button>
          }
          <div className={classNames('header__burger', { active: LayoutState?.headerMenuIsOpen })}>
            { windowWidth <= 1024 &&
              <Btn
                mod="header"
                onClick={() => {
                  setHeaderPopupIsOpen(false)
                  LayoutState?.setPopupIsOpen(true)
                }}
              >Заказать звонок</Btn>
            }
            <ul className="header__menu">
              { windowWidth <= 1024 &&
                <li className="header__item">
                  <NavLink className="header__link" onClick={() => LayoutState?.setHeaderMenuIsOpen(false)} to={'/'}>Главная</NavLink>
                </li>
              }
              {menu.map((item, index) => <li key={index} className="header__item">
                <NavLink key={index} onClick={() => LayoutState?.setHeaderMenuIsOpen(false)} className="header__link" to={item.link}>{item.text}</NavLink>
              </li>)}
            </ul>
            <div className="header__contact" ref={headerContactRef}>
              { windowWidth > 1024 &&
                <Btn mod='--xs' onClick={() => setHeaderPopupIsOpen(!headerPopupIsOpen)}>Связаться с нами</Btn>
              }
              <div className={classNames('header__popup', { active: headerPopupIsOpen })}>
                <a href="mailto:info@axes.pro" className="header__contact-link" onClick={() => setHeaderPopupIsOpen(false)}>
                  <EmailSvg />
                  info@axes.pro
                </a>
                <a href="tel:+74953080470" className="header__contact-link" onClick={() => setHeaderPopupIsOpen(false)}>
                  <PhoneSvg />
                  +7 (495) 308-04-70
                </a>
                <div className="header__contact-descr">по всему миру, оплата в соответствии с тарифами вашего оператора связи</div>
                <div className="header__socials">
                  {socials.map((item, index) => <a key={index} className="header__social" href={item.link} target="_blank" rel="noreferrer">{item.svg}</a>)}
                </div>
                { windowWidth > 1024 &&
                  <Btn
                  mod="header, --xs"
                  onClick={() => {
                    setHeaderPopupIsOpen(false)
                    LayoutState?.setPopupIsOpen(true)
                  }}
                  >Заказать звонок</Btn>
                }
              </div>
            </div>
          </div>
        </Inner>
      </header>
      { LayoutState?.popupIsOpen &&
        <Popup>
          <Form />
        </Popup>
      }
    </>
  )
}

const menu = [
  // { text: 'Платформа', link: '#' },
  { text: 'Решения', link: '/solutions/multiposting' }
  // { text: 'HR новости', link: '#' }
]

const socials = [
  { svg: <VkSvg />, link: 'https://vk.com/axespro' },
  { svg: <YouTubeSvg />, link: 'https://www.youtube.com/user/axesprodigital' },
  { svg: <LinkedInSvg />, link: 'https://www.linkedin.com/company/axes-pro' },
  { svg: <WhatsAppSvg />, link: 'https://wa.me/+79260953732' }
]
