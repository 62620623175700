export const GeneralProvisions = () => (
  <div>
    <div className="privacy_content_title">Общие положения</div>
    <div className="privacy_content">
      <div>
        Настоящая Политика конфиденциальности составлена в соответствии с
        требованиями Федерального закона от 27.07.2006 №152-ФЗ «О персональных
        данных» и призвана определить способы, цели, объемы обращения с
        информацией, и установить меры по обеспечению безопасности персональных
        данных (далее – Политика), получаемых компанией ООО «АКСИС ПРО» (далее –
        Оператор) при посещении третьими лицами Веб-Сайта www.dors.online (далее
        Сайт www.dors.online).
      </div>
      <div>
        Оператор с уважением относится к соблюдению прав и свобод человека и
        гражданина при обработке его персональных данных, в том числе защиты
        прав на неприкосновенность частной жизни, личную и семейную тайну.
      </div>
      <div>
        Оператор безоговорочно признает важность конфиденциальности личной
        информации третьих лиц, посещающих Сайт{' '}
        <a href="https://www.dors.online">www.dors.online</a>.
      </div>
      <div>
        Политика содержит сведения о том, какую информацию мы получаем,
        собираем, обрабатываем, храним, когда Вы пользуетесь Сайтом{' '}
        <a href="https://www.dors.online">www.dors.online</a>.
      </div>
      <div>
        Мы надеемся, что эти сведения помогут вам принимать осознанные решения в
        отношении предоставляемой нам персональной информации.
      </div>
      <div>
        Настоящая Политика конфиденциальности распространяется только на Сайт{' '}
        <a href="https://www.dors.online">www.dors.online</a> и на всю
        информацию, собираемую этим Сайтом и через его посредство о персональных
        данных посетителей. Политика не распространяется ни на какие другие
        Сайты и не применима к веб-Сайтам третьих лиц, с которых могут делаться
        ссылки на Сайт <a href="https://www.dors.online">www.dors.online</a>.
      </div>
    </div>
  </div>
)
