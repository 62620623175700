export const InfoTabletSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
  >
    <g clipPath="url(#a)">
      <g filter="url(#b)">
        <path
          fill="url(#c)"
          d="M10.378 21.19c0-4.893 0-7.34 1.52-8.86 1.52-1.519 3.966-1.519 8.859-1.519h22.486c4.893 0 7.339 0 8.859 1.52 1.52 1.52 1.52 3.966 1.52 8.858v21.19c0 4.892 0 7.338-1.52 8.858-1.52 1.52-3.966 1.52-8.859 1.52H20.757c-4.893 0-7.339 0-8.859-1.52-1.52-1.52-1.52-3.966-1.52-8.858v-21.19Z"
        />
      </g>
      <g filter="url(#d)">
        <path
          fill="url(#e)"
          fillRule="evenodd"
          d="M43.243 10.984H20.757c-2.451 0-4.272 0-5.671.188-1.394.188-2.343.559-3.066 1.281-.722.723-1.093 1.672-1.28 3.066-.188 1.398-.189 3.22-.189 5.67v21.19c0 2.45 0 4.272.189 5.67.187 1.394.558 2.343 1.28 3.066.723.722 1.672 1.093 3.066 1.28 1.399.189 3.22.189 5.67.189h22.487c2.451 0 4.272 0 5.671-.188 1.394-.188 2.343-.559 3.065-1.281.723-.723 1.094-1.672 1.281-3.066.188-1.398.189-3.22.189-5.67v-21.19c0-2.45 0-4.272-.189-5.67-.187-1.394-.558-2.343-1.28-3.066-.723-.722-1.672-1.093-3.066-1.28-1.399-.189-3.22-.189-5.67-.189Zm-31.345 1.347c-1.52 1.52-1.52 3.966-1.52 8.858v21.19c0 4.892 0 7.338 1.52 8.858 1.52 1.52 3.966 1.52 8.859 1.52h22.486c4.893 0 7.339 0 8.859-1.52 1.52-1.52 1.52-3.966 1.52-8.858v-21.19c0-4.892 0-7.338-1.52-8.858-1.52-1.52-3.966-1.52-8.859-1.52H20.757c-4.893 0-7.339 0-8.859 1.52Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#f)">
        <path
          fill="#005EF5"
          fillOpacity={0.2}
          d="M10.171 21.544c.598-4.856.896-7.284 2.59-8.607 1.694-1.323 4.122-1.024 8.978-.427l22.318 2.745c4.856.597 7.284.896 8.607 2.59 1.323 1.694 1.024 4.122.427 8.977L50.241 50c-.597 4.856-.896 7.284-2.59 8.607-1.694 1.323-4.122 1.024-8.978.427l-22.318-2.745c-4.856-.597-7.284-.895-8.607-2.59-1.323-1.694-1.024-4.121-.427-8.977l2.85-23.177Z"
        />
      </g>
      <g filter="url(#g)">
        <path
          fill="url(#h)"
          fillRule="evenodd"
          d="m44.036 15.427-22.318-2.745c-2.433-.3-4.24-.521-5.652-.505-1.406.015-2.393.268-3.198.896-.806.63-1.29 1.526-1.646 2.887-.357 1.365-.58 3.172-.88 5.605l-2.85 23.177c-.299 2.433-.52 4.24-.505 5.651.016 1.407.269 2.394.897 3.199.63.805 1.526 1.29 2.886 1.645 1.366.358 3.173.58 5.606.88l22.318 2.744c2.433.3 4.24.522 5.652.506 1.406-.016 2.393-.269 3.198-.897.806-.63 1.29-1.526 1.646-2.886.357-1.366.58-3.173.88-5.606l2.85-23.177c.299-2.432.52-4.24.505-5.651-.016-1.406-.268-2.394-.897-3.199-.63-.805-1.526-1.29-2.886-1.645-1.366-.358-3.173-.58-5.606-.88Zm-31.275-2.49c-1.694 1.323-1.992 3.751-2.59 8.607L7.321 44.72c-.597 4.856-.896 7.283.427 8.977s3.751 1.993 8.607 2.59l22.318 2.745c4.856.597 7.284.896 8.978-.427s1.992-3.751 2.59-8.607l2.85-23.177c.597-4.855.896-7.283-.427-8.977s-3.751-1.993-8.607-2.59L21.74 12.51c-4.856-.597-7.284-.896-8.978.427Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#i)">
        <path
          fill="#005EF5"
          fillOpacity={0.2}
          d="M11.122 26.425c-.35-4.88-.526-7.32.881-8.945s3.847-1.8 8.727-2.15l22.429-1.613c4.88-.35 7.32-.526 8.944.88 1.625 1.408 1.8 3.848 2.151 8.728l1.675 23.29c.35 4.88.526 7.32-.881 8.946-1.407 1.624-3.847 1.8-8.727 2.15l-22.429 1.613c-4.88.35-7.32.526-8.944-.881-1.625-1.407-1.8-3.847-2.151-8.727l-1.675-23.291Z"
        />
      </g>
      <g filter="url(#j)">
        <path
          fill="url(#k)"
          fillRule="evenodd"
          d="m43.171 13.89-22.429 1.612c-2.444.175-4.26.306-5.642.594-1.377.287-2.297.725-2.966 1.497-.669.773-.97 1.746-1.058 3.15-.087 1.409.043 3.225.219 5.67l1.674 23.29c.176 2.446.307 4.262.595 5.644.287 1.377.725 2.296 1.497 2.965.772.669 1.745.97 3.15 1.058 1.408.087 3.224-.043 5.669-.219l22.429-1.612c2.444-.176 4.26-.306 5.642-.594 1.377-.287 2.297-.725 2.966-1.498.669-.772.97-1.745 1.058-3.149.087-1.409-.043-3.225-.219-5.67l-1.674-23.291c-.176-2.445-.307-4.261-.595-5.643-.287-1.377-.724-2.297-1.497-2.965-.772-.67-1.745-.971-3.15-1.058-1.408-.087-3.224.043-5.669.219Zm-31.168 3.59c-1.407 1.625-1.231 4.065-.88 8.945l1.674 23.291c.35 4.88.526 7.32 2.15 8.727 1.626 1.407 4.066 1.232 8.945.88l22.429-1.611c4.88-.351 7.32-.527 8.727-2.151 1.407-1.625 1.231-4.065.88-8.945l-1.674-23.291c-.35-4.88-.526-7.32-2.15-8.727-1.626-1.407-4.066-1.232-8.945-.881L20.73 15.329c-4.88.35-7.32.526-8.727 2.151Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#l)">
        <path
          fill="#E0E0E0"
          fillRule="evenodd"
          d="M22.778 9.454c-.843-.722-.332-2.103.777-2.103h1.67a.72.72 0 0 0 .721-.72A5.766 5.766 0 0 1 31.712.865h.576a5.814 5.814 0 0 1 5.814 5.766c.003.399.328.72.727.72h1.627c1.075 0 1.593 1.316.808 2.05l-2.687 2.507c-.574.536-.195 1.497.59 1.497h2.995a1.513 1.513 0 1 1 0 3.027H21.405a1.513 1.513 0 1 1 0-3.027h3.644c.803 0 1.173-.999.563-1.521l-2.834-2.43Zm9.174-6.427a3.844 3.844 0 0 0-3.844 3.844c0 .265.215.48.48.48h6.823a.48.48 0 0 0 .48-.48 3.844 3.844 0 0 0-3.843-3.844h-.096Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#m)">
        <path
          fill="url(#n)"
          fillRule="evenodd"
          d="M42.162 16.26a1.34 1.34 0 0 0 0-2.682h-2.995c-.942 0-1.397-1.154-.708-1.796l2.687-2.508c.67-.626.228-1.75-.69-1.75H38.83a.9.9 0 0 1-.9-.892 5.641 5.641 0 0 0-5.64-5.594h-.577a5.593 5.593 0 0 0-5.593 5.593c0 .493-.4.893-.894.893h-1.67c-.949 0-1.386 1.181-.665 1.799l2.835 2.43c.731.627.288 1.825-.676 1.825h-3.644a1.34 1.34 0 0 0 0 2.681h20.757Zm-20.757.172a1.513 1.513 0 1 1 0-3.027h3.644c.803 0 1.173-.999.563-1.521l-2.834-2.43c-.843-.722-.332-2.103.777-2.103h1.67a.72.72 0 0 0 .721-.72A5.766 5.766 0 0 1 31.712.865h.576a5.814 5.814 0 0 1 5.814 5.766c.003.399.328.72.727.72h1.627c1.075 0 1.593 1.316.808 2.05l-2.687 2.507c-.574.536-.195 1.497.59 1.497h2.995a1.513 1.513 0 1 1 0 3.027H21.405Zm6.53-9.561a4.017 4.017 0 0 1 4.017-4.017h.096a4.017 4.017 0 0 1 4.017 4.017c0 .36-.293.653-.654.653H28.59a.653.653 0 0 1-.654-.653Zm.173 0a3.844 3.844 0 0 1 3.844-3.844h.096a3.844 3.844 0 0 1 3.844 3.844.48.48 0 0 1-.48.48h-6.823a.48.48 0 0 1-.48-.48Z"
          clipRule="evenodd"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        fill="#fff"
        d="M31.774 45.838c-.926 0-1.677-.751-1.677-1.678V33.285a1.677 1.677 0 1 1 3.355 0V44.16c0 .926-.751 1.677-1.677 1.677Zm.01-16.251c-.531 0-.989-.176-1.372-.528-.383-.358-.574-.788-.574-1.288 0-.506.191-.936.574-1.288.383-.358.84-.537 1.372-.537.537 0 .994.179 1.371.537.383.352.575.782.575 1.288 0 .5-.192.93-.575 1.288a1.936 1.936 0 0 1-1.371.528Z"
      />
    </g>
    <defs>
      <filter
        id="b"
        width={47.243}
        height={45.946}
        x={8.378}
        y={8.811}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-2} dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend in2="shape" result="effect1_innerShadow_115_10633" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={-2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend
          in2="effect1_innerShadow_115_10633"
          result="effect2_innerShadow_115_10633"
        />
      </filter>
      <filter
        id="d"
        width={47.243}
        height={45.946}
        x={8.378}
        y={8.811}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-2} dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend in2="shape" result="effect1_innerShadow_115_10633" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={-2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend
          in2="effect1_innerShadow_115_10633"
          result="effect2_innerShadow_115_10633"
        />
      </filter>
      <filter
        id="f"
        width={62.785}
        height={63.538}
        x={-1.186}
        y={4.003}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10633"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10633"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-2} dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend in2="shape" result="effect2_innerShadow_115_10633" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={-2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend
          in2="effect2_innerShadow_115_10633"
          result="effect3_innerShadow_115_10633"
        />
      </filter>
      <filter
        id="g"
        width={62.785}
        height={63.538}
        x={-1.186}
        y={4.003}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10633"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10633"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-2} dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend in2="shape" result="effect2_innerShadow_115_10633" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={-2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend
          in2="effect2_innerShadow_115_10633"
          result="effect3_innerShadow_115_10633"
        />
      </filter>
      <filter
        id="i"
        width={61.3}
        height={62.101}
        x={2.876}
        y={5.47}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10633"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10633"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-2} dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend in2="shape" result="effect2_innerShadow_115_10633" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={-2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend
          in2="effect2_innerShadow_115_10633"
          result="effect3_innerShadow_115_10633"
        />
      </filter>
      <filter
        id="j"
        width={61.3}
        height={62.101}
        x={2.876}
        y={5.47}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10633"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10633"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-2} dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend in2="shape" result="effect2_innerShadow_115_10633" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={-2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
        <feBlend
          in2="effect2_innerShadow_115_10633"
          result="effect3_innerShadow_115_10633"
        />
      </filter>
      <filter
        id="l"
        width={27.784}
        height={19.567}
        x={17.892}
        y={-0.135}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10633"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10633"
          result="shape"
        />
      </filter>
      <filter
        id="m"
        width={27.784}
        height={19.567}
        x={17.892}
        y={-0.135}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10633"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10633"
          result="shape"
        />
      </filter>
      <linearGradient
        id="c"
        x1={11.789}
        x2={58.917}
        y1={13.957}
        y2={22.502}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset={1} stopColor="#3787FF" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={1.507}
        x2={82.68}
        y1={-142.48}
        y2={4.572}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="h"
        x1={7.262}
        x2={29.637}
        y1={6.69}
        y2={69.367}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.766} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="k"
        x1={5.403}
        x2={39.447}
        y1={12.411}
        y2={69.595}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.766} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="n"
        x1={19.892}
        x2={38.39}
        y1={14.579}
        y2={14.579}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
)
