import React from 'react'
import { Risks } from '../../../components/common/Risks/Risks'
import { Banner } from '../../../components/common/Banner/Banner'
import { Possibilities } from '../../../components/common/Possibilities/Possibilities'
import { ColleaguesSearchSvg } from '../../../svg/ColleaguesSearchSvg'
import { RequirementsSvg } from '../../../svg/RequirementsSvg'
import { TagSvg } from '../../../svg/TagSvg'
import { CandidatesSvg } from '../../../svg/CandidatesSvg'
import { PuzzleSvg } from '../../../svg/PuzzleSvg'
import { ConnectionSteps } from '../../../components/common/ConnectionSteps/ConnectionSteps'
import { Intergations } from '../../../components/common/Intergations/Intergations'
import { DataAnalytics } from '../../../components/common/DataAnalytics/DataAnalytics'
import { PromoBanner } from '../../../components/common/PromoBanner/PromoBanner'
import { Main } from '../../../components/layout/Main/Main'

export const MultipostingPage: React.FC = () => {
  return (
    <Main>
      <Banner />
      <Possibilities />
      <Risks
        mod="--svg"
        title='Вам подойдет сервис, если ваша компания:'
        list={RiskList}
      />
      <ConnectionSteps />
      <Intergations />
      <DataAnalytics />
      {/* <InterestingLinks /> */}
      <PromoBanner />
    </Main>
  )
}

const RiskList = [
  {
    svg: <ColleaguesSearchSvg />,
    title: 'Вы всегда в активном поиске сотрудников',
    content:
      'Ваша компания занимается массовым подбором сотрудников на однотипные позиции и этот процесс никогда не прекращается '
  },
  {
    svg: <RequirementsSvg/>,
    title: 'Корректирует требования в ходе подбора',
    content:
      'Вы периодически вносите правки и дополнения в опубликованные вакансии, но ваша ATS не позволяет оперативно добавлять/изменять поля на форме публикации'
  },
  {
    svg: <TagSvg/>,
    title: 'Хочет размещать вакансии только под своим брендом',
    content:
      'Вы попробовали различные сервисы для публикации вакансий, но они не  позволяли делать публикации с корпоративным брендингом'
  },
  {
    svg: <CandidatesSvg/>,
    title: 'Не видит полную воронку кандидатов',
    content:
      'Обрабатывая отклики по каждому сайту по поиску работы отдельно, рекрутеры не всегда переносят всех кандидатов в ATS , зачастую пропуская релевантных соискателей'
  },
  {
    svg: <PuzzleSvg/>,
    title: 'Желает подключать новые источники размещения вакансий',
    content:
      'Ваша CRM/ATS имеет интеграцию, не со всеми сайтами, на которых вы публикуетесь или интеграции совсем отсутствуют и вы не рассматриваете новые источники из-за трудоемкости размещения на них'
  }
]
