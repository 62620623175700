export const RightWhyAreWeSvg = () => (
  <svg
    width="232"
    height="325"
    viewBox="0 0 232 325"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.3333 0H0V75H64.3333C114.076 75 154.4 114.175 154.4 162.5C154.4 210.825 114.076 250 64.3333 250H0V325H64.3333C156.712 325 231.6 252.246 231.6 162.5C231.6 72.7537 156.712 0 64.3333 0Z"
      fill="url(#paint0_linear_359_3135)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H64.3333C156.712 0 231.6 72.7537 231.6 162.5C231.6 252.246 156.712 325 64.3333 325H0.124435C85.34 324.935 154.4 257.802 154.4 175C154.4 172.77 154.33 170.6 154.192 168.488C154.33 166.51 154.4 164.513 154.4 162.5C154.4 114.175 114.076 75 64.3333 75H0V0Z"
      fill="url(#paint1_linear_359_3135)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H64.3333C133.017 0 192.032 40.2181 217.788 97.736C181.663 71.6706 136.914 56.25 88.4583 56.25C56.9548 56.25 27.0184 62.7682 0 74.4921V0Z"
      fill="#005EF5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_359_3135"
        x1="152.792"
        y1="303.125"
        x2="-8.67167"
        y2="247.177"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00368C" />
        <stop offset="1" stopColor="#005EF5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_359_3135"
        x1="115.8"
        y1="63.0753"
        x2="115.8"
        y2="325"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#003A98" />
        <stop offset="1" stopColor="#005EF5" />
      </linearGradient>
    </defs>
  </svg>
);
