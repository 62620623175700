export const AnalyticsSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 64 64"
  >
    <g filter="url(#filter0_d_115_10684)">
      <path
        fill="#E0E0E0"
        d="M29.838 20.757a1.73 1.73 0 113.46 0 1.73 1.73 0 01-3.46 0z"
      ></path>
    </g>
    <g filter="url(#filter1_d_115_10684)">
      <path
        fill="url(#paint0_linear_115_10684)"
        fillRule="evenodd"
        d="M30.01 20.757a1.557 1.557 0 103.114 0 1.557 1.557 0 00-3.113 0zm1.558-1.73a1.73 1.73 0 100 3.46 1.73 1.73 0 000-3.46z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter2_d_115_10684)">
      <path
        fill="#E0E0E0"
        d="M29.838 43.243a1.73 1.73 0 113.46 0 1.73 1.73 0 01-3.46 0z"
      ></path>
    </g>
    <g filter="url(#filter3_d_115_10684)">
      <path
        fill="url(#paint1_linear_115_10684)"
        fillRule="evenodd"
        d="M30.01 43.243a1.557 1.557 0 103.114 0 1.557 1.557 0 00-3.113 0zm1.558-1.73a1.73 1.73 0 100 3.46 1.73 1.73 0 000-3.46z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter4_d_115_10684)">
      <path
        fill="#E0E0E0"
        d="M19.027 32a1.73 1.73 0 113.46 0 1.73 1.73 0 01-3.46 0z"
      ></path>
    </g>
    <g filter="url(#filter5_d_115_10684)">
      <path
        fill="url(#paint2_linear_115_10684)"
        fillRule="evenodd"
        d="M19.2 32a1.557 1.557 0 103.114 0 1.557 1.557 0 00-3.114 0zm1.557-1.73a1.73 1.73 0 100 3.46 1.73 1.73 0 000-3.46z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter6_d_115_10684)">
      <path
        fill="#E0E0E0"
        d="M41.514 32a1.73 1.73 0 113.46 0 1.73 1.73 0 11-3.46 0z"
      ></path>
    </g>
    <g filter="url(#filter7_d_115_10684)">
      <path
        fill="url(#paint3_linear_115_10684)"
        fillRule="evenodd"
        d="M41.687 32a1.557 1.557 0 103.113 0 1.557 1.557 0 00-3.113 0zm1.556-1.73a1.73 1.73 0 100 3.46 1.73 1.73 0 000-3.46z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter8_d_115_10684)">
      <path
        fill="#E0E0E0"
        d="M38.355 22.466a1.73 1.73 0 112.463 2.43 1.73 1.73 0 11-2.463-2.43z"
      ></path>
    </g>
    <g filter="url(#filter9_d_115_10684)">
      <path
        fill="url(#paint4_linear_115_10684)"
        fillRule="evenodd"
        d="M38.478 22.588a1.557 1.557 0 102.217 2.186 1.557 1.557 0 00-2.217-2.186zm2.323-.139a1.73 1.73 0 10-2.429 2.464 1.73 1.73 0 002.43-2.464z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter10_d_115_10684)">
      <path
        fill="#E0E0E0"
        d="M22.566 38.477a1.73 1.73 0 112.463 2.43 1.73 1.73 0 01-2.463-2.43z"
      ></path>
    </g>
    <g filter="url(#filter11_d_115_10684)">
      <path
        fill="url(#paint5_linear_115_10684)"
        fillRule="evenodd"
        d="M22.689 38.599a1.557 1.557 0 102.217 2.186 1.557 1.557 0 00-2.217-2.186zm2.323-.139a1.73 1.73 0 10-2.43 2.463 1.73 1.73 0 002.43-2.463z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter12_d_115_10684)">
      <path
        fill="#E0E0E0"
        d="M22.763 22.881a1.73 1.73 0 112.463 2.43 1.73 1.73 0 11-2.463-2.43z"
      ></path>
    </g>
    <g filter="url(#filter13_d_115_10684)">
      <path
        fill="url(#paint6_linear_115_10684)"
        fillRule="evenodd"
        d="M22.886 23.002a1.557 1.557 0 102.217 2.187 1.557 1.557 0 00-2.217-2.187zm2.323-.138a1.73 1.73 0 10-2.429 2.463 1.73 1.73 0 002.43-2.463z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter14_d_115_10684)">
      <path
        fill="#E0E0E0"
        d="M38.773 38.67a1.73 1.73 0 112.463 2.43 1.73 1.73 0 11-2.463-2.43z"
      ></path>
    </g>
    <g filter="url(#filter15_d_115_10684)">
      <path
        fill="url(#paint7_linear_115_10684)"
        fillRule="evenodd"
        d="M38.897 38.791a1.557 1.557 0 102.216 2.187 1.557 1.557 0 00-2.216-2.187zm2.323-.138a1.73 1.73 0 10-2.43 2.463 1.73 1.73 0 002.43-2.463z"
        clipRule="evenodd"
        shapeRendering="crispEdges"
      ></path>
    </g>
    <g filter="url(#filter16_ii_115_10684)">
      <path
        fill="url(#paint8_linear_115_10684)"
        d="M32 11.243c0-1.668 0-2.502.597-3.02.597-.517 1.348-.409 2.85-.193A24.216 24.216 0 117.927 29.38c.164-1.51.247-2.264.896-2.714.65-.45 1.458-.242 3.073.173l1.779.457c1.478.38 2.217.569 2.582 1.053.364.483.34 1.397.289 3.226a15.46 15.46 0 1018.886-14.65c-1.784-.406-2.676-.608-3.053-1.082C32 15.37 32 14.606 32 13.08v-1.836z"
      ></path>
    </g>
    <g filter="url(#filter17_ii_115_10684)">
      <path
        fill="url(#paint9_linear_115_10684)"
        fillRule="evenodd"
        d="M45.828 12.65a23.785 23.785 0 00-10.442-4.191c-.766-.11-1.287-.184-1.693-.175-.38.01-.61.091-.813.266-.195.17-.313.396-.378.818-.068.441-.07 1.027-.07 1.875v1.836c0 .773.001 1.318.045 1.73.044.409.125.621.239.763.105.133.318.27.795.425.47.153 1.115.3 2.016.506a15.894 15.894 0 11-19.415 15.059c.026-.923.044-1.585.012-2.078-.032-.5-.111-.74-.213-.876-.11-.146-.296-.277-.68-.42-.389-.146-.916-.282-1.665-.474l-1.778-.457c-.822-.211-1.39-.356-1.834-.4-.424-.04-.674.017-.886.164-.22.152-.356.355-.46.721-.11.39-.168.914-.252 1.683A23.784 23.784 0 1045.828 12.65zM32.597 8.223C32 8.74 32 9.575 32 11.244v1.835c0 1.527 0 2.29.378 2.763.377.473 1.27.676 3.053 1.082a15.462 15.462 0 11-18.886 14.65c.05-1.829.075-2.743-.29-3.226-.364-.484-1.103-.674-2.581-1.053l-1.779-.457c-1.615-.415-2.423-.622-3.073-.173-.65.45-.732 1.204-.896 2.713A24.216 24.216 0 1035.448 8.03c-1.503-.216-2.254-.324-2.851.194z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter18_bii_115_10684)">
      <path
        fill="#E0E0E0"
        d="M12.303 25.452c-1.583-.526-2.374-.79-2.677-1.52-.302-.73.037-1.408.716-2.765a24.217 24.217 0 0116.47-12.82c1.482-.326 2.224-.489 2.857-.016.633.472.694 1.304.816 2.968l.157 2.144c.11 1.52.166 2.279-.173 2.778-.34.498-1.216.77-2.966 1.315a15.146 15.146 0 00-8.422 6.555c-.957 1.564-1.436 2.346-2.002 2.553-.567.207-1.29-.033-2.736-.514l-2.04-.678z"
      ></path>
    </g>
    <g filter="url(#filter19_bii_115_10684)">
      <path
        fill="url(#paint10_linear_115_10684)"
        fillRule="evenodd"
        d="M17.231 13.027a24.043 24.043 0 00-6.734 8.217c-.342.684-.589 1.178-.716 1.592-.123.404-.127.713.005 1.03.13.314.366.537.777.749.42.215.998.408 1.795.673l2.04.678c.726.241 1.258.418 1.68.51.419.091.703.093.941.006.234-.086.47-.297.776-.709.304-.409.658-.987 1.139-1.772a15.32 15.32 0 018.517-6.63c.879-.274 1.527-.475 1.998-.67.474-.196.737-.373.877-.578.143-.21.211-.485.225-.914.015-.43-.026-.99-.082-1.753l-.157-2.145c-.061-.837-.106-1.445-.212-1.904-.104-.452-.262-.734-.535-.938-.275-.205-.576-.278-.998-.257-.432.021-.971.14-1.718.303a24.044 24.044 0 00-9.618 4.512zM9.626 23.934c.303.73 1.094.993 2.677 1.519l2.04.678c1.446.48 2.169.721 2.736.514.566-.207 1.045-.989 2.002-2.553a15.145 15.145 0 018.422-6.555c1.75-.545 2.626-.817 2.966-1.315.34-.499.284-1.259.173-2.778l-.157-2.144c-.122-1.664-.183-2.496-.816-2.968-.633-.473-1.375-.31-2.857.015a24.217 24.217 0 00-16.47 12.82c-.679 1.358-1.018 2.037-.716 2.767z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#filter20_bii_115_10684)">
      <path
        fill="#005EF5"
        fillOpacity="0.2"
        d="M26.378 31.567c0-4.892 0-7.338 1.52-8.858 1.52-1.52 3.966-1.52 8.859-1.52h14.27c4.892 0 7.339 0 8.858 1.52 1.52 1.52 1.52 3.966 1.52 8.858v.865c0 4.893 0 7.339-1.52 8.859-1.52 1.52-3.966 1.52-8.858 1.52h-14.27c-4.893 0-7.339 0-8.859-1.52-1.52-1.52-1.52-3.966-1.52-8.859v-.865z"
      ></path>
    </g>
    <g filter="url(#filter21_bii_115_10684)">
      <path
        fill="url(#paint11_linear_115_10684)"
        fillRule="evenodd"
        d="M51.027 21.362h-14.27c-2.451 0-4.272 0-5.671.188-1.394.188-2.343.559-3.066 1.281-.722.723-1.093 1.672-1.28 3.065-.188 1.4-.189 3.22-.189 5.671v.865c0 2.451 0 4.272.189 5.671.187 1.394.558 2.343 1.28 3.065.723.723 1.672 1.094 3.066 1.281 1.399.188 3.22.189 5.67.189h14.271c2.451 0 4.272 0 5.67-.189 1.395-.187 2.344-.558 3.066-1.28.723-.723 1.094-1.672 1.281-3.066.188-1.399.188-3.22.188-5.67v-.866c0-2.45 0-4.272-.188-5.67-.187-1.394-.558-2.343-1.28-3.066-.723-.722-1.672-1.093-3.066-1.28-1.399-.189-3.22-.19-5.671-.19zm-23.129 1.347c-1.52 1.52-1.52 3.966-1.52 8.858v.865c0 4.893 0 7.339 1.52 8.859 1.52 1.52 3.966 1.52 8.859 1.52h14.27c4.892 0 7.339 0 8.858-1.52 1.52-1.52 1.52-3.966 1.52-8.859v-.865c0-4.892 0-7.338-1.52-8.858-1.52-1.52-3.966-1.52-8.858-1.52h-14.27c-4.893 0-7.339 0-8.859 1.52z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_d_115_10684"
        width="7.459"
        height="7.459"
        x="27.838"
        y="18.027"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter1_d_115_10684"
        width="7.459"
        height="7.459"
        x="27.838"
        y="18.027"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter2_d_115_10684"
        width="7.459"
        height="7.459"
        x="27.838"
        y="40.514"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter3_d_115_10684"
        width="7.459"
        height="7.459"
        x="27.838"
        y="40.514"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter4_d_115_10684"
        width="7.459"
        height="7.459"
        x="17.027"
        y="29.27"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter5_d_115_10684"
        width="7.459"
        height="7.459"
        x="17.027"
        y="29.27"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter6_d_115_10684"
        width="7.459"
        height="7.459"
        x="39.514"
        y="29.27"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter7_d_115_10684"
        width="7.459"
        height="7.459"
        x="39.514"
        y="29.27"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter8_d_115_10684"
        width="7.459"
        height="7.459"
        x="35.857"
        y="20.951"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter9_d_115_10684"
        width="7.459"
        height="7.459"
        x="35.857"
        y="20.951"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter10_d_115_10684"
        width="7.459"
        height="7.459"
        x="20.067"
        y="36.962"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter11_d_115_10684"
        width="7.459"
        height="7.459"
        x="20.067"
        y="36.962"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter12_d_115_10684"
        width="7.459"
        height="7.459"
        x="20.265"
        y="21.366"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter13_d_115_10684"
        width="7.459"
        height="7.459"
        x="20.265"
        y="21.366"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter14_d_115_10684"
        width="7.459"
        height="7.459"
        x="36.275"
        y="37.155"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter15_d_115_10684"
        width="7.459"
        height="7.459"
        x="36.275"
        y="37.155"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_115_10684"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_115_10684"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter16_ii_115_10684"
        width="52.432"
        height="52.365"
        x="5.784"
        y="5.851"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow_115_10684"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect1_innerShadow_115_10684"
          result="effect2_innerShadow_115_10684"
        ></feBlend>
      </filter>
      <filter
        id="filter17_ii_115_10684"
        width="52.432"
        height="52.365"
        x="5.784"
        y="5.851"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow_115_10684"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect1_innerShadow_115_10684"
          result="effect2_innerShadow_115_10684"
        ></feBlend>
      </filter>
      <filter
        id="filter18_bii_115_10684"
        width="37.212"
        height="34.683"
        x="1.515"
        y="0.036"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10684"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10684"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10684"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10684"
          result="effect3_innerShadow_115_10684"
        ></feBlend>
      </filter>
      <filter
        id="filter19_bii_115_10684"
        width="37.212"
        height="34.683"
        x="1.515"
        y="0.036"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10684"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10684"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10684"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10684"
          result="effect3_innerShadow_115_10684"
        ></feBlend>
      </filter>
      <filter
        id="filter20_bii_115_10684"
        width="51.027"
        height="37.622"
        x="18.378"
        y="13.189"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10684"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10684"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10684"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10684"
          result="effect3_innerShadow_115_10684"
        ></feBlend>
      </filter>
      <filter
        id="filter21_bii_115_10684"
        width="51.027"
        height="37.622"
        x="18.378"
        y="13.189"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="4"
        ></feGaussianBlur>
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_115_10684"
        ></feComposite>
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_115_10684"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-2" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow_115_10684"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="2" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"></feColorMatrix>
        <feBlend
          in2="effect2_innerShadow_115_10684"
          result="effect3_innerShadow_115_10684"
        ></feBlend>
      </filter>
      <linearGradient
        id="paint0_linear_115_10684"
        x1="31.568"
        x2="31.568"
        y1="19.027"
        y2="21.718"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_115_10684"
        x1="31.568"
        x2="31.568"
        y1="41.514"
        y2="44.204"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_115_10684"
        x1="20.757"
        x2="20.757"
        y1="30.27"
        y2="32.961"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_115_10684"
        x1="43.243"
        x2="43.243"
        y1="30.27"
        y2="32.961"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear_115_10684"
        x1="40.801"
        x2="38.912"
        y1="22.449"
        y2="24.365"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint5_linear_115_10684"
        x1="25.012"
        x2="23.122"
        y1="38.46"
        y2="40.376"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint6_linear_115_10684"
        x1="25.209"
        x2="23.32"
        y1="22.864"
        y2="24.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint7_linear_115_10684"
        x1="41.22"
        x2="39.33"
        y1="38.653"
        y2="40.569"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint8_linear_115_10684"
        x1="9.363"
        x2="62.246"
        y1="11.416"
        y2="20.717"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5"></stop>
        <stop offset="1" stopColor="#3787FF"></stop>
      </linearGradient>
      <linearGradient
        id="paint9_linear_115_10684"
        x1="-2.152"
        x2="93.075"
        y1="-169.212"
        y2="-1.875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint10_linear_115_10684"
        x1="32"
        x2="32"
        y1="7.784"
        y2="45.453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint11_linear_115_10684"
        x1="22.571"
        x2="32.724"
        y1="19.223"
        y2="52.642"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="0.766" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
  </svg>
)
