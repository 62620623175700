export const LeftWhyAreWeSvg = () => (
  <svg
    width="232"
    height="325"
    viewBox="0 0 232 325"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M167.267 0H231.6V75H167.267C117.524 75 77.2001 114.175 77.2001 162.5C77.2001 210.825 117.524 250 167.267 250H231.6V325H167.267C74.8879 325 0.000106812 252.246 0.000106812 162.5C0.000106812 72.7537 74.8879 0 167.267 0Z"
      fill="url(#paint0_linear_359_3136)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M231.6 0H167.267C74.8879 0 0.000106812 72.7537 0.000106812 162.5C0.000106812 252.246 74.8879 325 167.267 325H231.476C146.26 324.935 77.2001 257.802 77.2001 175C77.2001 172.77 77.27 170.6 77.4077 168.488C77.27 166.51 77.2001 164.513 77.2001 162.5C77.2001 114.175 117.524 75 167.267 75H231.6V0Z"
      fill="url(#paint1_linear_359_3136)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M231.6 0H167.267C98.5828 0 39.5678 40.2181 13.8122 97.736C49.9375 71.6706 94.686 56.25 143.142 56.25C174.645 56.25 204.582 62.7682 231.6 74.4921V0Z"
      fill="#005EF5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_359_3136"
        x1="78.8084"
        y1="303.125"
        x2="240.272"
        y2="247.177"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00368C" />
        <stop offset="1" stopColor="#005EF5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_359_3136"
        x1="115.8"
        y1="63.0753"
        x2="115.8"
        y2="325"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#003A98" />
        <stop offset="1" stopColor="#005EF5" />
      </linearGradient>
    </defs>
  </svg>
);
