import React from 'react'
import { DorsSvg } from '../../../svg/DorsSvg'
import { EmailSvg } from '../../../svg/EmailSvg'
import { PhoneSvg } from '../../../svg/PhoneSvg'
import { VkSvg } from '../../../svg/VkSvg'
import { WhatsAppSvg } from '../../../svg/WhatsAppSvg'
import { YouTubeSvg } from '../../../svg/YouTubeSvg'
import './styles.scss'
import { Inner } from '../../layout/Inner/Inner'
import { Link } from 'react-router-dom'
import { LinkedInSvg } from '../../../svg/LinkedInSvg'
export const Footer = () => {
  return (
    <footer className="footer">
      <Inner mod='footer'>
        <div className="footer__top">
          <div className="footer__about">
            <Link to={'/'} onClick={() => window.scrollTo(0, 0)} className='footer__logo'>
              <DorsSvg />
            </Link>
            <div className="footer__text">Набор HR API сервисов и инструментов, позволяющих усилить вашу HR систему</div>
          </div>
          <div className="footer__section">
            <div className="footer__section-name">Меню</div>
            <ul className="footer__menu">
              {menu.map((item, index) => <li key={index} className="footer__item"><Link className="footer__link" onClick={() => window.scrollTo(0, 0)} to={item.link}>{item.text}</Link></li>)}
            </ul>
          </div>
          <div className="footer__section">
            <div className="footer__section-name">Контакты</div>
            <div className="footer__contact">
              <a href="mailto:info@axes.pro" className="footer__contact-link">
                <EmailSvg />
                info@axes.pro
              </a>
              <a href="tel:+74953080470" className="footer__contact-link">
                <PhoneSvg />
                +7 (495) 308-04-70
              </a>
              <div className="footer__contact-descr">по всему миру, оплата в соответствии с тарифами вашего оператора связи</div>
              <div className="footer__socials">
                {socials.map((item, index) => <a key={index} className="footer__social" href={item.link} target="_blank" rel="noreferrer">{item.svg}</a>)}
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">© {new Date().getFullYear()} «DORS»</div>
      </Inner>
    </footer>
  )
}

const menu = [
  { text: 'Главная', link: '/' },
  // { text: 'Платформа', link: '#' },
  { text: 'Решения', link: '/solutions/multiposting' }
  // { text: 'HR новости', link: '#' }
]

const socials = [
  { svg: <VkSvg />, link: 'https://vk.com/axespro' },
  { svg: <YouTubeSvg />, link: 'https://www.youtube.com/user/axesprodigital' },
  { svg: <LinkedInSvg />, link: 'https://www.linkedin.com/company/axes-pro' },
  { svg: <WhatsAppSvg />, link: 'https://wa.me/+79260953732' }
]
