export const RentableSvg = () => (
  <svg
    width="148"
    height="148"
    viewBox="0 0 148 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_ii_359_3342)">
      <rect
        x="18.4346"
        y="30.5138"
        width="111.131"
        height="48.3177"
        rx="12"
        fill="url(#paint0_linear_359_3342)"
      />
    </g>
    <rect
      x="18.6346"
      y="30.7138"
      width="110.731"
      height="47.9177"
      rx="11.8"
      stroke="url(#paint1_linear_359_3342)"
      strokeWidth="0.4"
    />
    <g filter="url(#filter1_d_359_3342)">
      <rect
        x="47.4249"
        y="16.0187"
        width="9.66355"
        height="26.5748"
        rx="4.83177"
        fill="#E0E0E0"
      />
      <rect
        x="47.6249"
        y="16.2187"
        width="9.26355"
        height="26.1748"
        rx="4.63177"
        stroke="url(#paint2_linear_359_3342)"
        strokeWidth="0.4"
      />
    </g>
    <g filter="url(#filter2_d_359_3342)">
      <rect
        x="90.9111"
        y="16.0187"
        width="9.66355"
        height="26.5748"
        rx="4.83177"
        fill="#E0E0E0"
      />
      <rect
        x="91.1111"
        y="16.2187"
        width="9.26355"
        height="26.1748"
        rx="4.63177"
        stroke="url(#paint3_linear_359_3342)"
        strokeWidth="0.4"
      />
    </g>
    <g filter="url(#filter3_bii_359_3342)">
      <rect
        x="14.7324"
        y="53.6888"
        width="111.131"
        height="79.7243"
        rx="12"
        transform="rotate(-5.72999 14.7324 53.6888)"
        fill="url(#paint4_linear_359_3342)"
        fillOpacity="0.24"
      />
      <rect
        x="14.9514"
        y="53.8679"
        width="110.731"
        height="79.3243"
        rx="11.8"
        transform="rotate(-5.72999 14.9514 53.8679)"
        stroke="url(#paint5_linear_359_3342)"
        strokeWidth="0.4"
      />
    </g>
    <g filter="url(#filter4_bii_359_3342)">
      <rect
        x="18.6411"
        y="49.9492"
        width="111.131"
        height="79.7243"
        rx="12"
        transform="rotate(3.49387 18.6411 49.9492)"
        fill="url(#paint6_linear_359_3342)"
        fillOpacity="0.24"
      />
      <rect
        x="18.8286"
        y="50.161"
        width="110.731"
        height="79.3243"
        rx="11.8"
        transform="rotate(3.49387 18.8286 50.161)"
        stroke="url(#paint7_linear_359_3342)"
        strokeWidth="0.4"
      />
    </g>
    <rect
      width="8.58783"
      height="17.1757"
      rx="4.29392"
      transform="matrix(-0.998141 -0.0609417 -0.0609417 0.998141 99.7208 91.7396)"
      fill="#005EF5"
    />
    <rect
      width="8.58783"
      height="17.1757"
      rx="4.29392"
      transform="matrix(-0.998141 -0.0609417 -0.0609417 0.998141 86.863 90.9546)"
      fill="#4D92FF"
    />
    <g filter="url(#filter5_bdii_359_3342)">
      <mask id="path-13-inside-1_359_3342" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.2875 90.7797C32.7621 99.385 39.3122 106.787 47.9175 107.312C54.5285 107.716 60.4292 103.944 63.0475 98.2718L100.096 100.534C103.145 100.72 105.767 98.3994 105.953 95.3507C106.139 92.3019 103.819 89.6796 100.77 89.4934L63.823 87.2376C61.994 81.1528 56.5323 76.5594 49.8202 76.1496C41.2148 75.6242 33.8129 82.1743 33.2875 90.7797ZM45.6384 97.5607C42.3221 97.3583 39.7979 94.5058 40.0004 91.1895C40.2029 87.8733 43.0554 85.3491 46.3716 85.5516C49.6878 85.7541 52.212 88.6065 52.0096 91.9228C51.8071 95.239 48.9546 97.7632 45.6384 97.5607Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2875 90.7797C32.7621 99.385 39.3122 106.787 47.9175 107.312C54.5285 107.716 60.4292 103.944 63.0475 98.2718L100.096 100.534C103.145 100.72 105.767 98.3994 105.953 95.3507C106.139 92.3019 103.819 89.6796 100.77 89.4934L63.823 87.2376C61.994 81.1528 56.5323 76.5594 49.8202 76.1496C41.2148 75.6242 33.8129 82.1743 33.2875 90.7797ZM45.6384 97.5607C42.3221 97.3583 39.7979 94.5058 40.0004 91.1895C40.2029 87.8733 43.0554 85.3491 46.3716 85.5516C49.6878 85.7541 52.212 88.6065 52.0096 91.9228C51.8071 95.239 48.9546 97.7632 45.6384 97.5607Z"
        fill="#E0E0E0"
      />
      <path
        d="M63.0475 98.2718L62.6843 98.1042L62.7989 97.8559L63.0718 97.8726L63.0475 98.2718ZM100.77 89.4934L100.794 89.0942L100.77 89.4934ZM63.823 87.2376L63.7986 87.6369L63.5202 87.6199L63.4399 87.3528L63.823 87.2376ZM47.8932 107.712C39.0673 107.173 32.3493 99.5811 32.8882 90.7553L33.6867 90.804C33.1748 99.1889 39.557 106.401 47.9419 106.913L47.8932 107.712ZM63.4106 98.4395C60.7256 104.256 54.6741 108.126 47.8932 107.712L47.9419 106.913C54.3828 107.306 60.1328 103.631 62.6843 98.1042L63.4106 98.4395ZM100.072 100.933L63.0231 98.6711L63.0718 97.8726L100.12 100.135L100.072 100.933ZM106.352 95.375C106.153 98.6443 103.341 101.133 100.072 100.933L100.12 100.135C102.949 100.307 105.381 98.1545 105.554 95.3263L106.352 95.375ZM100.794 89.0942C104.064 89.2938 106.552 92.1058 106.352 95.375L105.554 95.3263C105.727 92.4981 103.574 90.0654 100.746 89.8927L100.794 89.0942ZM63.8474 86.8384L100.794 89.0942L100.746 89.8927L63.7986 87.6369L63.8474 86.8384ZM49.8446 75.7503C56.7292 76.1707 62.3303 80.8823 64.2061 87.1225L63.4399 87.3528C61.6576 81.4232 56.3353 76.9481 49.7958 76.5488L49.8446 75.7503ZM32.8882 90.7553C33.4271 81.9294 41.0187 75.2115 49.8446 75.7503L49.7958 76.5488C41.4109 76.0369 34.1987 82.4192 33.6867 90.804L32.8882 90.7553ZM40.3997 91.2139C40.2106 94.3097 42.567 96.9725 45.6627 97.1615L45.614 97.96C42.0773 97.7441 39.3852 94.7019 39.6011 91.1652L40.3997 91.2139ZM46.3472 85.9508C43.2515 85.7618 40.5887 88.1182 40.3997 91.2139L39.6011 91.1652C39.8171 87.6284 42.8592 84.9364 46.396 85.1523L46.3472 85.9508ZM51.6103 91.8984C51.7993 88.8027 49.443 86.1398 46.3472 85.9508L46.396 85.1523C49.9327 85.3683 52.6248 88.4104 52.4088 91.9471L51.6103 91.8984ZM45.6627 97.1615C48.7585 97.3505 51.4213 94.9941 51.6103 91.8984L52.4088 91.9471C52.1929 95.4839 49.1507 98.1759 45.614 97.96L45.6627 97.1615Z"
        fill="url(#paint8_linear_359_3342)"
        mask="url(#path-13-inside-1_359_3342)"
      />
    </g>
    <defs>
      <filter
        id="filter0_ii_359_3342"
        x="16.4346"
        y="28.5138"
        width="115.131"
        height="52.3177"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3342"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3342"
          result="effect2_innerShadow_359_3342"
        />
      </filter>
      <filter
        id="filter1_d_359_3342"
        x="45.4249"
        y="15.0187"
        width="13.6636"
        height="30.5747"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3342"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3342"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_359_3342"
        x="88.9111"
        y="15.0187"
        width="13.6636"
        height="30.5747"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3342"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3342"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_bii_359_3342"
        x="8.2334"
        y="36.0945"
        width="131.533"
        height="103.419"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3342"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3342"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3342"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3342"
          result="effect3_innerShadow_359_3342"
        />
      </filter>
      <filter
        id="filter4_bii_359_3342"
        x="6.78821"
        y="42.9548"
        width="129.772"
        height="100.337"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3342"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3342"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3342"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3342"
          result="effect3_innerShadow_359_3342"
        />
      </filter>
      <filter
        id="filter5_bdii_359_3342"
        x="31.2579"
        y="74.1201"
        width="76.7057"
        height="36.2218"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3342"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_359_3342"
          result="effect2_dropShadow_359_3342"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_359_3342"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect3_innerShadow_359_3342"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_innerShadow_359_3342"
          result="effect4_innerShadow_359_3342"
        />
      </filter>
      <linearGradient
        id="paint0_linear_359_3342"
        x1="22.0584"
        y1="34.1376"
        x2="129.565"
        y2="77.6236"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#3787FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_359_3342"
        x1="-4.36403"
        y1="-146.063"
        x2="47.163"
        y2="62.1916"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_359_3342"
        x1="52.2567"
        y1="16.0187"
        x2="52.2567"
        y2="36.6879"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_359_3342"
        x1="95.7429"
        y1="16.0187"
        x2="95.7429"
        y2="36.6879"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_359_3342"
        x1="70.2978"
        y1="56.7426"
        x2="70.2978"
        y2="137.854"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_359_3342"
        x1="2.65299"
        y1="46.4412"
        x2="44.907"
        y2="166.118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_359_3342"
        x1="74.2065"
        y1="53.003"
        x2="74.2065"
        y2="134.115"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_359_3342"
        x1="6.56168"
        y1="42.7016"
        x2="48.8157"
        y2="162.378"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_359_3342"
        x1="73.7917"
        y1="77.6133"
        x2="72.3118"
        y2="101.851"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
