import React, { useEffect, useRef } from 'react'
import './style.scss'
import { H2 } from '../../ui/H/H'
import { Inner } from '../../layout/Inner/Inner'

export const ConnectionSteps: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current
    const content = contentRef.current

    const offset = 50

    if ((container != null) && (content != null)) {
      container.style.height = ''

      let contentWidth = 0
      const stepItems = content.querySelectorAll<HTMLElement>('.connection-step__item')
      for (let index = 0; index < stepItems.length; index++) {
        contentWidth += stepItems[index].offsetWidth
      }
      contentWidth += Number.parseFloat(window.getComputedStyle(content).gap) * (stepItems.length - 1)
      contentWidth += Number.parseFloat(window.getComputedStyle(content).paddingLeft) + Number.parseFloat(window.getComputedStyle(content).paddingRight)

      const scrollWidth = contentWidth - container.offsetWidth

      content.style.minWidth = `${contentWidth}px`

      container.style.height = `${container.offsetHeight + scrollWidth + offset}px`

      const handleScroll = () => {
        if (container.getBoundingClientRect().top < 0 && container.getBoundingClientRect().bottom > window.innerHeight) {
          let tranalste = Math.abs(container.getBoundingClientRect().top) - offset
          if (tranalste > scrollWidth) tranalste = scrollWidth
          if (tranalste < 0) tranalste = 0
          content.style.transform = `translateX(-${tranalste}px)`
        }
        if (container.getBoundingClientRect().top > offset) {
          content.style.transform = 'translateX(0)'
        }
        if (container.getBoundingClientRect().bottom < window.innerHeight) {
          content.style.transform = `translateX(-${scrollWidth}px)`
        }
      }

      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  })

  return (
    <section className="connection-step" ref={containerRef}>
      <div className="connection-step__pin">
        <Inner>
          <H2 mod="connection-step">Этапы подключения сервиса DORS&nbsp;Мультипостинг</H2>
        </Inner>
        <div className="connection-step__timeline" ref={contentRef}>
          {data.map((item, index) =>
            <div key={index} className={'connection-step__item' + ` connection-step__item--${item.mod}`}>
              <div className="connection-step__num">0{++index}</div>
              <div className="connection-step__text" dangerouslySetInnerHTML={{ __html: item.text }}></div>
            </div>)}
            <div className="connection-step__lines">
              <svg preserveAspectRatio="xMinYMin slice" width="2951" height="72" viewBox="0 0 2951 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="p1" d="M2950 1.5L2175.58 58.0449C2166.88 58.6799 2158.15 58.3958 2149.51 57.1974L1763.28 3.60616C1750.83 1.87876 1738.19 2.05409 1725.8 4.12612L1413.29 56.361C1402.81 58.1131 1392.14 58.5103 1381.56 57.5429L860.966 9.95936C850.366 8.99049 839.686 9.39049 829.188 11.1495L486.955 68.4942C477.022 70.1585 466.923 70.6066 456.882 69.8285L1 34.5" stroke="#ffffff" strokeWidth="2"/>
                <animate xlinkHref="#p1"
                  attributeName="d"
                  attributeType="XML"
                  values="M2950 1.5L2175.58 58.0449C2166.88 58.6799 2158.15 58.3958 2149.51 57.1974L1763.28 3.60616C1750.83 1.87876 1738.19 2.05409 1725.8 4.12612L1413.29 56.361C1402.81 58.1131 1392.14 58.5103 1381.56 57.5429L860.966 9.95936C850.366 8.99049 839.686 9.39049 829.188 11.1495L486.955 68.4942C477.022 70.1585 466.923 70.6066 456.882 69.8285L1 34.5;M2950 70.5L2175.58 13.9551C2166.88 13.3201 2158.15 13.6042 2149.51 14.8026L1763.28 68.3938C1750.83 70.1212 1738.19 69.9459 1725.8 67.8739L1413.29 15.639C1402.81 13.8869 1392.14 13.4897 1381.56 14.4571L860.966 62.0406C850.366 63.0095 839.686 62.6095 829.188 60.8505L486.955 3.5058C477.022 1.84147 466.923 1.39341 456.882 2.17155L1 37.5;M2950 1.5L2175.58 58.0449C2166.88 58.6799 2158.15 58.3958 2149.51 57.1974L1763.28 3.60616C1750.83 1.87876 1738.19 2.05409 1725.8 4.12612L1413.29 56.361C1402.81 58.1131 1392.14 58.5103 1381.56 57.5429L860.966 9.95936C850.366 8.99049 839.686 9.39049 829.188 11.1495L486.955 68.4942C477.022 70.1585 466.923 70.6066 456.882 69.8285L1 34.5"
                  dur="10s"
                  repeatCount="indefinite"
                />
              </svg>
              <svg preserveAspectRatio="xMinYMin slice" width="2952" height="246" viewBox="0 0 2952 246" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="p2" d="M2951 152.5L2427.91 5.92103C2404.8 -0.553665 2380.31 -0.184934 2357.41 6.98249L1824.92 173.639C1810.43 178.174 1795.23 180.01 1780.07 179.053L1264.53 146.512C1253.89 145.84 1243.2 146.543 1232.75 148.604L756.733 242.384C728.463 247.954 699.136 243.505 673.79 229.801L501.583 136.698C479.08 124.531 453.364 119.622 427.961 122.645L0.5 173.5" stroke="white" strokeOpacity="0.26" strokeWidth="2"/>
                <animate xlinkHref="#p2"
                  attributeName="d"
                  attributeType="XML"
                  values="M2951 152.5L2427.91 5.92103C2404.8 -0.553665 2380.31 -0.184934 2357.41 6.98249L1824.92 173.639C1810.43 178.174 1795.23 180.01 1780.07 179.053L1264.53 146.512C1253.89 145.84 1243.2 146.543 1232.75 148.604L756.733 242.384C728.463 247.954 699.136 243.505 673.79 229.801L501.583 136.698C479.08 124.531 453.364 119.622 427.961 122.645L0.5 173.5;M2951 93.5L2427.91 240.079C2404.8 246.554 2380.31 246.185 2357.41 239.018L1824.92 72.3614C1810.43 67.8257 1795.23 65.9905 1780.07 66.9471L1264.53 99.4885C1253.89 100.16 1243.2 99.4568 1232.75 97.3962L756.733 3.61585C728.463 -1.9536 699.136 2.49544 673.79 16.1987L501.583 109.302C479.08 121.469 453.364 126.378 427.961 123.355L0.5 72.5;M2951 152.5L2427.91 5.92103C2404.8 -0.553665 2380.31 -0.184934 2357.41 6.98249L1824.92 173.639C1810.43 178.174 1795.23 180.01 1780.07 179.053L1264.53 146.512C1253.89 145.84 1243.2 146.543 1232.75 148.604L756.733 242.384C728.463 247.954 699.136 243.505 673.79 229.801L501.583 136.698C479.08 124.531 453.364 119.622 427.961 122.645L0.5 173.5"
                  dur="18s"
                  begin="5s"
                  repeatCount="indefinite"
                />
              </svg>
              <svg preserveAspectRatio="xMinYMin slice" width="2957" height="195" viewBox="0 0 2957 195" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="p3" d="M2956.5 81L2615.93 47.4093C2599.8 45.8185 2583.52 47.4049 2568 52.0786L2209.28 160.116C2181.63 168.443 2151.94 166.86 2125.34 155.64L1819.26 26.5615C1788.11 13.4268 1752.95 13.5763 1721.92 26.9753L1373.59 177.375C1341.67 191.154 1305.44 190.905 1273.72 176.687L907.096 12.3663C871.469 -3.60161 830.403 -1.85854 796.257 17.0708L505.684 178.158C484.681 189.802 460.748 195.096 436.793 193.397L1 162.5" stroke="white" strokeOpacity="0.26" strokeWidth="2"/>
                <animate xlinkHref="#p3"
                  attributeName="d"
                  attributeType="XML"
                  values="M2956.5 81L2615.93 47.4093C2599.8 45.8185 2583.52 47.4049 2568 52.0786L2209.28 160.116C2181.63 168.443 2151.94 166.86 2125.34 155.64L1819.26 26.5615C1788.11 13.4268 1752.95 13.5763 1721.92 26.9753L1373.59 177.375C1341.67 191.154 1305.44 190.905 1273.72 176.687L907.096 12.3663C871.469 -3.60161 830.403 -1.85854 796.257 17.0708L505.684 178.158C484.681 189.802 460.748 195.096 436.793 193.397L1 162.5;M2956.5 114L2615.93 147.591C2599.8 149.181 2583.52 147.595 2568 142.921L2209.28 34.8837C2181.63 26.5565 2151.94 28.1397 2125.34 39.36L1819.26 168.438C1788.11 181.573 1752.95 181.424 1721.92 168.025L1373.59 17.6254C1341.67 3.84582 1305.44 4.09527 1273.72 18.313L907.096 182.634C871.469 198.602 830.403 196.859 796.257 177.929L505.684 16.8423C484.681 5.1984 460.748 -0.0958888 436.793 1.60251L1 32.5;M2956.5 81L2615.93 47.4093C2599.8 45.8185 2583.52 47.4049 2568 52.0786L2209.28 160.116C2181.63 168.443 2151.94 166.86 2125.34 155.64L1819.26 26.5615C1788.11 13.4268 1752.95 13.5763 1721.92 26.9753L1373.59 177.375C1341.67 191.154 1305.44 190.905 1273.72 176.687L907.096 12.3663C871.469 -3.60161 830.403 -1.85854 796.257 17.0708L505.684 178.158C484.681 189.802 460.748 195.096 436.793 193.397L1 162.5"
                  dur="18s"
                  begin="10s"
                  repeatCount="indefinite"
                />
              </svg>
            </div>
        </div>
      </div>
    </section>
  )
}

const data = [
  { mod: 'w315', text: 'Мы&nbsp;исследуем технические возможности интеграции сервиса и&nbsp;вашей ATS/CRM' },
  { mod: 'w360', text: 'Формируем единую карточку для размещения вакансий, в&nbsp;которой учитываем все важные для Вас особенности подбора' },
  { mod: 'w430', text: 'Выстраиваем процесс интеграции с&nbsp;вашим минимальным участием, ведь основные справочники у&nbsp;нас уже структурированы и&nbsp;прописаны' },
  { mod: 'w420', text: 'Мы&nbsp;подключаем интеграцию сервиса с&nbsp;ATS Вашей компании и&nbsp;обучаем ваших сотрудников работать с&nbsp;новым сервисом' },
  { mod: 'w430', text: 'На&nbsp;постоянной основе обновляем и&nbsp;поддерживаем сервис и&nbsp;интеграцию, а&nbsp;наши технические специалисты в&nbsp;любое время помогут с&nbsp;решением неординарных кейсов в&nbsp;процессе эксплуатации' }
]
