export const PhoneSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9043 14.7841L14.2244 14.3039C15.139 12.9321 16.9925 12.5614 18.3644 13.4759L20.6703 15.0132C21.8677 15.8115 22.3252 17.3504 21.7583 18.6732L21.4611 19.3667C21.08 20.2558 20.3962 20.9811 19.531 21.4137C19.0066 21.6759 18.3154 21.9524 17.5063 21.9904C15.5855 22.0807 11.3976 21.6135 6.89206 17.108C2.38655 12.6025 1.91929 8.41447 2.0096 6.49372C2.04764 5.68461 2.32414 4.99338 2.58632 4.46903C3.01892 3.60382 3.7442 2.91995 4.63333 2.5389L5.32679 2.2417C6.64956 1.6748 8.18851 2.13228 8.98679 3.32971L10.5241 5.63561C11.4386 7.00748 11.0679 8.86102 9.69606 9.7756L9.21594 10.0957C9.22946 10.2002 9.25816 10.337 9.31626 10.507C9.4876 11.0081 9.9283 11.8336 11.0473 12.9527C12.1664 14.0717 12.9919 14.5124 13.493 14.6838C13.663 14.7418 13.7998 14.7705 13.9043 14.7841ZM15.0685 16.0424L15.6111 15.2284C16.0151 14.6224 16.8339 14.4587 17.4399 14.8627L19.7458 16.3999C20.2747 16.7526 20.4768 17.4324 20.2264 18.0167L19.9292 18.7101C19.7034 19.2369 19.2982 19.6667 18.7856 19.923C18.3573 20.1371 17.9063 20.3031 17.428 20.3256C15.9207 20.3965 12.2071 20.066 8.07056 15.9295C3.93407 11.793 3.60355 8.07933 3.67441 6.57199C3.6969 6.09368 3.86288 5.64267 4.07702 5.21438C4.33334 4.70175 4.76306 4.29657 5.28985 4.0708L5.98332 3.7736C6.56763 3.52318 7.24742 3.72526 7.60005 4.25421L9.13732 6.5601C9.54131 7.1661 9.37756 7.98486 8.77156 8.38886L7.95762 8.93149C7.79516 9.0398 7.65653 9.18304 7.60665 9.37182C7.44326 9.99017 7.36949 11.6318 9.86884 14.1312C12.3682 16.6305 14.0098 16.5567 14.6282 16.3933C14.817 16.3435 14.9602 16.2048 15.0685 16.0424Z"
      fill="currentColor"
    />
  </svg>
)
