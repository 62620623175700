import React from 'react'
import classNames from 'classnames'
import './style.scss'
import { default as RSelect } from 'react-select'

interface Props {
  placeholder?: string
  options?: any
  onChange?: (option: any) => void
  mod?: string
}
export const Select: React.FC<Props> = ({
  placeholder,
  options,
  onChange,
  mod
}) => {
  const modifiers = mod?.split(',').map((item) => item.trim())
  const baseName = 'ui-select'
  const className = classNames(baseName, modifiers?.map((item) => (item.startsWith('--') ? `${baseName}${item}` : `${item}__${baseName}`)))

  return (
    <RSelect
      placeholder={placeholder}
      isSearchable={false}
      onChange={onChange}
      className={className}
      classNamePrefix={baseName}
      options={options}
    />
  )
}
