export const BestPractiesSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_ii_359_3235)">
      <path
        d="M10.0306 25.1811C10.3271 24.5288 11.0962 24.2403 11.7484 24.5367L21.1969 28.8306C21.8492 29.1271 22.1376 29.8962 21.8412 30.5484L15.7603 43.929C15.3261 44.8845 13.996 44.9557 13.4623 44.052L11.1646 40.1619C10.8873 39.6925 10.346 39.4465 9.80999 39.5463L5.3684 40.3737C4.33661 40.5659 3.51553 39.5171 3.94976 38.5616L10.0306 25.1811Z"
        fill="#D9D9D9"
      />
      <path
        d="M10.0306 25.1811C10.3271 24.5288 11.0962 24.2403 11.7484 24.5367L21.1969 28.8306C21.8492 29.1271 22.1376 29.8962 21.8412 30.5484L15.7603 43.929C15.3261 44.8845 13.996 44.9557 13.4623 44.052L11.1646 40.1619C10.8873 39.6925 10.346 39.4465 9.80999 39.5463L5.3684 40.3737C4.33661 40.5659 3.51553 39.5171 3.94976 38.5616L10.0306 25.1811Z"
        fill="url(#paint0_linear_359_3235)"
      />
    </g>
    <g filter="url(#filter1_ii_359_3235)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1432 28.9487L11.6948 24.6548C11.1077 24.3881 10.4155 24.6477 10.1488 25.2347L4.06787 38.6153C3.67706 39.4753 4.41603 40.4192 5.34464 40.2462L9.78623 39.4188C10.3759 39.309 10.9712 39.5795 11.2763 40.096L13.574 43.9861C14.0543 44.7994 15.2514 44.7353 15.6422 43.8753L21.7231 30.4948C21.9899 29.9077 21.7303 29.2155 21.1432 28.9487ZM11.7484 24.5367C11.0962 24.2403 10.3271 24.5288 10.0306 25.1811L3.94976 38.5616C3.51553 39.5171 4.33661 40.5659 5.3684 40.3737L9.80999 39.5463C10.346 39.4465 10.8873 39.6925 11.1646 40.1619L13.4623 44.052C13.996 44.9557 15.3261 44.8845 15.7603 43.929L21.8412 30.5484C22.1376 29.8962 21.8492 29.1271 21.1969 28.8306L11.7484 24.5367Z"
        fill="url(#paint1_linear_359_3235)"
      />
    </g>
    <g filter="url(#filter2_ii_359_3235)">
      <path
        d="M37.6446 25.1811C37.3482 24.5288 36.5791 24.2403 35.9269 24.5367L26.4784 28.8306C25.8261 29.1271 25.5377 29.8962 25.8341 30.5484L31.915 43.929C32.3492 44.8845 33.6793 44.9557 34.213 44.052L36.5107 40.1619C36.788 39.6925 37.3293 39.4465 37.8653 39.5463L42.3069 40.3737C43.3387 40.5659 44.1598 39.5171 43.7255 38.5616L37.6446 25.1811Z"
        fill="#D9D9D9"
      />
      <path
        d="M37.6446 25.1811C37.3482 24.5288 36.5791 24.2403 35.9269 24.5367L26.4784 28.8306C25.8261 29.1271 25.5377 29.8962 25.8341 30.5484L31.915 43.929C32.3492 44.8845 33.6793 44.9557 34.213 44.052L36.5107 40.1619C36.788 39.6925 37.3293 39.4465 37.8653 39.5463L42.3069 40.3737C43.3387 40.5659 44.1598 39.5171 43.7255 38.5616L37.6446 25.1811Z"
        fill="url(#paint2_linear_359_3235)"
      />
    </g>
    <g filter="url(#filter3_ii_359_3235)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5321 28.9487L35.9805 24.6548C36.5676 24.3881 37.2598 24.6477 37.5265 25.2347L43.6074 38.6153C43.9982 39.4753 43.2593 40.4192 42.3307 40.2462L37.8891 39.4188C37.2994 39.309 36.704 39.5795 36.399 40.096L34.1013 43.9861C33.6209 44.7994 32.4239 44.7353 32.0331 43.8753L25.9522 30.4948C25.6854 29.9077 25.945 29.2155 26.5321 28.9487ZM35.9269 24.5367C36.5791 24.2403 37.3482 24.5288 37.6446 25.1811L43.7255 38.5616C44.1598 39.5171 43.3387 40.5659 42.3069 40.3737L37.8653 39.5463C37.3293 39.4465 36.788 39.6925 36.5107 40.1619L34.213 44.052C33.6793 44.9557 32.3492 44.8845 31.915 43.929L25.8341 30.5484C25.5377 29.8962 25.8261 29.1271 26.4784 28.8306L35.9269 24.5367Z"
        fill="url(#paint3_linear_359_3235)"
      />
    </g>
    <g filter="url(#filter4_bii_359_3235)">
      <path
        d="M8.75684 20.2703C8.75684 12.1204 15.3637 5.51355 23.5136 5.51355H24.4866C32.6365 5.51355 39.2433 12.1204 39.2433 20.2703C39.2433 28.4202 32.6365 35.0271 24.4866 35.0271H23.5136C15.3637 35.0271 8.75684 28.4202 8.75684 20.2703Z"
        fill="url(#paint4_linear_359_3235)"
        fillOpacity="0.4"
      />
    </g>
    <g filter="url(#filter5_bii_359_3235)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4866 5.64328H23.5136C15.4353 5.64328 8.88657 12.192 8.88657 20.2703C8.88657 28.3486 15.4353 34.8973 23.5136 34.8973H24.4866C32.5648 34.8973 39.1136 28.3486 39.1136 20.2703C39.1136 12.192 32.5648 5.64328 24.4866 5.64328ZM23.5136 5.51355C15.3637 5.51355 8.75684 12.1204 8.75684 20.2703C8.75684 28.4202 15.3637 35.0271 23.5136 35.0271H24.4866C32.6365 35.0271 39.2433 28.4202 39.2433 20.2703C39.2433 12.1204 32.6365 5.51355 24.4866 5.51355H23.5136Z"
        fill="url(#paint5_linear_359_3235)"
      />
    </g>
    <g filter="url(#filter6_d_359_3235)">
      <path
        d="M22.9284 12.2297C23.3168 11.0344 25.0077 11.0344 25.396 12.2297L26.5287 15.7157C26.7024 16.2503 27.2005 16.6122 27.7625 16.6122H31.428C32.6847 16.6122 33.2073 18.2203 32.1906 18.959L29.2251 21.1135C28.7704 21.4438 28.5802 22.0294 28.7539 22.5639L29.8865 26.05C30.2749 27.2452 28.9069 28.2391 27.8902 27.5004L24.9248 25.3459C24.4701 25.0156 23.8544 25.0156 23.3997 25.3459L20.4343 27.5004C19.4176 28.2391 18.0496 27.2452 18.4379 26.05L19.5706 22.5639C19.7443 22.0294 19.5541 21.4439 19.0994 21.1135L16.1339 18.959C15.1172 18.2203 15.6397 16.6122 16.8965 16.6122H20.5619C21.124 16.6122 21.6221 16.2503 21.7957 15.7157L22.9284 12.2297Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter7_d_359_3235)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4054 15.7558L25.2727 12.2698C24.9232 11.1941 23.4013 11.1941 23.0518 12.2698L21.9191 15.7558C21.7281 16.3438 21.1802 16.7419 20.5619 16.7419H16.8965C15.7654 16.7419 15.2951 18.1892 16.2102 18.854L19.1756 21.0085C19.6758 21.3719 19.8851 22.016 19.694 22.604L18.5613 26.0901C18.2118 27.1658 19.443 28.0603 20.358 27.3955L23.3235 25.241C23.8236 24.8776 24.5009 24.8776 25.001 25.241L27.9665 27.3955C28.8815 28.0603 30.1127 27.1658 29.7632 26.0901L28.6305 22.604C28.4394 22.016 28.6487 21.3719 29.1489 21.0085L32.1143 18.854C33.0293 18.1892 32.5591 16.7419 31.428 16.7419H27.7625C27.1443 16.7419 26.5964 16.3438 26.4054 15.7558ZM25.396 12.2297C25.0077 11.0344 23.3168 11.0344 22.9284 12.2297L21.7957 15.7157C21.6221 16.2503 21.124 16.6122 20.5619 16.6122H16.8965C15.6397 16.6122 15.1172 18.2203 16.1339 18.959L19.0994 21.1135C19.5541 21.4438 19.7443 22.0294 19.5706 22.5639L18.4379 26.05C18.0496 27.2452 19.4176 28.2391 20.4343 27.5004L23.3997 25.3459C23.8544 25.0156 24.4701 25.0156 24.9248 25.3459L27.8902 27.5004C28.9069 28.2391 30.2749 27.2452 29.8865 26.05L28.7539 22.5639C28.5802 22.0294 28.7704 21.4438 29.2251 21.1135L32.1906 18.959C33.2073 18.2203 32.6847 16.6122 31.428 16.6122H27.7625C27.2005 16.6122 26.7024 16.2503 26.5287 15.7157L25.396 12.2297Z"
        fill="url(#paint6_linear_359_3235)"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_ii_359_3235"
        x="1.83105"
        y="22.4202"
        width="22.1267"
        height="24.2694"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3235"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3235"
          result="effect2_innerShadow_359_3235"
        />
      </filter>
      <filter
        id="filter1_ii_359_3235"
        x="1.83105"
        y="22.4202"
        width="22.1267"
        height="24.2694"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3235"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3235"
          result="effect2_innerShadow_359_3235"
        />
      </filter>
      <filter
        id="filter2_ii_359_3235"
        x="23.7175"
        y="22.4202"
        width="22.1267"
        height="24.2694"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3235"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3235"
          result="effect2_innerShadow_359_3235"
        />
      </filter>
      <filter
        id="filter3_ii_359_3235"
        x="23.7175"
        y="22.4202"
        width="22.1267"
        height="24.2694"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3235"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3235"
          result="effect2_innerShadow_359_3235"
        />
      </filter>
      <filter
        id="filter4_bii_359_3235"
        x="0.756836"
        y="-2.48645"
        width="46.4866"
        height="45.5135"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3235"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3235"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3235"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3235"
          result="effect3_innerShadow_359_3235"
        />
      </filter>
      <filter
        id="filter5_bii_359_3235"
        x="0.756836"
        y="-2.48645"
        width="46.4866"
        height="45.5135"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3235"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3235"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3235"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3235"
          result="effect3_innerShadow_359_3235"
        />
      </filter>
      <filter
        id="filter6_d_359_3235"
        x="13.5967"
        y="10.3333"
        width="21.1311"
        height="20.4208"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3235"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3235"
          result="shape"
        />
      </filter>
      <filter
        id="filter7_d_359_3235"
        x="13.5967"
        y="10.3333"
        width="21.1311"
        height="20.4208"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3235"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3235"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_359_3235"
        x1="10.3789"
        y1="25.4373"
        x2="22.7397"
        y2="33.0047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#3787FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_359_3235"
        x1="36.0959"
        y1="-38.6065"
        x2="52.6507"
        y2="24.9489"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_359_3235"
        x1="37.2964"
        y1="25.4373"
        x2="24.9355"
        y2="33.0047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#3787FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_359_3235"
        x1="11.5794"
        y1="-38.6065"
        x2="-4.97538"
        y2="24.9489"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_359_3235"
        x1="24.0001"
        y1="6.64404"
        x2="24.0001"
        y2="36.6712"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#0061FF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_359_3235"
        x1="5.44309"
        y1="2.8305"
        x2="24.7908"
        y2="43.4388"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_359_3235"
        x1="24.1622"
        y1="8.43241"
        x2="24.1622"
        y2="26.8468"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
