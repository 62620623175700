export const FinalProvisions = () => (
  <div>
    <div className="privacy_content_title">Заключительные положения</div>
    <div className="privacy_content" style={{ paddingBottom: 84 }}>
      <div>
        Передача информации личного характера при посещении сторонних Сайтов,
        включая Сайты компаний-партнеров, даже если веб-Сайт содержит ссылку на
        Сайт <a href="https://www.dors.online">www.dors.online</a> или на Сайте{' '}
        <a href="https://www.dors.online">www.dors.online</a> есть ссылка на эти
        веб-Сайты, не подпадает под действия данного документа.
      </div>
      <div>
        Администрация Сайт <a href="https://www.dors.online">www.dors.online</a>{' '}
        не несет ответственности за действия других веб-Сайтов. Процесс сбора и
        передачи информации личного характера при посещении этих Сайтов
        регламентируется документом «Защита информации личного характера» или
        аналогичным, расположенном на Сайтах этих компаний.
      </div>
      <div>
        Актуальная версия Политики в свободном доступе расположена в сети
        Интернет по адресу{' '}
        <a href="https://www.dors.online/#/privacy">www.dors.online/privacy</a>
      </div>
    </div>
  </div>
)
