import { ComputerSvgDark } from "./dark/ComputerSvgDark";
import { ComputerSvgLight } from "./light/ComputerSvgLight";

interface Props {
  /** Цветовые схемы черная - dark | синяя - primary | белая - light */
  type?: 'dark' | 'light'
}
export const ComputerSvg: React.FC<Props> = ({ type }) => {
  switch (type) {
    case 'dark':
      return <ComputerSvgDark />
    default:
      return <ComputerSvgLight />
  }
}
