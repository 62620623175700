import React, { useContext } from 'react'
import { H2 } from '../../ui/H/H'
import { Inner } from '../../layout/Inner/Inner'
import { Btn } from '../../ui/Btn/Btn'
import { LayoutContext } from '../../../contexts/LayoutContext/LayoutContext'
import './style.scss'
import { MegafonSvg } from '../../../svg/MegafonSvg'
import { InfoTabletSvg } from '../../../svg/InfoTabletSvg'
import { ComputerSvg } from '../../../svg/ComputerSvg'
import { ListSvg } from '../../../svg/ListSvg'
import { ClockSvg } from '../../../svg/ClockSvg'
import { AnalyticsSvg } from '../../../svg/AnalyticsSvg'

export const Possibilities: React.FC = () => {
  const LayoutState = useContext(LayoutContext)
  return (
    <div className="possibilities">
      <Inner mod="possibilities">
        <H2 mod="possibilities">Возможности сервиса DORS&nbsp;Мультипостинг</H2>
        <div className="possibilities__content">
          {data.map((item, index) =>
            <div key={index} className="possibilities__item">
              <div className="possibilities__svg">{item.svg}</div>
              <div className="possibilities__name">{item.text}</div>
            </div>
          )}
        </div>
        <div className="possibilities__foot">
          <Btn
            onClick={() => LayoutState?.setPopupIsOpen(true)}
            mod="possibilities"
          >Отправить запрос на демо</Btn>
        </div>
      </Inner>
    </div>
  )
}

const data = [
  { svg: <MegafonSvg />, text: 'Массовая публикация вакансий сразу на нескольких сайтах по поиску работы ' },
  { svg: <InfoTabletSvg />, text: 'Мгновенное уведомление рекрутеров об ошибках при публикации вакансий ' },
  { svg: <ComputerSvg />, text: 'Работа в одном личном кабинете вашей CRM/ATS, вместо нескольких рабочих сайтов' },
  { svg: <ListSvg />, text: 'Одна форма размещения обьявления для всех необходимых сайтов по поиску работы' },
  { svg: <ClockSvg />, text: 'Автоматический сбор откликов каждые 15 минут ' },
  { svg: <AnalyticsSvg />, text: 'Возможность аналитики данных по эффективности использования работных сайтов' }
]
