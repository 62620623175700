export const CickStarter = () => (
  <svg
    width="148"
    height="148"
    viewBox="0 0 148 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.5 98V63.5C65.5 59.3579 62.1421 56 58 56V56C53.8579 56 50.5 59.3579 50.5 63.5V63.5C50.5 67.6421 53.8579 71 58 71H89C93.1421 71 96.5 67.6421 96.5 63.5V63.25C96.5 59.2459 93.2541 56 89.25 56V56C85.2459 56 82 59.2459 82 63.25V98"
      stroke="#E0E0E0"
      strokeWidth="4"
    />
    <g filter="url(#filter0_dii_359_3317)">
      <path
        d="M56.5 107C54.0147 107 52 104.985 52 102.5C52 100.015 54.0147 98 56.5 98H90.5C92.9853 98 95 100.015 95 102.5C95 104.985 92.9853 107 90.5 107C92.9853 107 95 109.015 95 111.5C95 113.985 92.9853 116 90.5 116C92.9853 116 95 118.015 95 120.5C95 122.985 92.9853 125 90.5 125H89C89 131.627 83.6274 137 77 137H70C63.3726 137 58 131.627 58 125H56.5C54.0147 125 52 122.985 52 120.5C52 118.015 54.0147 116 56.5 116C54.0147 116 52 113.985 52 111.5C52 109.015 54.0147 107 56.5 107Z"
        fill="#E0E0E0"
      />
      <path
        d="M56.5 107C54.0147 107 52 104.985 52 102.5C52 100.015 54.0147 98 56.5 98H90.5C92.9853 98 95 100.015 95 102.5C95 104.985 92.9853 107 90.5 107C92.9853 107 95 109.015 95 111.5C95 113.985 92.9853 116 90.5 116C92.9853 116 95 118.015 95 120.5C95 122.985 92.9853 125 90.5 125H89C89 131.627 83.6274 137 77 137H70C63.3726 137 58 131.627 58 125H56.5C54.0147 125 52 122.985 52 120.5C52 118.015 54.0147 116 56.5 116C54.0147 116 52 113.985 52 111.5C52 109.015 54.0147 107 56.5 107Z"
        fill="url(#paint0_linear_359_3317)"
      />
      <path
        d="M89 124.8H88.8V125C88.8 131.517 83.517 136.8 77 136.8H70C63.483 136.8 58.2 131.517 58.2 125V124.8H58H56.5C54.1252 124.8 52.2 122.875 52.2 120.5C52.2 118.125 54.1252 116.2 56.5 116.2V116V115.8C54.1252 115.8 52.2 113.875 52.2 111.5C52.2 109.125 54.1252 107.2 56.5 107.2V107V106.8C54.1252 106.8 52.2 104.875 52.2 102.5C52.2 100.125 54.1252 98.2 56.5 98.2H90.5C92.8748 98.2 94.8 100.125 94.8 102.5C94.8 104.875 92.8748 106.8 90.5 106.8V107V107.2C92.8748 107.2 94.8 109.125 94.8 111.5C94.8 113.875 92.8748 115.8 90.5 115.8V116V116.2C92.8748 116.2 94.8 118.125 94.8 120.5C94.8 122.875 92.8748 124.8 90.5 124.8H89Z"
        stroke="url(#paint1_linear_359_3317)"
        strokeWidth="0.4"
      />
    </g>
    <g filter="url(#filter1_bii_359_3317)">
      <mask id="path-4-inside-1_359_3317" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99 93.6825C99 90.0662 100.71 86.7138 103.172 84.0648C109.891 76.8357 114 67.1477 114 56.5C114 34.1325 95.8675 16 73.5 16C51.1325 16 33 34.1325 33 56.5C33 67.1477 37.109 76.8357 43.8277 84.0648C46.2896 86.7138 48 90.0662 48 93.6825V96C48 102.627 53.3726 108 60 108H87C93.6274 108 99 102.627 99 96V93.6825Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99 93.6825C99 90.0662 100.71 86.7138 103.172 84.0648C109.891 76.8357 114 67.1477 114 56.5C114 34.1325 95.8675 16 73.5 16C51.1325 16 33 34.1325 33 56.5C33 67.1477 37.109 76.8357 43.8277 84.0648C46.2896 86.7138 48 90.0662 48 93.6825V96C48 102.627 53.3726 108 60 108H87C93.6274 108 99 102.627 99 96V93.6825Z"
        fill="url(#paint2_linear_359_3317)"
        fillOpacity="0.24"
      />
      <path
        d="M103.172 84.0648L103.465 84.3371L103.172 84.0648ZM113.6 56.5C113.6 67.0427 109.532 76.6344 102.879 83.7925L103.465 84.3371C110.25 77.0369 114.4 67.2526 114.4 56.5H113.6ZM73.5 16.4C95.6466 16.4 113.6 34.3534 113.6 56.5H114.4C114.4 33.9116 96.0884 15.6 73.5 15.6V16.4ZM33.4 56.5C33.4 34.3534 51.3534 16.4 73.5 16.4V15.6C50.9116 15.6 32.6 33.9116 32.6 56.5H33.4ZM44.1207 83.7925C37.468 76.6344 33.4 67.0427 33.4 56.5H32.6C32.6 67.2526 36.7499 77.0369 43.5347 84.3371L44.1207 83.7925ZM48.4 96V93.6825H47.6V96H48.4ZM87 107.6H60V108.4H87V107.6ZM98.6 93.6825V96H99.4V93.6825H98.6ZM47.6 96C47.6 102.848 53.1517 108.4 60 108.4V107.6C53.5935 107.6 48.4 102.407 48.4 96H47.6ZM43.5347 84.3371C45.9492 86.935 47.6 90.1947 47.6 93.6825H48.4C48.4 89.9376 46.6301 86.4925 44.1207 83.7925L43.5347 84.3371ZM87 108.4C93.8483 108.4 99.4 102.848 99.4 96H98.6C98.6 102.407 93.4065 107.6 87 107.6V108.4ZM102.879 83.7925C100.37 86.4925 98.6 89.9376 98.6 93.6825H99.4C99.4 90.1947 101.051 86.935 103.465 84.3371L102.879 83.7925Z"
        fill="url(#paint3_linear_359_3317)"
        mask="url(#path-4-inside-1_359_3317)"
      />
    </g>
    <g filter="url(#filter2_d_359_3317)">
      <rect
        x="36"
        y="22.8331"
        width="9.66355"
        height="20.2561"
        rx="4.83177"
        transform="rotate(-45 36 22.8331)"
        fill="#E0E0E0"
      />
      <rect
        x="36.2828"
        y="22.8331"
        width="9.26355"
        height="19.8561"
        rx="4.63177"
        transform="rotate(-45 36.2828 22.8331)"
        stroke="url(#paint4_linear_359_3317)"
        strokeWidth="0.4"
      />
    </g>
    <g filter="url(#filter3_d_359_3317)">
      <rect
        x="69"
        y="6"
        width="9.66355"
        height="20.2561"
        rx="4.83177"
        fill="#E0E0E0"
      />
      <rect
        x="69.2"
        y="6.2"
        width="9.26355"
        height="19.8561"
        rx="4.63177"
        stroke="url(#paint5_linear_359_3317)"
        strokeWidth="0.4"
      />
    </g>
    <g filter="url(#filter4_d_359_3317)">
      <rect
        width="9.66355"
        height="20.2561"
        rx="4.83177"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 110.156 22.8331)"
        fill="#E0E0E0"
      />
      <rect
        x="-0.282843"
        width="9.26355"
        height="19.8561"
        rx="4.63177"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 109.673 22.6331)"
        stroke="url(#paint6_linear_359_3317)"
        strokeWidth="0.4"
      />
    </g>
    <g filter="url(#filter5_d_359_3317)">
      <rect
        x="25"
        y="53.4086"
        width="9.66355"
        height="20.2561"
        rx="4.83177"
        transform="rotate(-76.8084 25 53.4086)"
        fill="#E0E0E0"
      />
      <rect
        x="25.2404"
        y="53.2595"
        width="9.26355"
        height="19.8561"
        rx="4.63177"
        transform="rotate(-76.8084 25.2404 53.2595)"
        stroke="url(#paint7_linear_359_3317)"
        strokeWidth="0.4"
      />
    </g>
    <g filter="url(#filter6_d_359_3317)">
      <rect
        width="9.66355"
        height="20.2561"
        rx="4.83177"
        transform="matrix(-0.228208 -0.973612 -0.973612 0.228208 121.156 53.4086)"
        fill="#E0E0E0"
      />
      <rect
        x="-0.240364"
        y="-0.149081"
        width="9.26355"
        height="19.8561"
        rx="4.63177"
        transform="matrix(-0.228208 -0.973612 -0.973612 0.228208 120.716 53.0595)"
        stroke="url(#paint8_linear_359_3317)"
        strokeWidth="0.4"
      />
    </g>
    <defs>
      <filter
        id="filter0_dii_359_3317"
        x="50"
        y="96"
        width="47"
        height="44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3317"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3317"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3317"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3317"
          result="effect3_innerShadow_359_3317"
        />
      </filter>
      <filter
        id="filter1_bii_359_3317"
        x="25"
        y="8"
        width="97"
        height="108"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3317"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3317"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3317"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3317"
          result="effect3_innerShadow_359_3317"
        />
      </filter>
      <filter
        id="filter2_d_359_3317"
        x="36.0015"
        y="17.0013"
        width="21.1536"
        height="21.1536"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3317"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3317"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_359_3317"
        x="67"
        y="5"
        width="13.6636"
        height="24.2561"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3317"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3317"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_359_3317"
        x="89.0012"
        y="17.0013"
        width="21.1536"
        height="21.1536"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3317"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3317"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_d_359_3317"
        x="23.9741"
        y="43.9741"
        width="23.9788"
        height="16.083"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3317"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3317"
          result="shape"
        />
      </filter>
      <filter
        id="filter6_d_359_3317"
        x="98.2034"
        y="43.9741"
        width="23.9788"
        height="16.083"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3317"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3317"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_359_3317"
        x1="53.4022"
        y1="100.925"
        x2="100.052"
        y2="109.971"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#3787FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_359_3317"
        x1="18"
        y1="46"
        x2="83"
        y2="139"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_359_3317"
        x1="73.5"
        y1="19.524"
        x2="73.5"
        y2="113.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_359_3317"
        x1="24.1957"
        y1="7.63636"
        x2="90.3475"
        y2="125.978"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_359_3317"
        x1="40.8318"
        y1="22.8331"
        x2="40.8318"
        y2="38.5879"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_359_3317"
        x1="73.8318"
        y1="6"
        x2="73.8318"
        y2="21.7547"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_359_3317"
        x1="4.83177"
        y1="0"
        x2="4.83177"
        y2="15.7547"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_359_3317"
        x1="29.8318"
        y1="53.4086"
        x2="29.8318"
        y2="69.1633"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_359_3317"
        x1="4.83177"
        y1="0"
        x2="4.83177"
        y2="15.7547"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
