export const RightArrowSvg = () => (
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.56569 5.43431C6.8781 5.74673 6.8781 6.25327 6.56569 6.56569L1.47452 11.6569C1.1621 11.9693 0.655565 11.9693 0.343146 11.6569C0.0307264 11.3444 0.0307264 10.8379 0.343146 10.5255L4.86863 6L0.343146 1.47452C0.0307264 1.1621 0.0307264 0.655565 0.343146 0.343146C0.655565 0.0307264 1.1621 0.0307264 1.47452 0.343146L6.56569 5.43431ZM5 5.2H6V6.8H5V5.2Z"
      fill="#005EF5"
    />
  </svg>
);
