export const IternalMarketingSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_359_3276)">
      <path
        d="M8.10815 28.5406C8.10815 27.4658 8.97938 26.5946 10.0541 26.5946C11.1288 26.5946 12 27.4658 12 28.5406V38.9189C12 39.9936 11.1288 40.8649 10.0541 40.8649C8.97938 40.8649 8.10815 39.9936 8.10815 38.9189V28.5406Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter1_d_359_3276)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8703 38.9189V28.5406C11.8703 27.5375 11.0572 26.7243 10.0541 26.7243C9.05103 26.7243 8.23788 27.5375 8.23788 28.5406V38.9189C8.23788 39.922 9.05103 40.7351 10.0541 40.7351C11.0572 40.7351 11.8703 39.922 11.8703 38.9189ZM10.0541 26.5946C8.97938 26.5946 8.10815 27.4658 8.10815 28.5406V38.9189C8.10815 39.9936 8.97938 40.8649 10.0541 40.8649C11.1288 40.8649 12 39.9936 12 38.9189V28.5406C12 27.4658 11.1288 26.5946 10.0541 26.5946Z"
        fill="url(#paint0_linear_359_3276)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter2_dii_359_3276)">
      <path
        d="M4.21631 20.7568C4.21631 18.6073 5.95877 16.8649 8.1082 16.8649H15.892V28.5405H8.1082C5.95877 28.5405 4.21631 26.7981 4.21631 24.6487V20.7568Z"
        fill="url(#paint1_linear_359_3276)"
      />
    </g>
    <g filter="url(#filter3_dii_359_3276)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7623 16.9946H8.1082C6.03042 16.9946 4.34604 18.679 4.34604 20.7568V24.6487C4.34604 26.7264 6.03042 28.4108 8.1082 28.4108H15.7623V16.9946ZM8.1082 16.8649C5.95877 16.8649 4.21631 18.6073 4.21631 20.7568V24.6487C4.21631 26.7981 5.95877 28.5405 8.1082 28.5405H15.892V16.8649H8.1082Z"
        fill="url(#paint2_linear_359_3276)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter4_dii_359_3276)">
      <path
        d="M30.4866 11.8378C30.4866 10.4944 31.5756 9.4054 32.919 9.4054C34.2624 9.4054 35.3514 10.4944 35.3514 11.8378V33.8919C35.3514 35.2353 34.2624 36.3243 32.919 36.3243C31.5756 36.3243 30.4866 35.2353 30.4866 33.8919V11.8378Z"
        fill="url(#paint3_linear_359_3276)"
      />
    </g>
    <g filter="url(#filter5_dii_359_3276)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.2217 33.8919V11.8378C35.2217 10.5661 34.1908 9.53513 32.919 9.53513C31.6473 9.53513 30.6163 10.5661 30.6163 11.8378V33.8919C30.6163 35.1636 31.6473 36.1946 32.919 36.1946C34.1908 36.1946 35.2217 35.1636 35.2217 33.8919ZM32.919 9.4054C31.5756 9.4054 30.4866 10.4944 30.4866 11.8378V33.8919C30.4866 35.2353 31.5756 36.3243 32.919 36.3243C34.2624 36.3243 35.3514 35.2353 35.3514 33.8919V11.8378C35.3514 10.4944 34.2624 9.4054 32.919 9.4054Z"
        fill="url(#paint4_linear_359_3276)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter6_bii_359_3276)">
      <path
        d="M13.2974 17.859C13.2974 16.5501 14.2723 15.4461 15.5712 15.2843L19.5443 14.7893C21.4443 14.5525 23.3023 14.0541 25.0656 13.3081L29.1513 11.5795C30.862 10.8558 32.7568 12.1115 32.7568 13.969V31.4364C32.7568 33.2939 30.862 34.5497 29.1513 33.826L25.0656 32.0974C23.3023 31.3514 21.4443 30.8529 19.5443 30.6162L15.5712 30.1212C14.2723 29.9593 13.2974 28.8554 13.2974 27.5465V17.859Z"
        fill="url(#paint5_linear_359_3276)"
        fillOpacity="0.4"
      />
    </g>
    <g filter="url(#filter7_bii_359_3276)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6271 31.4364V13.969C32.6271 12.2044 30.827 11.0114 29.2018 11.699L25.1162 13.4275C23.3418 14.1782 21.4722 14.6798 19.5603 14.918L15.5872 15.413C14.3533 15.5668 13.4271 16.6155 13.4271 17.859V27.5465C13.4271 28.7899 14.3533 29.8387 15.5872 29.9924L19.5604 30.4875C21.4722 30.7257 23.3418 31.2273 25.1162 31.9779L29.2018 33.7065C30.827 34.3941 32.6271 33.2011 32.6271 31.4364ZM15.5712 15.2843C14.2723 15.4461 13.2974 16.5501 13.2974 17.859V27.5465C13.2974 28.8554 14.2723 29.9593 15.5712 30.1212L19.5443 30.6162C21.4443 30.8529 23.3023 31.3514 25.0656 32.0974L29.1513 33.826C30.862 34.5497 32.7568 33.2939 32.7568 31.4364V13.969C32.7568 12.1115 30.862 10.8558 29.1513 11.5795L25.0656 13.3081C23.3023 14.0541 21.4443 14.5525 19.5443 14.7893L15.5712 15.2843Z"
        fill="url(#paint6_linear_359_3276)"
      />
    </g>
    <g filter="url(#filter8_d_359_3276)">
      <path
        d="M38.2157 24.2151C37.3503 24.2151 36.6487 23.5135 36.6487 22.648C36.6487 21.7826 37.3503 21.081 38.2157 21.081H41.6512C42.5166 21.081 43.2182 21.7826 43.2182 22.648C43.2182 23.5135 42.5166 24.2151 41.6512 24.2151H38.2157Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter9_d_359_3276)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.6512 21.2107H38.2157C37.4219 21.2107 36.7784 21.8542 36.7784 22.648C36.7784 23.4418 37.4219 24.0854 38.2157 24.0854H41.6512C42.445 24.0854 43.0885 23.4418 43.0885 22.648C43.0885 21.8542 42.445 21.2107 41.6512 21.2107ZM36.6487 22.648C36.6487 23.5135 37.3503 24.2151 38.2157 24.2151H41.6512C42.5166 24.2151 43.2182 23.5135 43.2182 22.648C43.2182 21.7826 42.5166 21.081 41.6512 21.081H38.2157C37.3503 21.081 36.6487 21.7826 36.6487 22.648Z"
        fill="url(#paint7_linear_359_3276)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter10_d_359_3276)">
      <path
        d="M39.2389 16.4346C38.4913 16.8706 37.5318 16.6179 37.0958 15.8703C36.6598 15.1227 36.9124 14.1632 37.66 13.7272L39.6571 12.5625C40.4047 12.1265 41.3643 12.3791 41.8003 13.1268C42.2363 13.8744 41.9836 14.8339 41.236 15.2699L39.2389 16.4346Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter11_d_359_3276)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7225 12.6746L37.7254 13.8393C37.0397 14.2392 36.808 15.1192 37.2079 15.805C37.6078 16.4907 38.4879 16.7224 39.1736 16.3225L41.1707 15.1578C41.8564 14.7579 42.0881 13.8778 41.6882 13.1921C41.2883 12.5064 40.4082 12.2747 39.7225 12.6746ZM37.0958 15.8703C37.5318 16.6179 38.4913 16.8706 39.2389 16.4346L41.236 15.2699C41.9836 14.8339 42.2363 13.8744 41.8003 13.1268C41.3643 12.3791 40.4047 12.1265 39.6571 12.5625L37.66 13.7272C36.9124 14.1632 36.6598 15.1227 37.0958 15.8703Z"
        fill="url(#paint8_linear_359_3276)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter12_d_359_3276)">
      <path
        d="M39.2389 28.6813C38.4913 28.2453 37.5318 28.4979 37.0958 29.2455C36.6598 29.9931 36.9124 30.9527 37.66 31.3887L39.6571 32.5533C40.4047 32.9893 41.3643 32.7367 41.8003 31.9891C42.2363 31.2415 41.9836 30.2819 41.236 29.846L39.2389 28.6813Z"
        fill="#E0E0E0"
      />
    </g>
    <g filter="url(#filter13_d_359_3276)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7225 32.4413L37.7254 31.2766C37.0397 30.8767 36.808 29.9966 37.2079 29.3109C37.6078 28.6252 38.4879 28.3935 39.1736 28.7934L41.1707 29.958C41.8564 30.3579 42.0881 31.238 41.6882 31.9237C41.2883 32.6095 40.4082 32.8412 39.7225 32.4413ZM37.0958 29.2455C37.5318 28.4979 38.4913 28.2453 39.2389 28.6813L41.236 29.846C41.9836 30.2819 42.2363 31.2415 41.8003 31.9891C41.3643 32.7367 40.4047 32.9893 39.6571 32.5533L37.66 31.3887C36.9124 30.9527 36.6598 29.9931 37.0958 29.2455Z"
        fill="url(#paint9_linear_359_3276)"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_359_3276"
        x="6.10815"
        y="25.5946"
        width="7.89185"
        height="18.2703"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_359_3276"
        x="6.10815"
        y="25.5946"
        width="7.89185"
        height="18.2703"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_dii_359_3276"
        x="2.21631"
        y="14.8649"
        width="15.6758"
        height="16.6757"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3276"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3276"
          result="effect3_innerShadow_359_3276"
        />
      </filter>
      <filter
        id="filter3_dii_359_3276"
        x="2.21631"
        y="14.8649"
        width="15.6758"
        height="16.6757"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3276"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3276"
          result="effect3_innerShadow_359_3276"
        />
      </filter>
      <filter
        id="filter4_dii_359_3276"
        x="28.4866"
        y="7.4054"
        width="8.86475"
        height="31.9189"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3276"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3276"
          result="effect3_innerShadow_359_3276"
        />
      </filter>
      <filter
        id="filter5_dii_359_3276"
        x="28.4866"
        y="7.4054"
        width="8.86475"
        height="31.9189"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3276"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3276"
          result="effect3_innerShadow_359_3276"
        />
      </filter>
      <filter
        id="filter6_bii_359_3276"
        x="5.29736"
        y="3.3717"
        width="35.4595"
        height="38.6621"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3276"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3276"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3276"
          result="effect3_innerShadow_359_3276"
        />
      </filter>
      <filter
        id="filter7_bii_359_3276"
        x="5.29736"
        y="3.3717"
        width="35.4595"
        height="38.6621"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3276"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3276"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3276"
          result="effect3_innerShadow_359_3276"
        />
      </filter>
      <filter
        id="filter8_d_359_3276"
        x="34.6487"
        y="20.0809"
        width="10.5696"
        height="7.13416"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
      </filter>
      <filter
        id="filter9_d_359_3276"
        x="34.6487"
        y="20.0809"
        width="10.5696"
        height="7.13416"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
      </filter>
      <filter
        id="filter10_d_359_3276"
        x="34.8821"
        y="11.3489"
        width="9.13184"
        height="8.29932"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
      </filter>
      <filter
        id="filter11_d_359_3276"
        x="34.8821"
        y="11.3489"
        width="9.13184"
        height="8.29932"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
      </filter>
      <filter
        id="filter12_d_359_3276"
        x="34.8821"
        y="27.4677"
        width="9.13184"
        height="8.29932"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
      </filter>
      <filter
        id="filter13_d_359_3276"
        x="34.8821"
        y="27.4677"
        width="9.13184"
        height="8.29932"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3276"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_359_3276"
        x1="10.0541"
        y1="26.5946"
        x2="10.0541"
        y2="37.6937"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_359_3276"
        x1="4.59704"
        y1="17.7405"
        x2="17.3457"
        y2="19.9826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#3787FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_359_3276"
        x1="-5.01562"
        y1="1.2973"
        x2="15.0216"
        y2="27.2991"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_359_3276"
        x1="30.6452"
        y1="11.4243"
        x2="36.1159"
        y2="11.5982"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#3787FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_359_3276"
        x1="26.6399"
        y1="-26.4865"
        x2="47.8797"
        y2="-21.5053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_359_3276"
        x1="23.0271"
        y1="11.0231"
        x2="23.0271"
        y2="36.7607"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#0061FF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_359_3276"
        x1="11.836"
        y1="12.059"
        x2="26.5072"
        y2="39.1592"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_359_3276"
        x1="36.6487"
        y1="22.648"
        x2="41.7583"
        y2="22.648"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_359_3276"
        x1="37.0958"
        y1="15.8703"
        x2="40.7548"
        y2="13.7364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_359_3276"
        x1="37.0958"
        y1="29.2455"
        x2="40.7548"
        y2="31.3794"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
