import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import './style.scss'

interface Props {
  link?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement | HTMLInputElement> | any
  mod?: string
  target?: '_blank'
  type?: 'submit' | 'button' | 'reset'
  disabled?: boolean
  children?: React.ReactNode
}
export const Btn: React.FC<Props> = ({
  link,
  onClick,
  mod,
  target,
  type,
  disabled,
  children
}) => {
  const modifiers = mod?.split(',').map((item) => item.trim())
  const baseName = 'ui-button'
  const className = classNames(baseName, modifiers?.map((item) => (item.startsWith('--') ? `${baseName}${item}` : `${item}__${baseName}`)))

  return (
    <>
      {link &&
        <Link
          className={className}
          onClick={onClick}
          to={link}
          target={target}
          >
            {children}
        </Link>
      }
      {!link &&
        <button className={className} onClick={onClick} type={type ?? 'button'} {...(disabled ? { disabled } : {})} >
          {children}
        </button>
      }
    </>
  )
}
