export const SettingSvgPrimary = () => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ii_359_3076)">
        <rect
          x="18.4324"
          y="13.0811"
          width="5.94595"
          height="61.8378"
          rx="2.97297"
          fill="url(#paint0_linear_359_3076)"
        />
        <rect
          x="18.4324"
          y="13.0811"
          width="5.94595"
          height="61.8378"
          rx="2.97297"
          fill="url(#paint1_linear_359_3076)"
        />
      </g>
      <rect
        x="18.6324"
        y="13.2811"
        width="5.54595"
        height="61.4378"
        rx="2.77297"
        stroke="url(#paint2_linear_359_3076)"
        strokeWidth="0.4"
      />
      <g filter="url(#filter1_ii_359_3076)">
        <rect
          x="41.0271"
          y="13.0811"
          width="5.94595"
          height="61.8378"
          rx="2.97297"
          fill="url(#paint3_linear_359_3076)"
        />
        <rect
          x="41.0271"
          y="13.0811"
          width="5.94595"
          height="61.8378"
          rx="2.97297"
          fill="url(#paint4_linear_359_3076)"
        />
      </g>
      <rect
        x="41.2271"
        y="13.2811"
        width="5.54595"
        height="61.4378"
        rx="2.77297"
        stroke="url(#paint5_linear_359_3076)"
        strokeWidth="0.4"
      />
      <g filter="url(#filter2_ii_359_3076)">
        <rect
          x="63.6216"
          y="13.0811"
          width="5.94595"
          height="61.8378"
          rx="2.97297"
          fill="url(#paint6_linear_359_3076)"
        />
        <rect
          x="63.6216"
          y="13.0811"
          width="5.94595"
          height="61.8378"
          rx="2.97297"
          fill="url(#paint7_linear_359_3076)"
        />
      </g>
      <rect
        x="63.8216"
        y="13.2811"
        width="5.54595"
        height="61.4378"
        rx="2.77297"
        stroke="url(#paint8_linear_359_3076)"
        strokeWidth="0.4"
      />
      <g filter="url(#filter3_bii_359_3076)">
        <rect
          x="11.8918"
          y="19.6216"
          width="19.027"
          height="19.027"
          rx="9.51351"
          fill="url(#paint9_linear_359_3076)"
          fillOpacity="0.24"
        />
        <rect
          x="12.0918"
          y="19.8216"
          width="18.627"
          height="18.627"
          rx="9.31351"
          stroke="url(#paint10_linear_359_3076)"
          strokeWidth="0.4"
        />
      </g>
      <g filter="url(#filter4_bii_359_3076)">
        <rect
          x="57.0811"
          y="19.6216"
          width="19.027"
          height="19.027"
          rx="9.51351"
          fill="url(#paint11_linear_359_3076)"
          fillOpacity="0.24"
        />
        <rect
          x="57.2811"
          y="19.8216"
          width="18.627"
          height="18.627"
          rx="9.31351"
          stroke="url(#paint12_linear_359_3076)"
          strokeWidth="0.4"
        />
      </g>
      <g filter="url(#filter5_d_359_3076)">
        <rect
          x="35.0811"
          y="49.3513"
          width="17.8378"
          height="17.8378"
          rx="8.91892"
          fill="white"
        />
        <rect
          x="35.2811"
          y="49.5513"
          width="17.4378"
          height="17.4378"
          rx="8.71892"
          stroke="url(#paint13_linear_359_3076)"
          strokeWidth="0.4"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_359_3076"
          x="16.4324"
          y="11.0811"
          width="9.94604"
          height="65.8379"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_359_3076"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_359_3076"
            result="effect2_innerShadow_359_3076"
          />
        </filter>
        <filter
          id="filter1_ii_359_3076"
          x="39.0271"
          y="11.0811"
          width="9.94604"
          height="65.8379"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_359_3076"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_359_3076"
            result="effect2_innerShadow_359_3076"
          />
        </filter>
        <filter
          id="filter2_ii_359_3076"
          x="61.6216"
          y="11.0811"
          width="9.94604"
          height="65.8379"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_359_3076"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_359_3076"
            result="effect2_innerShadow_359_3076"
          />
        </filter>
        <filter
          id="filter3_bii_359_3076"
          x="3.89185"
          y="11.6216"
          width="35.0271"
          height="35.027"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_359_3076"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_359_3076"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_359_3076"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_359_3076"
            result="effect3_innerShadow_359_3076"
          />
        </filter>
        <filter
          id="filter4_bii_359_3076"
          x="49.0811"
          y="11.6216"
          width="35.0271"
          height="35.027"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_359_3076"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_359_3076"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_359_3076"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_359_3076"
            result="effect3_innerShadow_359_3076"
          />
        </filter>
        <filter
          id="filter5_d_359_3076"
          x="33.0811"
          y="48.3513"
          width="21.8379"
          height="21.8379"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_359_3076"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_359_3076"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_359_3076"
          x1="18.6263"
          y1="17.7189"
          x2="25.3175"
          y2="17.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005EF5" />
          <stop offset="1" stopColor="#3787FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_359_3076"
          x1="18.6263"
          y1="17.7189"
          x2="25.3175"
          y2="17.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E2E2E2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_359_3076"
          x1="21.4053"
          y1="13.0811"
          x2="21.4053"
          y2="61.1772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_359_3076"
          x1="41.221"
          y1="17.7189"
          x2="47.9123"
          y2="17.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005EF5" />
          <stop offset="1" stopColor="#3787FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_359_3076"
          x1="41.221"
          y1="17.7189"
          x2="47.9123"
          y2="17.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E2E2E2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_359_3076"
          x1="44.0001"
          y1="13.0811"
          x2="44.0001"
          y2="61.1772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_359_3076"
          x1="63.8155"
          y1="17.7189"
          x2="70.5067"
          y2="17.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005EF5" />
          <stop offset="1" stopColor="#3787FF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_359_3076"
          x1="63.8155"
          y1="17.7189"
          x2="70.5067"
          y2="17.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E2E2E2" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_359_3076"
          x1="66.5946"
          y1="13.0811"
          x2="66.5946"
          y2="61.1772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_359_3076"
          x1="21.4054"
          y1="20.3504"
          x2="21.4054"
          y2="39.7085"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_359_3076"
          x1="9.82369"
          y1="17.8919"
          x2="22.5504"
          y2="43.7509"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.765625" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_359_3076"
          x1="66.5946"
          y1="20.3504"
          x2="66.5946"
          y2="39.7085"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_359_3076"
          x1="55.0129"
          y1="17.8919"
          x2="67.7396"
          y2="43.7509"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.765625" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_359_3076"
          x1="44"
          y1="49.3513"
          x2="44"
          y2="63.2252"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
