export const CompositionOfInformation = () => (
  <div>
    <div className="privacy_content_title">Состав информации</div>
    <div className="privacy_content">
      <div>
        Оператор имеет право на обработку таких персональных данных о
        пользователях Сайта{' '}
        <a href="https://www.dors.online">www.dors.online</a>, как: Фамилия,
        Имя, контактный телефонный номер, адрес электронной почты, обезличенные
        данные из файлов cookie («куки») и иные дополнительные сведения, которые
        пользователи предпочли указать о себе в формах обратной связи, к
        примеру. При этом предоставляя Оператору дополнительные сведения о себе,
        пользователь осознаёт и даёт вместе с согласием на обработку тех
        персональных данных, которые действительно необходимы Оператору,
        согласие на сбор, обработку, хранение и последующее уничтожение
        дополнительно предоставленных сведений.
      </div>
    </div>
  </div>
)
