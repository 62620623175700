import React from 'react'
import {
  CollectionOfInformation,
  CompositionOfInformation,
  FinalProvisions,
  GeneralProvisions,
  LegalGroundsForTheProcessingOfPersonalData,
  MeasuresToEnsureTheSecurityOfPersonalData,
  PurposesOfPersonalDataProcessing,
  TermsAndDefinition,
  UseOfInformation
} from '../../components/common/Privacy'

import { Main } from '../../components/layout/Main/Main'
import { Inner } from '../../components/layout/Inner/Inner'
import './styles.scss'

export const PrivacyPage: React.FC = () => {
  return (
    <Main>
      <Inner mod="--max-w750">
        <div className="privacy_container">
          <div className="privacy_title">
            Согласие на обработку персональных данных
          </div>
          <GeneralProvisions />
          <TermsAndDefinition />
          <CompositionOfInformation />
          <PurposesOfPersonalDataProcessing />
          <CollectionOfInformation />
          <LegalGroundsForTheProcessingOfPersonalData />
          <UseOfInformation />
          <MeasuresToEnsureTheSecurityOfPersonalData />
          <FinalProvisions />
        </div>
      </Inner>
    </Main>
  )
}
