export const RequirementsSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_359_3176)">
      <g filter="url(#filter0_ii_359_3176)">
        <path
          d="M7.78369 15.8919C7.78369 12.2226 7.78369 10.3879 8.9236 9.248C10.0635 8.10809 11.8982 8.10809 15.5675 8.10809H32.4323C36.1017 8.10809 37.9363 8.10809 39.0762 9.248C40.2161 10.3879 40.2161 12.2226 40.2161 15.8919V31.7838C40.2161 35.4531 40.2161 37.2877 39.0762 38.4276C37.9363 39.5676 36.1017 39.5676 32.4323 39.5676H15.5675C11.8982 39.5676 10.0635 39.5676 8.9236 38.4276C7.78369 37.2877 7.78369 35.4531 7.78369 31.7838V15.8919Z"
          fill="url(#paint0_linear_359_3176)"
        />
      </g>
      <g filter="url(#filter1_ii_359_3176)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.4323 8.23782H15.5675C13.7292 8.23782 12.3635 8.2381 11.3143 8.37915C10.2689 8.51971 9.55717 8.7979 9.01533 9.33973C8.4735 9.88157 8.19531 10.5933 8.05475 11.6387C7.9137 12.6879 7.91342 14.0536 7.91342 15.8919V31.7838C7.91342 33.6221 7.9137 34.9878 8.05475 36.0369C8.19531 37.0824 8.4735 37.7941 9.01533 38.3359C9.55717 38.8777 10.2689 39.1559 11.3143 39.2965C12.3635 39.4375 13.7292 39.4378 15.5675 39.4378H32.4323C34.2707 39.4378 35.6364 39.4375 36.6855 39.2965C37.7309 39.1559 38.4426 38.8777 38.9845 38.3359C39.5263 37.7941 39.8045 37.0824 39.9451 36.0369C40.0861 34.9878 40.0864 33.6221 40.0864 31.7838V15.8919C40.0864 14.0536 40.0861 12.6879 39.9451 11.6387C39.8045 10.5933 39.5263 9.88157 38.9845 9.33973C38.4426 8.7979 37.7309 8.51971 36.6855 8.37915C35.6364 8.2381 34.2707 8.23782 32.4323 8.23782ZM8.9236 9.248C7.78369 10.3879 7.78369 12.2226 7.78369 15.8919V31.7838C7.78369 35.4531 7.78369 37.2877 8.9236 38.4276C10.0635 39.5676 11.8982 39.5676 15.5675 39.5676H32.4323C36.1017 39.5676 37.9363 39.5676 39.0762 38.4276C40.2161 37.2877 40.2161 35.4531 40.2161 31.7838V15.8919C40.2161 12.2226 40.2161 10.3879 39.0762 9.248C37.9363 8.10809 36.1017 8.10809 32.4323 8.10809H15.5675C11.8982 8.10809 10.0635 8.10809 8.9236 9.248Z"
          fill="url(#paint1_linear_359_3176)"
        />
      </g>
      <g filter="url(#filter2_bii_359_3176)">
        <path
          d="M7.62848 16.158C8.07638 12.5161 8.30033 10.6952 9.57085 9.70296C10.8414 8.71072 12.6623 8.93467 16.3042 9.38257L33.0429 11.4412C36.6848 11.8891 38.5057 12.113 39.498 13.3836C40.4902 14.6541 40.2663 16.475 39.8184 20.1169L37.6806 37.4994C37.2327 41.1413 37.0087 42.9623 35.7382 43.9545C34.4677 44.9467 32.6467 44.7228 29.0049 44.2749L12.2661 42.2163C8.62425 41.7684 6.80332 41.5444 5.81108 40.2739C4.81884 39.0034 5.04278 37.1824 5.49068 33.5406L7.62848 16.158Z"
          fill="url(#paint2_linear_359_3176)"
          fillOpacity="0.24"
        />
      </g>
      <g filter="url(#filter3_bii_359_3176)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.0271 11.5699L16.2884 9.51133C14.4638 9.28693 13.1083 9.1205 12.0497 9.13243C10.995 9.14433 10.2546 9.33356 9.6507 9.80521C9.04678 10.2768 8.6838 10.9493 8.41668 11.9697C8.14861 12.9938 7.98164 14.3493 7.75724 16.1738L5.61944 33.5564C5.39505 35.381 5.22861 36.7365 5.24055 37.795C5.25244 38.8498 5.44168 39.5901 5.91332 40.194C6.38496 40.798 7.05738 41.161 8.07784 41.4281C9.10194 41.6961 10.4574 41.8631 12.282 42.0875L29.0207 44.1461C30.8453 44.3705 32.2008 44.537 33.2593 44.525C34.3141 44.5131 35.0544 44.3239 35.6584 43.8523C36.2623 43.3806 36.6253 42.7082 36.8924 41.6877C37.1605 40.6636 37.3274 39.3082 37.5518 37.4836L39.6896 20.1011C39.914 18.2765 40.0805 16.921 40.0685 15.8624C40.0566 14.8077 39.8674 14.0673 39.3957 13.4634C38.9241 12.8595 38.2517 12.4965 37.2312 12.2294C36.2071 11.9613 34.8517 11.7943 33.0271 11.5699ZM9.57085 9.70296C8.30033 10.6952 8.07638 12.5161 7.62848 16.158L5.49068 33.5406C5.04279 37.1824 4.81884 39.0034 5.81108 40.2739C6.80332 41.5444 8.62425 41.7684 12.2661 42.2163L29.0049 44.2749C32.6467 44.7228 34.4677 44.9467 35.7382 43.9545C37.0087 42.9623 37.2327 41.1413 37.6806 37.4994L39.8184 20.1169C40.2663 16.475 40.4902 14.6541 39.498 13.3836C38.5057 12.113 36.6848 11.8891 33.0429 11.4412L16.3042 9.38257C12.6623 8.93467 10.8414 8.71072 9.57085 9.70296Z"
          fill="url(#paint3_linear_359_3176)"
        />
      </g>
      <g filter="url(#filter4_bii_359_3176)">
        <path
          d="M9.08105 18.4864C9.08105 14.8171 9.08105 12.9825 10.221 11.8425C11.3609 10.7026 13.1955 10.7026 16.8648 10.7026H33.7297C37.399 10.7026 39.2337 10.7026 40.3736 11.8425C41.5135 12.9825 41.5135 14.8171 41.5135 18.4864V35.9999C41.5135 39.6692 41.5135 41.5039 40.3736 42.6438C39.2337 43.7837 37.399 43.7837 33.7297 43.7837H16.8648C13.1955 43.7837 11.3609 43.7837 10.221 42.6438C9.08105 41.5039 9.08105 39.6692 9.08105 35.9999V18.4864Z"
          fill="url(#paint4_linear_359_3176)"
          fillOpacity="0.24"
        />
      </g>
      <g filter="url(#filter5_bii_359_3176)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.7297 10.8324H16.8648C15.0265 10.8324 13.6608 10.8326 12.6117 10.9737C11.5662 11.1143 10.8545 11.3924 10.3127 11.9343C9.77086 12.4761 9.49267 13.1878 9.35212 14.2332C9.21106 15.2824 9.21078 16.6481 9.21078 18.4864V35.9999C9.21078 37.8383 9.21106 39.2039 9.35212 40.2531C9.49267 41.2985 9.77086 42.0102 10.3127 42.5521C10.8545 43.0939 11.5662 43.3721 12.6117 43.5127C13.6608 43.6537 15.0265 43.654 16.8648 43.654H33.7297C35.568 43.654 36.9337 43.6537 37.9829 43.5127C39.0283 43.3721 39.74 43.0939 40.2818 42.5521C40.8237 42.0102 41.1019 41.2985 41.2424 40.2531C41.3835 39.2039 41.3838 37.8383 41.3838 35.9999V18.4864C41.3838 16.6481 41.3835 15.2824 41.2424 14.2332C41.1019 13.1878 40.8237 12.4761 40.2818 11.9343C39.74 11.3924 39.0283 11.1143 37.9829 10.9737C36.9337 10.8326 35.568 10.8324 33.7297 10.8324ZM10.221 11.8425C9.08105 12.9825 9.08105 14.8171 9.08105 18.4864V35.9999C9.08105 39.6692 9.08105 41.5039 10.221 42.6438C11.3609 43.7837 13.1955 43.7837 16.8648 43.7837H33.7297C37.399 43.7837 39.2337 43.7837 40.3736 42.6438C41.5135 41.5039 41.5135 39.6692 41.5135 35.9999V18.4864C41.5135 14.8171 41.5135 12.9825 40.3736 11.8425C39.2337 10.7026 37.399 10.7026 33.7297 10.7026H16.8648C13.1955 10.7026 11.3609 10.7026 10.221 11.8425Z"
          fill="url(#paint5_linear_359_3176)"
        />
      </g>
      <g filter="url(#filter6_d_359_3176)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0832 7.09058C16.4513 6.54895 16.8343 5.5135 17.6666 5.5135H18.9189C19.2175 5.5135 19.4595 5.27149 19.4595 4.97296C19.4595 2.58469 21.3956 0.648621 23.7838 0.648621H24.2161C26.6102 0.648621 28.5567 2.57889 28.5766 4.97296C28.5791 5.27222 28.8224 5.5135 29.1217 5.5135H30.3421C31.1479 5.5135 31.537 6.5006 30.9479 7.05044L28.9328 8.93119C28.5024 9.33289 28.7867 10.054 29.3754 10.054H31.6216C32.2486 10.054 32.7568 10.5623 32.7568 11.1892C32.7568 11.8161 32.2486 12.3243 31.6216 12.3243H24.2772L24.2746 12.3243H24.149L24.1465 12.3243H16.0541C15.4272 12.3243 14.9189 11.8161 14.9189 11.1892C14.9189 10.5623 15.4272 10.054 16.0541 10.054H18.7871C19.3893 10.054 19.6665 9.30481 19.2092 8.9129L17.0832 7.09058ZM23.964 2.27024C22.3718 2.27024 21.0811 3.56095 21.0811 5.15312C21.0811 5.35215 21.2424 5.51349 21.4415 5.51349H26.5586C26.7576 5.51349 26.9189 5.35215 26.9189 5.15312C26.9189 3.56095 25.6282 2.27024 24.0361 2.27024H23.964Z"
          fill="#E0E0E0"
        />
      </g>
      <g filter="url(#filter7_d_359_3176)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.6216 12.1946C32.1769 12.1946 32.6271 11.7444 32.6271 11.1892C32.6271 10.6339 32.1769 10.1838 31.6216 10.1838H29.3754C28.6689 10.1838 28.3278 9.31839 28.8443 8.83636L30.8594 6.9556C31.3624 6.4861 31.0302 5.64323 30.3421 5.64323H29.1217C28.7512 5.64323 28.45 5.34452 28.4469 4.97404C28.4275 2.65119 26.539 0.77835 24.2161 0.77835H23.7838C21.4672 0.77835 19.5892 2.65634 19.5892 4.97296C19.5892 5.34314 19.2891 5.64323 18.9189 5.64323H17.6666C16.9548 5.64323 16.6272 6.52883 17.1676 6.99208L19.2937 8.8144C19.8423 9.2847 19.5098 10.1838 18.7871 10.1838H16.0541C15.4988 10.1838 15.0487 10.6339 15.0487 11.1892C15.0487 11.7444 15.4988 12.1946 16.0541 12.1946H31.6216ZM16.0541 12.3243C15.4272 12.3243 14.9189 11.8161 14.9189 11.1892C14.9189 10.5623 15.4272 10.054 16.0541 10.054H18.7871C19.3893 10.054 19.6665 9.30481 19.2092 8.9129L17.0832 7.09058C16.4513 6.54895 16.8343 5.5135 17.6666 5.5135H18.9189C19.2175 5.5135 19.4595 5.27149 19.4595 4.97296C19.4595 2.58469 21.3956 0.648621 23.7838 0.648621H24.2161C26.6102 0.648621 28.5567 2.57889 28.5766 4.97296C28.5791 5.27222 28.8224 5.5135 29.1217 5.5135H30.3421C31.1479 5.5135 31.537 6.5006 30.9479 7.05044L28.9328 8.93119C28.5024 9.33289 28.7867 10.054 29.3754 10.054H31.6216C32.2486 10.054 32.7568 10.5623 32.7568 11.1892C32.7568 11.8161 32.2486 12.3243 31.6216 12.3243H16.0541ZM20.9514 5.15313C20.9514 3.48931 22.3002 2.14051 23.964 2.14051H24.0361C25.6999 2.14051 27.0487 3.48931 27.0487 5.15313C27.0487 5.4238 26.8293 5.64322 26.5586 5.64322H21.4415C21.1708 5.64322 20.9514 5.42379 20.9514 5.15313ZM21.0811 5.15313C21.0811 3.56095 22.3718 2.27024 23.964 2.27024H24.0361C25.6282 2.27024 26.9189 3.56095 26.9189 5.15313C26.9189 5.35215 26.7576 5.51349 26.5586 5.51349H21.4415C21.2424 5.51349 21.0811 5.35215 21.0811 5.15313Z"
          fill="url(#paint6_linear_359_3176)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter8_d_359_3176)">
        <path
          d="M15.8373 20.3233C14.9719 20.3233 14.2703 19.6217 14.2703 18.7562C14.2703 17.8908 14.9719 17.1892 15.8373 17.1892L34.7573 17.1892C35.6227 17.1892 36.3243 17.8908 36.3243 18.7562C36.3243 19.6217 35.6227 20.3233 34.7573 20.3233L15.8373 20.3233Z"
          fill="#E0E0E0"
        />
      </g>
      <g filter="url(#filter9_d_359_3176)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7573 17.3189L15.8373 17.3189C15.0435 17.3189 14.4 17.9624 14.4 18.7562C14.4 19.5501 15.0435 20.1936 15.8373 20.1936L34.7573 20.1936C35.5511 20.1936 36.1946 19.5501 36.1946 18.7562C36.1946 17.9624 35.5511 17.3189 34.7573 17.3189ZM14.2703 18.7562C14.2703 19.6217 14.9719 20.3233 15.8373 20.3233L34.7573 20.3233C35.6227 20.3233 36.3243 19.6217 36.3243 18.7562C36.3243 17.8908 35.6227 17.1892 34.7573 17.1892L15.8373 17.1892C14.9719 17.1892 14.2703 17.8908 14.2703 18.7562Z"
          fill="url(#paint7_linear_359_3176)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter10_d_359_3176)">
        <path
          d="M15.8373 26.052C14.9719 26.052 14.2703 25.3504 14.2703 24.4849C14.2703 23.6195 14.9719 22.9179 15.8373 22.9179L34.7573 22.9179C35.6227 22.9179 36.3243 23.6195 36.3243 24.4849C36.3243 25.3504 35.6227 26.052 34.7573 26.052L15.8373 26.052Z"
          fill="#E0E0E0"
        />
      </g>
      <g filter="url(#filter11_d_359_3176)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7573 23.0476L15.8373 23.0476C15.0435 23.0476 14.4 23.6911 14.4 24.4849C14.4 25.2788 15.0435 25.9223 15.8373 25.9223L34.7573 25.9223C35.5511 25.9223 36.1946 25.2788 36.1946 24.4849C36.1946 23.6911 35.5511 23.0476 34.7573 23.0476ZM14.2703 24.4849C14.2703 25.3504 14.9719 26.052 15.8373 26.052L34.7573 26.052C35.6227 26.052 36.3243 25.3504 36.3243 24.4849C36.3243 23.6195 35.6227 22.9179 34.7573 22.9179L15.8373 22.9179C14.9719 22.9179 14.2703 23.6195 14.2703 24.4849Z"
          fill="url(#paint8_linear_359_3176)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter12_d_359_3176)">
        <path
          d="M15.8373 31.7808C14.9719 31.7808 14.2703 31.0792 14.2703 30.2137C14.2703 29.3482 14.9719 28.6466 15.8373 28.6466L34.7573 28.6466C35.6227 28.6466 36.3243 29.3482 36.3243 30.2137C36.3243 31.0792 35.6227 31.7808 34.7573 31.7808L15.8373 31.7808Z"
          fill="#E0E0E0"
        />
      </g>
      <g filter="url(#filter13_d_359_3176)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7573 28.7764L15.8373 28.7764C15.0435 28.7764 14.4 29.4199 14.4 30.2137C14.4 31.0075 15.0435 31.651 15.8373 31.651L34.7573 31.651C35.5511 31.651 36.1946 31.0075 36.1946 30.2137C36.1946 29.4199 35.5511 28.7764 34.7573 28.7764ZM14.2703 30.2137C14.2703 31.0792 14.9719 31.7808 15.8373 31.7808L34.7573 31.7808C35.6227 31.7808 36.3243 31.0792 36.3243 30.2137C36.3243 29.3482 35.6227 28.6466 34.7573 28.6466L15.8373 28.6466C14.9719 28.6466 14.2703 29.3482 14.2703 30.2137Z"
          fill="url(#paint9_linear_359_3176)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter14_d_359_3176)">
        <path
          d="M15.8373 37.5095C14.9719 37.5095 14.2703 36.8079 14.2703 35.9424C14.2703 35.0769 14.9719 34.3753 15.8373 34.3753L34.7573 34.3753C35.6227 34.3753 36.3243 35.0769 36.3243 35.9424C36.3243 36.8079 35.6227 37.5095 34.7573 37.5095L15.8373 37.5095Z"
          fill="#E0E0E0"
        />
      </g>
      <g filter="url(#filter15_d_359_3176)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7573 34.5051L15.8373 34.5051C15.0435 34.5051 14.4 35.1486 14.4 35.9424C14.4 36.7362 15.0435 37.3797 15.8373 37.3797L34.7573 37.3797C35.5511 37.3797 36.1946 36.7362 36.1946 35.9424C36.1946 35.1486 35.5511 34.5051 34.7573 34.5051ZM14.2703 35.9424C14.2703 36.8079 14.9719 37.5095 15.8373 37.5095L34.7573 37.5095C35.6227 37.5095 36.3243 36.8079 36.3243 35.9424C36.3243 35.0769 35.6227 34.3753 34.7573 34.3753L15.8373 34.3753C14.9719 34.3753 14.2703 35.0769 14.2703 35.9424Z"
          fill="url(#paint10_linear_359_3176)"
          shapeRendering="crispEdges"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_ii_359_3176"
        x="5.78369"
        y="6.10809"
        width="36.4324"
        height="35.4595"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3176"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3176"
          result="effect2_innerShadow_359_3176"
        />
      </filter>
      <filter
        id="filter1_ii_359_3176"
        x="5.78369"
        y="6.10809"
        width="36.4324"
        height="35.4595"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_359_3176"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_359_3176"
          result="effect2_innerShadow_359_3176"
        />
      </filter>
      <filter
        id="filter2_bii_359_3176"
        x="-2.88989"
        y="1.00208"
        width="51.0886"
        height="51.6533"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3176"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3176"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3176"
          result="effect3_innerShadow_359_3176"
        />
      </filter>
      <filter
        id="filter3_bii_359_3176"
        x="-2.88989"
        y="1.00208"
        width="51.0886"
        height="51.6533"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3176"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3176"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3176"
          result="effect3_innerShadow_359_3176"
        />
      </filter>
      <filter
        id="filter4_bii_359_3176"
        x="1.08105"
        y="2.70264"
        width="48.4324"
        height="49.0811"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3176"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3176"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3176"
          result="effect3_innerShadow_359_3176"
        />
      </filter>
      <filter
        id="filter5_bii_359_3176"
        x="1.08105"
        y="2.70264"
        width="48.4324"
        height="49.0811"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_359_3176"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_359_3176"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_359_3176"
          result="effect3_innerShadow_359_3176"
        />
      </filter>
      <filter
        id="filter6_d_359_3176"
        x="12.9189"
        y="-0.351379"
        width="21.8379"
        height="15.6757"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3176"
          result="shape"
        />
      </filter>
      <filter
        id="filter7_d_359_3176"
        x="12.9189"
        y="-0.351379"
        width="21.8379"
        height="15.6757"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3176"
          result="shape"
        />
      </filter>
      <filter
        id="filter8_d_359_3176"
        x="12.2703"
        y="16.1892"
        width="26.054"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3176"
          result="shape"
        />
      </filter>
      <filter
        id="filter9_d_359_3176"
        x="12.2703"
        y="16.1892"
        width="26.054"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3176"
          result="shape"
        />
      </filter>
      <filter
        id="filter10_d_359_3176"
        x="12.2703"
        y="21.9179"
        width="26.054"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3176"
          result="shape"
        />
      </filter>
      <filter
        id="filter11_d_359_3176"
        x="12.2703"
        y="21.9179"
        width="26.054"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3176"
          result="shape"
        />
      </filter>
      <filter
        id="filter12_d_359_3176"
        x="12.2703"
        y="27.6467"
        width="26.054"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3176"
          result="shape"
        />
      </filter>
      <filter
        id="filter13_d_359_3176"
        x="12.2703"
        y="27.6467"
        width="26.054"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3176"
          result="shape"
        />
      </filter>
      <filter
        id="filter14_d_359_3176"
        x="12.2703"
        y="33.3754"
        width="26.054"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3176"
          result="shape"
        />
      </filter>
      <filter
        id="filter15_d_359_3176"
        x="12.2703"
        y="33.3754"
        width="26.054"
        height="7.13409"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_359_3176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_359_3176"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_359_3176"
        x1="8.84127"
        y1="10.4676"
        x2="44.1876"
        y2="16.8761"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopColor="#3787FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_359_3176"
        x1="1.13014"
        y1="-106.86"
        x2="62.0097"
        y2="3.42864"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_359_3176"
        x1="20.6355"
        y1="43.2456"
        x2="24.6736"
        y2="10.4119"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_359_3176"
        x1="5.44681"
        y1="5.01724"
        x2="22.2274"
        y2="52.0252"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_359_3176"
        x1="25.2973"
        y1="43.7837"
        x2="25.2973"
        y2="10.7026"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005EF5" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_359_3176"
        x1="5.55579"
        y1="7.69527"
        x2="27.949"
        y2="52.3034"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.765625" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_359_3176"
        x1="14.9189"
        y1="10.9339"
        x2="28.7928"
        y2="10.9339"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_359_3176"
        x1="14.2703"
        y1="18.7562"
        x2="31.4234"
        y2="18.7562"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_359_3176"
        x1="14.2703"
        y1="24.4849"
        x2="31.4234"
        y2="24.4849"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_359_3176"
        x1="14.2703"
        y1="30.2137"
        x2="31.4234"
        y2="30.2137"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_359_3176"
        x1="14.2703"
        y1="35.9424"
        x2="31.4234"
        y2="35.9424"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_359_3176">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
