export const LegalGroundsForTheProcessingOfPersonalData = () => (
  <div>
    <div className="privacy_content_title">
      Правовые основания обработки персональных данных
    </div>
    <div className="privacy_content">
      <div>
        Оператор обрабатывает персональные данные пользователей только в случае
        их заполнения и/или отправки пользователем самостоятельно через
        специальные формы, расположенные на Сайте{' '}
        <a href="https://www.dors.online">www.dors.online</a>. Заполняя
        соответствующие формы и/или отправляя свои персональные данные
        Оператору, пользователь выражает свое согласие с настоящей Политикой.
      </div>
      <div>
        Оператор обрабатывает обезличенные данные о пользователе в случае, если
        это разрешено в настройках браузера пользователя (включено сохранение
        файлов «cookie»).
      </div>
    </div>
  </div>
)
