import axios from 'axios'
export const getFeedback = async (
  title: string,
  phoneNumber: string,
  email: string,
  companyName: string,
  companySize: string,
  comment: string
) => {
  const request = axios.post(
    'https://axespro-api.k8s.axes.pro/api/v1/feedback',
    {
      resourseID: 10,
      title,
      phoneNumber,
      email,
      companyName,
      companySize,
      comment
    }
  )
  return await request
}
