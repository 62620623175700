export const PurposesOfPersonalDataProcessing = () => (
  <div>
    <div className="privacy_content_title">
      Цели обработки персональных данных
    </div>
    <div className="privacy_content">
      <div>
        Оператор осуществляет сбор и обработку персональных данных пользователей
        для следующих целей:
        <ul style={{ marginTop: 0 }}>
          <li>во исполнение гражданско-правовых договоров;</li>
          <li>
            предоставление доступа пользователю к сервисам, информации и/или
            материалам, размещённым на Веб-сайте{' '}
            <a href="https://www.dors.online">www.dors.online</a>;{' '}
          </li>
          <li>для обратной связи;</li>
          <li> для оформления/уточнения обращений (заказов);</li>{' '}
          <li>
            улучшение организации структуры Сайта{' '}
            <a href="https://www.dors.online">www.dors.online</a> для наиболее
            удобного ориентирования пользователей;
          </li>
          <li>
            для предоставления возможности получения рассылки уведомлений о
            новых продуктах и услугах, специальных предложениях и различных
            событиях. При этом пользователь всегда и в любое время может
            отказаться от получения информационных сообщений, направив Оператору
            соответствующее письмо на адрес электронной почты @.
          </li>
        </ul>
      </div>
      Обезличенные данные пользователей, собираемые с помощью сервисов
      интернет-статистики, служат для сбора информации о действиях пользователей
      на сайте, улучшения качества сайта и его содержания.
    </div>
  </div>
)
