export const FireSvgPrimary = () => {
  return (
    <svg
      width="89"
      height="88"
      viewBox="0 0 89 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ii_359_3081)">
        <path
          d="M70.1726 51.2518C67.6749 64.4529 54.9259 73.1254 41.6968 70.6225C28.4676 68.1195 19.7681 55.3889 22.2657 42.1878C23.4718 35.8132 28.0441 27.8261 33.6775 26.273C36.428 25.5147 33.5972 34.2661 39.3446 37.634C39.5946 37.7805 39.9 37.6031 39.9194 37.314C40.6368 26.6511 51.8369 9.10171 60.5234 10.0578C60.7678 10.0847 60.9106 10.3374 60.84 10.573C57.8072 20.6988 73.24 35.0394 70.1726 51.2518Z"
          fill="url(#paint0_linear_359_3081)"
        />
      </g>
      <path
        d="M69.9761 51.2146C67.499 64.3068 54.8549 72.9084 41.7339 70.4259C28.613 67.9435 19.9852 55.3171 22.4622 42.225C23.0602 39.0646 24.4943 35.4993 26.4776 32.5335C28.464 29.5632 30.9809 27.2239 33.7307 26.4658C34.0252 26.3846 34.2076 26.4365 34.3429 26.5555C34.4946 26.6889 34.6193 26.934 34.7223 27.3077C34.8239 27.6762 34.8962 28.1376 34.9656 28.6722C34.9912 28.8695 35.0165 29.0774 35.0428 29.2933C35.0868 29.6556 35.1336 30.0401 35.1892 30.4341C35.5445 32.9534 36.2828 36.0716 39.2435 37.8065C39.6337 38.0352 40.0905 37.7508 40.119 37.3275C40.4744 32.0449 43.4336 25.0268 47.3961 19.4445C49.3757 16.6556 51.5983 14.2359 53.8587 12.5749C56.1222 10.9117 58.4008 10.0254 60.5015 10.2566C60.6031 10.2678 60.6888 10.381 60.6484 10.5156C59.873 13.1044 60.2826 15.946 61.2597 18.9823C62.0844 21.5449 63.3217 24.2697 64.6165 27.1211C64.8554 27.6472 65.0963 28.1776 65.3369 28.712C68.4341 35.5929 71.4967 43.1774 69.9761 51.2146Z"
        stroke="url(#paint1_linear_359_3081)"
        strokeWidth="0.4"
      />
      <g filter="url(#filter1_bii_359_3081)">
        <path
          d="M62.1675 45.5141C66.5383 58.2185 59.7606 72.0682 47.0292 76.4483C34.2978 80.8284 20.4337 74.0801 16.0629 61.3757C13.9523 55.2408 13.9669 46.0377 18.0908 41.8975C20.1043 39.8761 21.9812 48.8805 28.6422 48.9585C28.932 48.9618 29.1094 48.6565 28.983 48.3957C24.3233 38.778 25.3575 17.985 33.3766 14.5118C33.6023 14.4141 33.8515 14.5629 33.907 14.8025C36.2892 25.1008 56.7997 29.9116 62.1675 45.5141Z"
          fill="url(#paint2_linear_359_3081)"
          fillOpacity="0.24"
        />
        <path
          d="M61.9784 45.5791C66.3131 58.1787 59.5915 71.9149 46.9642 76.2592C34.3368 80.6034 20.5868 73.9102 16.2521 61.3106C15.2057 58.2691 14.685 54.4616 14.9384 50.9028C15.1922 47.3385 16.2196 44.0595 18.2325 42.0387C18.4482 41.8222 18.6323 41.7769 18.8088 41.8132C19.0067 41.854 19.2365 42.0051 19.5111 42.2787C19.7818 42.5485 20.0732 42.9135 20.3984 43.3434C20.5184 43.5021 20.6434 43.6702 20.7732 43.8447C20.9909 44.1376 21.2221 44.4484 21.4655 44.7631C23.0223 46.7755 25.2086 49.1183 28.6399 49.1584C29.0921 49.1637 29.348 48.6904 29.163 48.3085C26.8545 43.5438 25.948 35.9815 26.6243 29.1693C26.9621 25.7659 27.6939 22.563 28.8345 20.0002C29.9766 17.4341 31.5168 15.5353 33.4561 14.6953C33.5499 14.6547 33.6804 14.7106 33.7121 14.8475C34.3212 17.4805 36.0848 19.7459 38.4378 21.8992C40.4238 23.7166 42.8485 25.4704 45.3859 27.3057C45.8541 27.6444 46.3261 27.9858 46.7999 28.3309C52.8993 32.7734 59.3173 37.8442 61.9784 45.5791Z"
          stroke="url(#paint3_linear_359_3081)"
          strokeWidth="0.4"
        />
      </g>
      <g filter="url(#filter2_d_359_3081)">
        <path
          d="M62.2242 64.7133C62.8644 73.3491 56.3679 80.8699 47.7137 81.5115C39.0596 82.1531 31.525 75.6725 30.8847 67.0367C30.5756 62.8666 32.0944 57.1325 35.3441 55.2285C36.9032 54.3149 36.6335 60.0159 40.5512 61.2969C40.8266 61.3869 41.0769 61.1329 41.0267 60.8476C39.8391 54.1031 43.7758 41.7556 49.2001 40.6948C49.4415 40.6476 49.6449 40.8549 49.6446 41.1008C49.6375 47.8482 61.4443 54.194 62.2242 64.7133Z"
          fill="white"
        />
        <path
          d="M62.0247 64.7281C62.6568 73.2533 56.2433 80.6786 47.6989 81.3121C39.1545 81.9455 31.7162 75.5472 31.0842 67.0219C30.9316 64.9642 31.2304 62.5151 31.9691 60.3473C32.7097 58.1744 33.8803 56.3179 35.4452 55.401C35.5972 55.3119 35.6928 55.3189 35.769 55.3551C35.8636 55.4 35.9709 55.5101 36.0929 55.7139C36.2124 55.9133 36.3302 56.1747 36.459 56.4859C36.5127 56.6155 36.5699 56.7577 36.6297 56.9066C36.7086 57.1029 36.7922 57.3109 36.8787 57.5171C37.4971 58.9911 38.4383 60.8165 40.489 61.487C40.9346 61.6327 41.2951 61.2184 41.2237 60.8129C40.6379 57.4865 41.3155 52.7509 42.8214 48.7097C43.5734 46.6913 44.5279 44.8577 45.6247 43.4666C46.7237 42.0726 47.949 41.1433 49.2385 40.8911C49.3288 40.8734 49.4448 40.9511 49.4446 41.1006C49.4428 42.8454 50.2043 44.547 51.3302 46.2671C52.2606 47.6886 53.4536 49.1422 54.6974 50.6576C54.9581 50.9752 55.221 51.2955 55.4842 51.6189C58.5343 55.3659 61.64 59.538 62.0247 64.7281Z"
          stroke="url(#paint4_linear_359_3081)"
          strokeWidth="0.4"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_359_3081"
          x="19.8381"
          y="8.02026"
          width="52.74"
          height="65.0325"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_359_3081"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_359_3081"
            result="effect2_innerShadow_359_3081"
          />
        </filter>
        <filter
          id="filter1_bii_359_3081"
          x="6.67505"
          y="6.48145"
          width="64.8198"
          height="79.3022"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_359_3081"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_359_3081"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_359_3081"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_359_3081"
            result="effect3_innerShadow_359_3081"
          />
        </filter>
        <filter
          id="filter2_d_359_3081"
          x="28.8455"
          y="39.6881"
          width="35.4224"
          height="44.8672"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_359_3081"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_359_3081"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_359_3081"
          x1="30.1436"
          y1="9.1023"
          x2="81.7281"
          y2="26.3234"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E2E2E2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_359_3081"
          x1="62.4513"
          y1="-224.014"
          x2="162.883"
          y2="-14.6367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_359_3081"
          x1="27.3106"
          y1="19.1326"
          x2="48.1714"
          y2="79.7683"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_359_3081"
          x1="-3.40247"
          y1="21.0866"
          x2="66.8087"
          y2="77.0361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.765625" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_359_3081"
          x1="44.7103"
          y1="40.9995"
          x2="47.0463"
          y2="72.5089"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
