import React, { useEffect, useRef } from 'react'
import ReactECharts from 'echarts-for-react'
import { H2 } from '../../ui/H/H'
import { Inner } from '../../layout/Inner/Inner'
import './styles.scss'
import { barLineChartData, pieChartJobs, pieChartPublications, pieChartResponses, pieChartResume } from './diagramsData'

export const DataAnalytics: React.FC = () => {
  const renderLegend = (legendItems: any, mods: string = '') => (
    <div className={'data-analytics__legend ' + mods}>
      {legendItems.map((item: any, index: number) => (
        <div className="data-analytics__legend-item" key={index}>
          <div
            className="data-analytics__legend-icon"
            style={{
              ['--color' as string]: (item.color.image ? `url(${item.color.image as string})` : `${item.color as string}`)
            }}
          ></div>
          <div className="data-analytics__legend-name">{item.name}</div>
        </div>
      ))}
    </div>
  )

  const dataAnalyticsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (dataAnalyticsRef.current) {
      const elements = dataAnalyticsRef.current.querySelectorAll('.data-analytics__head')
      elements.forEach((element) => {
        const htmlElement = element as HTMLElement
        const height = `${htmlElement.clientHeight}px`
        const sectionElement = htmlElement.closest('.data-analytics__section') as HTMLElement
        if (sectionElement) sectionElement.style.setProperty('--head-height', height)
      })
    }
  })

  return (
    <section className="data-analytics" ref={dataAnalyticsRef}>
      <div className="data-analytics__top">
        <Inner>
          <H2 mod="data-analytics">Аналитика данных</H2>
          <div className="data-analytics__descr">Важная часть массового подбора – аналитика. При необходимости мы можем настроить требуемую вам аналитику</div>
        </Inner>
      </div>
      <div className="data-analytics__section">
        <div className="data-analytics__head">
          <Inner>
            <div className="data-analytics__num">01</div>
            <div className="data-analytics__title">Возможность просмотреть общую статистику по компании</div>
          </Inner>
        </div>
        <Inner mod='data-analytics'>
          <div className="data-analytics__body">
            <div className="data-analytics__item">
              <div className="data-analytics__charts">
                <div className="data-analytics__chart">
                  <div className="data-analytics__name">Общее количество публикаций в работе</div>
                  <PieChartEC option={getPieChartOprions(pieChartPublications)} />
                </div>
                <div className="data-analytics__chart">
                  <div className="data-analytics__name">Общее количество резюме в работе</div>
                  <PieChartEC option={getPieChartOprions(pieChartResume)} />
                </div>
              </div>
              {renderLegend(pieChartPublications)}
            </div>
            <div className="data-analytics__item">
              <div className="data-analytics__name">Последние публикации</div>
              <div className="latest-publications">
                {latestPublicationsData.map((item, index) =>
                  <div key={index} className="latest-publications__group">
                    <div className="latest-publications__name">{item.groupName}</div>
                    <div className="latest-publications__list">
                      {item.list.map((item, index) =>
                        <div key={index} className="latest-publications__item">
                          <div className="latest-publications__title">{item.title}</div>
                          <div className="latest-publications__date">{item.date}</div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Inner>
      </div>
      <div className="data-analytics__section">
        <div className="data-analytics__head">
          <Inner>
            <div className="data-analytics__num">02</div>
            <div className="data-analytics__title">Возможность посмотреть детальную статистику по каждой из вакансий</div>
          </Inner>
        </div>
        <Inner mod='data-analytics'>
          <div className="data-analytics__body">
            <div className="data-analytics__item">
              <div className="data-analytics__charts">
                <div className="data-analytics__chart">
                  <div className="data-analytics__name">Количество размещенных вакансий от компании</div>
                  <PieChartEC option={getPieChartOprions(pieChartJobs)} />
                </div>
                <div className="data-analytics__chart">
                  <div className="data-analytics__name">Количество откликов</div>
                  <PieChartEC option={getPieChartOprions(pieChartResponses)} />
                </div>
              </div>
              {renderLegend(pieChartJobs)}
            </div>
            <div className="data-analytics__item">
              <div className="data-analytics__name">Оператор Call центра</div>
              <div className="data-analytics__bar-chart">
                <ReactECharts
                  notMerge={true}
                  option={getBarLineChartOptions(barLineChartData)}
                  opts={{ renderer: 'svg' }}
                />
              </div>
              {renderLegend([
                { name: barLineChartData.barName, color: barLineChartData.barColor },
                { name: barLineChartData.lineName, color: barLineChartData.lineColor }
              ], 'data-analytics__legend--line data-analytics__legend--mt16')}
            </div>
          </div>
        </Inner>
      </div>
    </section>
  )
}

const PieChartEC: React.FC<any> = ({ option }) => {
  const chartRef = useRef(null)

  // fix outside touch
  useEffect(() => {
    const handleTouchstart = (event: TouchEvent) => {
      const target = event.target as HTMLElement

      if (target) {
        if (chartRef.current) {
          const current = chartRef.current as any
          const instance = current.getEchartsInstance()

          if (!target.closest(`[_echarts_instance_="${instance.getDom().getAttribute('_echarts_instance_')}"]`)) {
            instance.dispatchAction({
              type: 'highlight',
              seriesIndex: 1
            })
            instance.dispatchAction({
              type: 'hideTip'
            })
          }
        }
      }
    }
    document.addEventListener('touchstart', handleTouchstart)

    return () => {
      document.removeEventListener('touchstart', handleTouchstart)
    }
  })

  return (
    <ReactECharts
      className='data-analytics__pie-chart'
      notMerge={true}
      option={option}
      opts={{ renderer: 'svg' }}
      ref={chartRef}
    />
  )
}

const latestPublicationsData = [
  {
    groupName: 'Оператор Call-центра',
    list: [
      { title: 'HH.ru', date: '25.02.2023' },
      { title: 'SuperJob', date: '06.03.2023' },
      { title: 'Avito', date: '24.03.2023' },
      { title: 'Zarplata.ru', date: '25.02.2023' },
      { title: 'Rabota', date: '12.03.2023' },
      { title: 'Карьерный сайт', date: '08.03.2023' }
    ]
  },
  {
    groupName: 'Менеджер по продажам',
    list: [
      { title: 'HH.ru', date: '13.03.2023' },
      { title: 'SuperJob', date: '17.03.2023' },
      { title: 'Avito', date: '05.03.2023' },
      { title: 'Zarplata.ru', date: '14.02.2023' },
      { title: 'Rabota', date: '01.03.2023' },
      { title: 'Карьерный сайт', date: '28.02.2023' }
    ]
  }
]

const getBarLineChartOptions = (data: any) => (
  {
    grid: {
      top: 10,
      left: 25,
      right: 12,
      bottom: 40,
      height: 200
    },
    xAxis: {
      data: data.bar.map((item: any) => item.label),
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: '#CECECE'
        }
      },
      axisLabel: {
        margin: 16,
        color: '#787878',
        fontFamily: 'Inter',
        fontSize: 13,
        lineHeight: 1.15,
        hideOverlap: false,
        interval: 0
      }
    },
    yAxis: {
      interval: 10,
      splitLine: {
        lineStyle: {
          color: '#CECECE'
        }
      },
      axisLabel: {
        margin: 25,
        color: '#787878',
        align: 'left',
        fontFamily: 'Inter',
        fontSize: 14,
        lineHeight: 1.3
      }
    },
    series: [
      {
        clip: false,
        type: 'bar',
        barWidth: 40,
        color: data.barColor,
        barCategoryGap: '0%',
        data: data.bar.map((item: any) => item.value)
      },
      {
        type: 'line',
        data: data.line,
        symbol: 'none',
        lineStyle: {
          color: data.lineColor,
          width: 6,
          cap: 'round',
          shadowOffsetY: 4,
          shadowColor: 'rgba(1, 183, 249, 0.48)',
          shadowBlur: 16
        }
      }
    ]
  }
)

const getPieChartOprions = (data: any) => (
  {
    tooltip: {
      show: true,
      formatter: (params: any) => `<div class="percent">${params.percent.toFixed(1)}%</div> ${params.name}`,
      className: 'data-analytics__tooltip',
      padding: [8, 16, 8, 16],
      borderWidth: 0,
      backgroundColor: '#fff',
      textStyle: {
        fontSize: 14,
        lineHeight: 1.4,
        color: '#888'
      }
    },
    textStyle: {
      fontFamily: 'Inter',
      fontSize: 12,
      lineHeight: 1.3
    },
    series: [
      {
        type: 'pie',
        top: 0,
        radius: ['100%', '12%'],
        startAngle: 90,
        height: '100%',
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          focus: 'self',
          blurScope: 'series',
          scale: false,
          itemStyle: {
            shadowBlur: 32,
            shadowColor: 'rgba(0, 94, 245, 0.25)',
            shadowOffsetY: 4
          }
        },
        blur: {
          itemStyle: {
            opacity: 0.2
          }
        },
        labelLine: {
          show: false
        },
        itemStyle: {
          color: (params: any) => params.data.color,
          borderColor: '#fff',
          borderWidth: 2,
          shadowColor: 'rgba(0, 94, 245, 0)'
        },
        data
      }
    ]
  }
)
