import React, { useContext } from 'react'
import img from './solutions-banner.png'
import imgMobile from './solutions-banner_mobile.png'
import { useWindow } from '../../../hooks/useWindow'
import { Btn } from '../../ui/Btn/Btn'
import { LayoutContext } from '../../../contexts/LayoutContext/LayoutContext'
import './style.scss'

export const Banner: React.FC = () => {
  const windowWidth = useWindow()

  const LayoutState = useContext(LayoutContext)

  return (
    <div className="banner">
      <div className="inner banner__inner">
        <div className="banner__content">
          <h1 className="banner__title">DORS Мультипостинг</h1>
          <div className="banner__descr">Публикуйте ваши вакансии сразу на&nbsp;нескольких работных сайтах и&nbsp;получайте все отклики автоматически в&nbsp;вашей системе подбора персонала</div>
          <Btn
            onClick={() => LayoutState?.setPopupIsOpen(true)}
            mod="--m-w100, banner"
          >Отправить запрос на демо</Btn>
        </div>
        <div className="banner__img">
          <img src={windowWidth > 767 ? img : imgMobile} alt="" />
        </div>
      </div>
    </div>
  )
}
