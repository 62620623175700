import React from 'react'
import { About } from '../../components/common/About/About'
import { Details } from '../../components/common/Details/Details'
import { Partner } from '../../components/common/Partner/Partner'
import { Risks } from '../../components/common/Risks/Risks'
import { WhyAreWe } from '../../components/common/WhyAreWe/WhyAreWe'
import { Main } from '../../components/layout/Main/Main'

export const HomePage: React.FC = () => {
  return (
    <Main>
      <About />
      <Risks
        title='Риски, о которых надо забыть'
        descr="Мы заметили, что большая часть разочарований Заказчика лежит в области 4 основных проблем"
        list={RiskList}
      />
      <WhyAreWe />
      <Details />
      <Partner />
    </Main>
  )
}

const RiskList = [
  {
    title: 'Эффект беговой  дорожки',
    content:
      '8 из 10 купленных в дом беговых дорожек используются в качестве вешалки для одежды. Большая часть ТЗ на автоматизацию HR процессов, с которыми нам удалось ознакомиться, содержали такие “беговые дорожки” в том или ином виде. Это происходило, в 100% случаев когда TO BE модель процессов существенно опережала по развитию (по зрелости) AS IS процессы заказчика'
  },
  {
    title: 'Вложенный Технологический долг',
    content:
      'Многие ТЗ, которые мы анализировали, были наполнены технологически просроченными идеями и концептами даже на этапе их создания, не говоря уже о том, насколько они будут отсталыми к моменту ввода в эксплуатацию. Эти недостатки, как правило, выявляются уже в ходе проекта и требуют постоянного увеличения сроков и стоимости работ'
  },
  {
    title: 'Экономический инфантилизм',
    content:
      'Очень часто клиента склоняют к ложно-выгодным форматам разработки ПО. Повременная оплата работы, разделение ответственности за конечный результат и прочие уловки опытных провайдеров засасывают клиента в долгосрочные и очень дорогие отношение с крайне низкой удовлетворенностью партнерством, соскочить с которого очень затруднительно'
  },
  {
    title: 'Трудности перевода',
    content:
      'HR довольно часто используют гуманитарный язык описания своих требований, который можно с технической точки зрения трактовать по разному и зачастую не в пользу Заказчика. Этим часто пользуются провайдеры, создавая Спецификации, которые невозможно понять и оценить с точки зрения соответствия требованиям Заказчика. Заказчик подписывает не глядя эти Спецификации, а потом получает совершенно не то, что хотел. Хотя юридическая правда на стороне Провайдера'
  }
]
