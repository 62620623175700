import React from 'react'
import classNames from 'classnames'
import './style.scss'

interface H2Props {
  children?: React.ReactNode
  mod?: string
}
export const H2: React.FC<H2Props> = ({
  mod,
  children
}) => {
  const modifiers = mod?.split(',').map((item) => item.trim())
  const baseName = 'ui-h2'
  const className = classNames(baseName, modifiers?.map((item) => (item.startsWith('--') ? `${baseName}${item}` : `${item}__${baseName}`)))

  return (
    <h2 className={className}>{children}</h2>
  )
}
