export const EmailSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.01406 13.8729L9.01406 13.8729L2.19935 9.32976C1.45006 8.83023 1 7.98928 1 7.08875C1 5.01856 2.67822 3.34033 4.74842 3.34033H19.2516C21.3218 3.34033 23 5.01856 23 7.08875C23 7.98928 22.5499 8.83023 21.8006 9.32976L14.9859 13.8729C14.9369 13.9056 14.8879 13.9384 14.8387 13.9714C14.2417 14.3715 13.6274 14.7831 12.9272 14.9509C12.3177 15.0969 11.6823 15.0969 11.0728 14.9509C10.3726 14.7831 9.75833 14.3715 9.16133 13.9714C9.11447 13.94 9.06772 13.9087 9.02104 13.8776C9.01871 13.876 9.01639 13.8744 9.01406 13.8729ZM9.92282 12.5098L3.10811 7.96661C2.8146 7.77093 2.6383 7.44151 2.6383 7.08875C2.6383 5.92336 3.58303 4.97863 4.74842 4.97863H19.2516C20.417 4.97863 21.3617 5.92336 21.3617 7.08875C21.3617 7.44151 21.1854 7.77093 20.8919 7.96661L14.0772 12.5097C13.3265 13.0102 12.9511 13.2605 12.5454 13.3577C12.1869 13.4436 11.8131 13.4436 11.4546 13.3577C11.0489 13.2605 10.6735 13.0102 9.92282 12.5098Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9997 6.80322L22.9999 15.3349C22.9999 15.9401 23 16.4893 22.9626 16.9471C22.9224 17.4387 22.8311 17.9673 22.5662 18.487C22.1848 19.2357 21.5761 19.8443 20.8275 20.2258C20.3077 20.4906 19.7791 20.582 19.2875 20.6221C18.8297 20.6595 18.2806 20.6595 17.6753 20.6595H6.3245C5.71924 20.6595 5.17007 20.6595 4.71229 20.6221C4.22071 20.582 3.6921 20.4906 3.17233 20.2258C2.42369 19.8443 1.81502 19.2357 1.43357 18.487C1.16873 17.9673 1.0774 17.4387 1.03724 16.9471C0.999836 16.4893 0.99987 15.9401 0.999908 15.3348C0.99991 15.3154 0.999911 15.296 0.999911 15.2765L0.999756 6.80322L10.105 12.6456C11.1734 13.2243 11.4121 13.3343 11.6306 13.3782C11.8744 13.4273 12.1255 13.4273 12.3693 13.3782C12.5878 13.3343 12.8265 13.2243 13.895 12.6456L22.9997 6.80322ZM9.32467 14.0861C10.3023 14.6157 10.791 14.8804 11.3074 14.9843C11.7645 15.0763 12.2354 15.0763 12.6925 14.9843C13.2089 14.8804 13.6977 14.6157 14.6752 14.0861L21.3614 9.55383L21.3616 15.2765C21.3616 16.5872 21.3616 17.2426 21.1065 17.7433C20.8821 18.1836 20.5241 18.5417 20.0837 18.7661C19.5831 19.0212 18.9277 19.0212 17.6169 19.0212H6.38289C5.07213 19.0212 4.41675 19.0212 3.9161 18.7661C3.47572 18.5417 3.11768 18.1836 2.8933 17.7433C2.63821 17.2426 2.63821 16.5872 2.63821 15.2765L2.63805 9.55383L9.32467 14.0861Z"
      fill="currentColor"
    />
  </svg>
)
