import React, { useContext } from 'react'
import { CrossSvg } from '../../../svg/CrossSvg'
import { useWindow } from '../../../hooks/useWindow'
import { DorsSvg } from '../../../svg/DorsSvg'
import { Link } from 'react-router-dom'
import { LayoutContext } from '../../../contexts/LayoutContext/LayoutContext'
import './styles.scss'

interface Props {
  children?: React.ReactNode
}
export const Popup: React.FC<Props> = ({
  children
}) => {
  const LayoutState = useContext(LayoutContext)
  const windowWidth = useWindow()
  const handlerPopupBg = (event: React.MouseEvent<HTMLElement>) => {
    if (!(event.target as HTMLElement).closest('.popup__content')) {
      LayoutState?.setPopupIsOpen(false)
    }
  }

  return (
    <div
      className="popup"
      onClick={handlerPopupBg}
    >
      <div className="popup__content">
        { windowWidth <= 1024 &&
          <div className='popup__head'>
            <Link to={'/'} className="popup__logo" onClick={() => LayoutState?.setPopupIsOpen(false)}>
              <DorsSvg />
            </Link>
          </div>
        }
        <button className="popup__close" onClick={() => LayoutState?.setPopupIsOpen(false)}>
          <CrossSvg />
        </button>
        {children}
      </div>
    </div>
  )
}
