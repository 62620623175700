export const CandidatesSvg = () => (<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_649_10677)">
<g filter="url(#filter0_dii_649_10677)">
<path d="M0.995762 19.0945C1.08877 18.9501 1.48921 18.3572 2.28863 17.5427C4.14626 15.6499 7.05883 13.7147 11.1119 13.7147C15.1649 13.7147 18.0775 15.6499 19.9351 17.5427C20.5493 18.1685 21.0518 18.7898 21.4494 19.3413C22.2022 20.3852 21.7598 21.7661 20.6323 22.3868C19.55 22.9827 18.1938 22.6861 17.459 21.6929L16.9922 21.0619C15.7163 19.7618 13.808 18.5041 11.1119 18.5041C8.41571 18.5041 6.50744 19.7618 5.23152 21.0619C5.05794 21.2388 4.83954 21.4863 4.61311 21.7535C3.86569 22.6355 2.57343 22.8552 1.60336 22.2263C0.578127 21.5616 0.280485 20.1831 0.952021 19.1623C0.966891 19.1397 0.981107 19.1172 0.995762 19.0945Z" fill="url(#paint0_linear_649_10677)"/>
</g>
<g filter="url(#filter1_dii_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M2.21445 17.4698C4.08499 15.5639 7.02273 13.6107 11.112 13.6107C15.2012 13.6107 18.1389 15.5639 20.0095 17.4698C20.6279 18.0999 21.1337 18.7254 21.5339 19.2805C22.3299 20.3843 21.8543 21.833 20.6826 22.4781C19.5546 23.0991 18.1412 22.79 17.3754 21.7548L16.913 21.1297C15.6501 19.8447 13.7685 18.6082 11.112 18.6082C8.45189 18.6082 6.5688 19.848 5.3059 21.1349C5.13504 21.309 4.9186 21.5541 4.69261 21.8208C3.91294 22.7409 2.56289 22.9724 1.54684 22.3137C0.47375 21.618 0.161514 20.1748 0.865179 19.1052C0.874833 19.0905 0.883936 19.0763 0.89346 19.0614C0.898283 19.0539 0.903214 19.0462 0.90838 19.0382C1.00476 18.8886 1.40953 18.29 2.21445 17.4698ZM11.112 13.8189C7.09513 13.8189 4.20773 15.736 2.363 17.6156C1.5691 18.4246 1.17298 19.0118 1.08334 19.1509C1.0789 19.1578 1.07434 19.1649 1.06969 19.1722C1.05975 19.1877 1.04942 19.2038 1.03906 19.2196C0.399655 20.1915 0.682702 21.5054 1.66007 22.139C2.58417 22.7381 3.81864 22.5302 4.53382 21.6863C4.76067 21.4186 4.98104 21.1687 5.15735 20.9891C6.44629 19.6757 8.37974 18.4001 11.112 18.4001C13.8442 18.4001 15.7776 19.6757 17.0666 20.9891L17.0717 20.9942L17.5428 21.6311C18.2466 22.5824 19.5455 22.8665 20.5822 22.2957C21.6654 21.6994 22.0746 20.3862 21.3651 19.4022C20.9701 18.8544 20.4709 18.2372 19.8609 17.6156C18.0162 15.736 15.1288 13.8189 11.112 13.8189Z" fill="url(#paint1_linear_649_10677)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter2_dii_649_10677)">
<path d="M5.5459 5.77461C5.5459 2.58538 8.13128 0 11.3205 0C14.5097 0 17.0951 2.58538 17.0951 5.77461C17.0951 8.96383 14.5097 11.5492 11.3205 11.5492C8.13128 11.5492 5.5459 8.96383 5.5459 5.77461Z" fill="url(#paint2_linear_649_10677)"/>
</g>
<g filter="url(#filter3_dii_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M5.75404 5.77461C5.75404 8.84888 8.24623 11.3411 11.3205 11.3411C14.3948 11.3411 16.887 8.84888 16.887 5.77461C16.887 2.70033 14.3948 0.208141 11.3205 0.208141C8.24623 0.208141 5.75404 2.70033 5.75404 5.77461ZM11.3205 0C8.13128 0 5.5459 2.58538 5.5459 5.77461C5.5459 8.96384 8.13128 11.5492 11.3205 11.5492C14.5097 11.5492 17.0951 8.96384 17.0951 5.77461C17.0951 2.58538 14.5097 0 11.3205 0Z" fill="url(#paint3_linear_649_10677)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter4_dii_649_10677)">
<path d="M42.6237 19.0945C42.7167 18.9501 43.1171 18.3572 43.9166 17.5427C45.7742 15.6499 48.6868 13.7147 52.7398 13.7147C56.7928 13.7147 59.7054 15.6499 61.563 17.5427C62.1772 18.1685 62.6798 18.7898 63.0774 19.3413C63.8301 20.3852 63.3877 21.7661 62.2602 22.3868C61.1779 22.9827 59.8217 22.6861 59.0869 21.6929L58.6201 21.0619C57.3442 19.7618 55.4359 18.5041 52.7398 18.5041C50.0436 18.5041 48.1354 19.7618 46.8595 21.0619C46.6859 21.2388 46.4675 21.4863 46.241 21.7535C45.4936 22.6355 44.2014 22.8552 43.2313 22.2263C42.2061 21.5616 41.9084 20.1831 42.58 19.1623C42.5948 19.1397 42.609 19.1172 42.6237 19.0945Z" fill="url(#paint4_linear_649_10677)"/>
</g>
<g filter="url(#filter5_dii_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M43.8424 17.4698C45.7129 15.5639 48.6507 13.6107 52.7399 13.6107C56.8291 13.6107 59.7669 15.5639 61.6374 17.4698C62.2558 18.0999 62.7617 18.7254 63.1619 19.2805C63.9578 20.3843 63.4822 21.833 62.3105 22.4781C61.1826 23.0991 59.7692 22.79 59.0034 21.7548L58.5409 21.1297C57.278 19.8447 55.3964 18.6082 52.7399 18.6082C50.0798 18.6082 48.1967 19.848 46.9338 21.1349C46.763 21.309 46.5465 21.5541 46.3205 21.8208C45.5409 22.7409 44.1908 22.9724 43.1748 22.3137C42.1017 21.618 41.7894 20.1748 42.4931 19.1052C42.5028 19.0905 42.5119 19.0763 42.5214 19.0614C42.5262 19.0539 42.5311 19.0462 42.5363 19.0382C42.6327 18.8886 43.0375 18.29 43.8424 17.4698ZM52.7399 13.8189C48.7231 13.8189 45.8357 15.736 43.9909 17.6156C43.197 18.4246 42.8009 19.0118 42.7113 19.1509C42.7068 19.1578 42.7023 19.1649 42.6976 19.1722C42.6877 19.1877 42.6773 19.2038 42.667 19.2196C42.0276 20.1915 42.3106 21.5054 43.288 22.139C44.2121 22.7381 45.4466 22.5302 46.1617 21.6863C46.3886 21.4186 46.609 21.1687 46.7853 20.9891C48.0742 19.6757 50.0077 18.4001 52.7399 18.4001C55.4721 18.4001 57.4056 19.6757 58.6945 20.9891L58.6996 20.9942L59.1707 21.6311C59.8745 22.5824 61.1735 22.8665 62.2101 22.2957C63.2933 21.6994 63.7026 20.3862 62.993 19.4022C62.598 18.8544 62.0988 18.2372 61.4889 17.6156C59.6441 15.736 56.7567 13.8189 52.7399 13.8189Z" fill="url(#paint5_linear_649_10677)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter6_dii_649_10677)">
<path d="M47.1738 5.77461C47.1738 2.58538 49.7592 0 52.9484 0C56.1377 0 58.723 2.58538 58.723 5.77461C58.723 8.96383 56.1377 11.5492 52.9484 11.5492C49.7592 11.5492 47.1738 8.96383 47.1738 5.77461Z" fill="url(#paint6_linear_649_10677)"/>
</g>
<g filter="url(#filter7_dii_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M47.382 5.77461C47.382 8.84888 49.8742 11.3411 52.9484 11.3411C56.0227 11.3411 58.5149 8.84888 58.5149 5.77461C58.5149 2.70033 56.0227 0.208141 52.9484 0.208141C49.8742 0.208141 47.382 2.70033 47.382 5.77461ZM52.9484 0C49.7592 0 47.1738 2.58538 47.1738 5.77461C47.1738 8.96384 49.7592 11.5492 52.9484 11.5492C56.1377 11.5492 58.723 8.96384 58.723 5.77461C58.723 2.58538 56.1377 0 52.9484 0Z" fill="url(#paint7_linear_649_10677)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter8_dii_649_10677)">
<path d="M42.6237 59.1616C42.7167 59.0173 43.1171 58.4244 43.9166 57.6098C45.7742 55.717 48.6868 53.7819 52.7398 53.7819C56.7928 53.7819 59.7054 55.717 61.563 57.6098C62.1772 58.2356 62.6798 58.857 63.0774 59.4084C63.8301 60.4524 63.3877 61.8332 62.2602 62.454C61.1779 63.0498 59.8217 62.7533 59.0869 61.76L58.6201 61.129C57.3442 59.829 55.4359 58.5712 52.7398 58.5712C50.0436 58.5712 48.1354 59.829 46.8595 61.129C46.6859 61.3059 46.4675 61.5534 46.241 61.8206C45.4936 62.7026 44.2014 62.9223 43.2313 62.2934C42.2061 61.6288 41.9084 60.2502 42.58 59.2295C42.5948 59.2069 42.609 59.1843 42.6237 59.1616Z" fill="url(#paint8_linear_649_10677)"/>
</g>
<g filter="url(#filter9_dii_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M43.8424 57.537C45.7129 55.631 48.6507 53.6779 52.7399 53.6779C56.8291 53.6779 59.7669 55.631 61.6374 57.537C62.2558 58.1671 62.7617 58.7926 63.1619 59.3476C63.9578 60.4515 63.4822 61.9001 62.3105 62.5452C61.1826 63.1662 59.7692 62.8571 59.0034 61.822L58.5409 61.1968C57.278 59.9119 55.3964 58.6753 52.7399 58.6753C50.0798 58.6753 48.1967 59.9152 46.9338 61.202C46.763 61.3761 46.5465 61.6213 46.3205 61.888C45.5409 62.808 44.1908 63.0395 43.1748 62.3808C42.1017 61.6851 41.7894 60.2419 42.4931 59.1723C42.5028 59.1577 42.5119 59.1434 42.5214 59.1286C42.5262 59.121 42.5311 59.1133 42.5363 59.1053C42.6327 58.9557 43.0375 58.3572 43.8424 57.537ZM52.7399 53.886C48.7231 53.886 45.8357 55.8031 43.9909 57.6828C43.197 58.4917 42.8009 59.0789 42.7113 59.218C42.7068 59.2249 42.7023 59.2321 42.6976 59.2393C42.6877 59.2549 42.6773 59.271 42.667 59.2867C42.0276 60.2586 42.3106 61.5725 43.288 62.2062C44.2121 62.8053 45.4466 62.5973 46.1617 61.7534C46.3886 61.4857 46.609 61.2359 46.7853 61.0562C48.0742 59.7429 50.0077 58.4672 52.7399 58.4672C55.4721 58.4672 57.4056 59.7429 58.6945 61.0562L58.6996 61.0614L59.1707 61.6982C59.8745 62.6495 61.1735 62.9336 62.2101 62.3629C63.2933 61.7665 63.7026 60.4534 62.993 59.4693C62.598 58.9215 62.0988 58.3043 61.4889 57.6828C59.6441 55.8031 56.7567 53.886 52.7399 53.886Z" fill="url(#paint9_linear_649_10677)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter10_dii_649_10677)">
<path d="M47.1738 45.8417C47.1738 42.6525 49.7592 40.0671 52.9484 40.0671C56.1377 40.0671 58.723 42.6525 58.723 45.8417C58.723 49.031 56.1377 51.6164 52.9484 51.6164C49.7592 51.6164 47.1738 49.031 47.1738 45.8417Z" fill="url(#paint10_linear_649_10677)"/>
</g>
<g filter="url(#filter11_dii_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M47.382 45.8417C47.382 48.916 49.8742 51.4082 52.9484 51.4082C56.0227 51.4082 58.5149 48.916 58.5149 45.8417C58.5149 42.7675 56.0227 40.2753 52.9484 40.2753C49.8742 40.2753 47.382 42.7675 47.382 45.8417ZM52.9484 40.0671C49.7592 40.0671 47.1738 42.6525 47.1738 45.8417C47.1738 49.031 49.7592 51.6164 52.9484 51.6164C56.1377 51.6164 58.723 49.031 58.723 45.8417C58.723 42.6525 56.1377 40.0671 52.9484 40.0671Z" fill="url(#paint11_linear_649_10677)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter12_dii_649_10677)">
<path d="M0.995762 59.1616C1.08877 59.0173 1.48921 58.4244 2.28863 57.6098C4.14626 55.717 7.05883 53.7819 11.1119 53.7819C15.1649 53.7819 18.0775 55.717 19.9351 57.6098C20.5493 58.2356 21.0518 58.857 21.4494 59.4084C22.2022 60.4524 21.7598 61.8332 20.6323 62.454C19.55 63.0498 18.1938 62.7533 17.459 61.76L16.9922 61.129C15.7163 59.829 13.808 58.5712 11.1119 58.5712C8.41571 58.5712 6.50744 59.829 5.23152 61.129C5.05794 61.3059 4.83954 61.5534 4.61311 61.8206C3.86569 62.7026 2.57343 62.9223 1.60336 62.2934C0.578127 61.6288 0.280485 60.2502 0.952021 59.2295C0.966891 59.2069 0.981107 59.1843 0.995762 59.1616Z" fill="url(#paint12_linear_649_10677)"/>
</g>
<g filter="url(#filter13_dii_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M2.21445 57.537C4.08499 55.631 7.02273 53.6779 11.112 53.6779C15.2012 53.6779 18.1389 55.631 20.0095 57.537C20.6279 58.1671 21.1337 58.7926 21.5339 59.3476C22.3299 60.4515 21.8543 61.9001 20.6826 62.5452C19.5546 63.1662 18.1412 62.8571 17.3754 61.822L16.913 61.1968C15.6501 59.9119 13.7685 58.6753 11.112 58.6753C8.45189 58.6753 6.5688 59.9152 5.3059 61.202C5.13504 61.3761 4.9186 61.6213 4.69261 61.888C3.91294 62.808 2.56289 63.0395 1.54684 62.3808C0.47375 61.6851 0.161514 60.2419 0.865179 59.1723C0.874833 59.1577 0.883936 59.1434 0.89346 59.1286C0.898283 59.121 0.903214 59.1133 0.90838 59.1053C1.00476 58.9557 1.40953 58.3572 2.21445 57.537ZM11.112 53.886C7.09513 53.886 4.20773 55.8031 2.363 57.6828C1.5691 58.4917 1.17298 59.0789 1.08334 59.218C1.0789 59.2249 1.07434 59.2321 1.06969 59.2393C1.05975 59.2549 1.04942 59.271 1.03906 59.2867C0.399655 60.2586 0.682702 61.5725 1.66007 62.2062C2.58417 62.8053 3.81864 62.5973 4.53382 61.7534C4.76067 61.4857 4.98104 61.2359 5.15735 61.0562C6.44629 59.7429 8.37974 58.4672 11.112 58.4672C13.8442 58.4672 15.7776 59.7429 17.0666 61.0562L17.0717 61.0614L17.5428 61.6982C18.2466 62.6495 19.5455 62.9336 20.5822 62.3629C21.6654 61.7665 22.0746 60.4534 21.3651 59.4693C20.9701 58.9215 20.4709 58.3043 19.8609 57.6828C18.0162 55.8031 15.1288 53.886 11.112 53.886Z" fill="url(#paint13_linear_649_10677)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter14_dii_649_10677)">
<path d="M5.5459 45.8417C5.5459 42.6525 8.13128 40.0671 11.3205 40.0671C14.5097 40.0671 17.0951 42.6525 17.0951 45.8417C17.0951 49.031 14.5097 51.6164 11.3205 51.6164C8.13128 51.6164 5.5459 49.031 5.5459 45.8417Z" fill="url(#paint14_linear_649_10677)"/>
</g>
<g filter="url(#filter15_dii_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M5.75404 45.8417C5.75404 48.916 8.24623 51.4082 11.3205 51.4082C14.3948 51.4082 16.887 48.916 16.887 45.8417C16.887 42.7675 14.3948 40.2753 11.3205 40.2753C8.24623 40.2753 5.75404 42.7675 5.75404 45.8417ZM11.3205 40.0671C8.13128 40.0671 5.5459 42.6525 5.5459 45.8417C5.5459 49.031 8.13128 51.6164 11.3205 51.6164C14.5097 51.6164 17.0951 49.031 17.0951 45.8417C17.0951 42.6525 14.5097 40.0671 11.3205 40.0671Z" fill="url(#paint15_linear_649_10677)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter16_d_649_10677)">
<path d="M21.7924 43.5426C20.7763 42.5266 20.7763 40.8792 21.7924 39.8632L36.8681 24.7875C37.8841 23.7714 39.5314 23.7714 40.5475 24.7875C41.5635 25.8035 41.5635 27.4509 40.5475 28.4669L25.4718 43.5426C24.4557 44.5586 22.8084 44.5587 21.7924 43.5426Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter17_d_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M37.0152 24.9346L21.9395 40.0103C21.0048 40.9451 21.0048 42.4607 21.9395 43.3954C22.8743 44.3302 24.3899 44.3302 25.3246 43.3954L40.4003 28.3197C41.3351 27.385 41.3351 25.8694 40.4003 24.9346C39.4656 23.9999 37.95 23.9999 37.0152 24.9346ZM21.7924 39.8632C20.7763 40.8792 20.7763 42.5266 21.7924 43.5426C22.8084 44.5587 24.4557 44.5586 25.4718 43.5426L40.5475 28.4669C41.5635 27.4509 41.5635 25.8035 40.5475 24.7875C39.5314 23.7714 37.8841 23.7714 36.8681 24.7875L21.7924 39.8632Z" fill="url(#paint16_linear_649_10677)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter18_d_649_10677)">
<path d="M21.7924 24.7877C20.7763 25.8038 20.7763 27.4511 21.7924 28.4672L36.8681 43.5429C37.8841 44.5589 39.5314 44.5589 40.5475 43.5429C41.5635 42.5268 41.5635 40.8795 40.5475 39.8634L25.4718 24.7877C24.4557 23.7717 22.8084 23.7717 21.7924 24.7877Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter19_d_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M37.0152 43.3957L21.9395 28.32C21.0048 27.3852 21.0048 25.8697 21.9395 24.9349C22.8743 24.0001 24.3899 24.0001 25.3246 24.9349L40.4003 40.0106C41.3351 40.9454 41.3351 42.4609 40.4003 43.3957C39.4656 44.3304 37.95 44.3304 37.0152 43.3957ZM21.7924 28.4672C20.7763 27.4511 20.7763 25.8038 21.7924 24.7877C22.8084 23.7717 24.4557 23.7717 25.4718 24.7877L40.5475 39.8634C41.5635 40.8795 41.5635 42.5268 40.5475 43.5429C39.5314 44.5589 37.8841 44.5589 36.8681 43.5429L21.7924 28.4672Z" fill="url(#paint17_linear_649_10677)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter20_bii_649_10677)">
<path d="M24.7104 33.5627C24.7104 29.9705 27.6226 27.0583 31.2148 27.0583C34.8071 27.0583 37.7192 29.9705 37.7192 33.5627C37.7192 37.155 34.8071 40.0671 31.2148 40.0671C27.6226 40.0671 24.7104 37.155 24.7104 33.5627Z" fill="#005EF5" fillOpacity="0.2"/>
</g>
<g filter="url(#filter21_bii_649_10677)">
<path fillRule="evenodd" clipRule="evenodd" d="M24.9186 33.5627C24.9186 37.0401 27.7375 39.859 31.2148 39.859C34.6922 39.859 37.5111 37.0401 37.5111 33.5627C37.5111 30.0854 34.6922 27.2665 31.2148 27.2665C27.7375 27.2665 24.9186 30.0854 24.9186 33.5627ZM31.2148 27.0583C27.6226 27.0583 24.7104 29.9705 24.7104 33.5627C24.7104 37.155 27.6226 40.0671 31.2148 40.0671C34.8071 40.0671 37.7192 37.155 37.7192 33.5627C37.7192 29.9705 34.8071 27.0583 31.2148 27.0583Z" fill="#005EF5" fillOpacity="0.2"/>
</g>
</g>
<defs>
<filter id="filter0_dii_649_10677" x="-1.40771" y="11.7147" width="25.2305" height="13.9781" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter1_dii_649_10677" x="-1.51172" y="11.6107" width="25.439" height="14.1864" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter2_dii_649_10677" x="3.5459" y="-2" width="15.5493" height="16.5492" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter3_dii_649_10677" x="3.5459" y="-2" width="15.5493" height="16.5492" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter4_dii_649_10677" x="40.2202" y="11.7147" width="25.2305" height="13.9781" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter5_dii_649_10677" x="40.1162" y="11.6107" width="25.439" height="14.1864" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter6_dii_649_10677" x="45.1738" y="-2" width="15.5493" height="16.5492" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter7_dii_649_10677" x="45.1738" y="-2" width="15.5493" height="16.5492" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter8_dii_649_10677" x="40.2202" y="51.7819" width="25.2305" height="13.9781" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter9_dii_649_10677" x="40.1162" y="51.6779" width="25.439" height="14.1864" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter10_dii_649_10677" x="45.1738" y="38.0671" width="15.5493" height="16.5492" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter11_dii_649_10677" x="45.1738" y="38.0671" width="15.5493" height="16.5492" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter12_dii_649_10677" x="-1.40771" y="51.7819" width="25.2305" height="13.9781" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter13_dii_649_10677" x="-1.51172" y="51.6779" width="25.439" height="14.1864" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter14_dii_649_10677" x="3.5459" y="38.0671" width="15.5493" height="16.5492" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter15_dii_649_10677" x="3.5459" y="38.0671" width="15.5493" height="16.5492" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter16_d_649_10677" x="19.0303" y="23.0254" width="24.2793" height="24.2793" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
</filter>
<filter id="filter17_d_649_10677" x="19.0303" y="23.0254" width="24.2793" height="24.2793" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
</filter>
<filter id="filter18_d_649_10677" x="19.0303" y="23.0256" width="24.2793" height="24.2793" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
</filter>
<filter id="filter19_d_649_10677" x="19.0303" y="23.0256" width="24.2793" height="24.2793" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_649_10677" result="shape"/>
</filter>
<filter id="filter20_bii_649_10677" x="16.7104" y="19.0583" width="29.0088" height="29.0088" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<filter id="filter21_bii_649_10677" x="16.7104" y="19.0583" width="29.0088" height="29.0088" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_649_10677"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_649_10677" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_649_10677"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_649_10677" result="effect3_innerShadow_649_10677"/>
</filter>
<linearGradient id="paint0_linear_649_10677" x1="0.283825" y1="14.4546" x2="22.5153" y2="23.6092" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint1_linear_649_10677" x1="11.1119" y1="13.7148" x2="11.1119" y2="21.3877" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_649_10677" x1="5.9225" y1="0.866191" x2="18.5331" y2="3.08398" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint3_linear_649_10677" x1="11.3205" y1="0" x2="11.3205" y2="8.98272" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_649_10677" x1="41.9118" y1="14.4546" x2="64.1433" y2="23.6092" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint5_linear_649_10677" x1="52.7398" y1="13.7148" x2="52.7398" y2="21.3877" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_649_10677" x1="47.5504" y1="0.866191" x2="60.161" y2="3.08398" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint7_linear_649_10677" x1="52.9484" y1="0" x2="52.9484" y2="8.98272" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_649_10677" x1="41.9118" y1="54.5217" x2="64.1433" y2="63.6764" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint9_linear_649_10677" x1="52.7398" y1="53.7819" x2="52.7398" y2="61.4548" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint10_linear_649_10677" x1="47.5504" y1="40.9333" x2="60.161" y2="43.1511" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint11_linear_649_10677" x1="52.9484" y1="40.0671" x2="52.9484" y2="49.0499" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint12_linear_649_10677" x1="0.283825" y1="54.5217" x2="22.5153" y2="63.6764" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint13_linear_649_10677" x1="11.1119" y1="53.7819" x2="11.1119" y2="61.4548" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint14_linear_649_10677" x1="5.9225" y1="40.9333" x2="18.5331" y2="43.1511" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint15_linear_649_10677" x1="11.3205" y1="40.0671" x2="11.3205" y2="49.0499" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_649_10677" x1="29.3302" y1="32.3253" x2="32.192" y2="35.1871" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint17_linear_649_10677" x1="29.3302" y1="36.005" x2="32.192" y2="33.1432" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<clipPath id="clip0_649_10677">
<rect width="64" height="64" fill="white"/>
</clipPath>
</defs>
</svg>
)
