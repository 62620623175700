export const ComputerSvgDark = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_657_12325)">
<path fillRule="evenodd" clipRule="evenodd" d="M27.5412 43.6758C26.2332 43.6758 25.3984 45.0716 26.0173 46.2239L28.6093 51.0504C28.9187 51.6265 28.5013 52.3244 27.8473 52.3244H21.4056C20.5697 52.3244 19.8921 53.0021 19.8921 53.8379C19.8921 54.6738 20.5697 55.3515 21.4056 55.3515H32.278H42.1624C42.9982 55.3515 43.6759 54.6738 43.6759 53.8379C43.6759 53.0021 42.9982 52.3244 42.1624 52.3244H36.4848C35.8532 52.3244 35.4346 51.6694 35.6999 51.0962L37.9981 46.1322C38.5288 44.9859 37.6916 43.6758 36.4284 43.6758H27.5412Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter1_d_657_12325)">
<path fillRule="evenodd" clipRule="evenodd" d="M27.5412 43.8488C26.364 43.8488 25.6127 45.1049 26.1697 46.1421L28.7617 50.9685C29.133 51.6599 28.6321 52.4974 27.8473 52.4974H21.4056C20.6652 52.4974 20.0651 53.0976 20.0651 53.8379C20.0651 54.5783 20.6652 55.1785 21.4056 55.1785H42.1624C42.9027 55.1785 43.5029 54.5783 43.5029 53.8379C43.5029 53.0976 42.9027 52.4974 42.1624 52.4974H36.4848C35.7268 52.4974 35.2246 51.7113 35.543 51.0235L37.8411 46.0595C38.3188 45.0278 37.5653 43.8488 36.4284 43.8488H27.5412ZM26.0173 46.2239C25.3984 45.0716 26.2332 43.6758 27.5412 43.6758H36.4284C37.6916 43.6758 38.5288 44.9859 37.9981 46.1322L35.6999 51.0962C35.4346 51.6694 35.8532 52.3244 36.4848 52.3244H42.1624C42.9982 52.3244 43.6759 53.0021 43.6759 53.8379C43.6759 54.6738 42.9982 55.3515 42.1624 55.3515H21.4056C20.5697 55.3515 19.8921 54.6738 19.8921 53.8379C19.8921 53.0021 20.5697 52.3244 21.4056 52.3244H27.8473C28.5013 52.3244 28.9187 51.6265 28.6093 51.0504L26.0173 46.2239Z" fill="url(#paint0_linear_657_12325)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter2_ii_657_12325)">
<path d="M7.78369 19.8917C7.78369 17.4738 7.78369 16.2649 8.17869 15.3113C8.70537 14.0398 9.71557 13.0296 10.9871 12.5029C11.9407 12.1079 13.1496 12.1079 15.5675 12.1079H48.4323C50.8502 12.1079 52.0591 12.1079 53.0128 12.5029C54.2842 13.0296 55.2945 14.0398 55.8211 15.3113C56.2161 16.2649 56.2161 17.4738 56.2161 19.8917V19.8917C56.2161 22.3096 56.2161 23.5185 55.8211 24.4721C55.2945 25.7436 54.2842 26.7538 53.0128 27.2805C52.0591 27.6755 50.8502 27.6755 48.4323 27.6755H15.5675C13.1496 27.6755 11.9407 27.6755 10.9871 27.2805C9.71557 26.7538 8.70537 25.7436 8.17869 24.4721C7.78369 23.5185 7.78369 22.3096 7.78369 19.8917V19.8917Z" fill="url(#paint1_linear_657_12325)"/>
</g>
<g filter="url(#filter3_ii_657_12325)">
<path fillRule="evenodd" clipRule="evenodd" d="M48.4323 12.2809H15.5675C14.3562 12.2809 13.4564 12.281 12.74 12.3299C12.0251 12.3786 11.5055 12.4754 11.0533 12.6627C9.82414 13.1718 8.84762 14.1484 8.3385 15.3775C8.15118 15.8297 8.05441 16.3493 8.00564 17.0642C7.95676 17.7806 7.95666 18.6804 7.95666 19.8917C7.95666 21.103 7.95676 22.0028 8.00564 22.7192C8.05441 23.4341 8.15118 23.9537 8.3385 24.4059C8.84762 25.635 9.82414 26.6116 11.0533 27.1207C11.5055 27.308 12.0251 27.4048 12.74 27.4535C13.4564 27.5024 14.3562 27.5025 15.5675 27.5025H48.4323C49.6436 27.5025 50.5434 27.5024 51.2599 27.4535C51.9747 27.4048 52.4943 27.308 52.9466 27.1207C54.1757 26.6116 55.1522 25.635 55.6613 24.4059C55.8486 23.9537 55.9454 23.4341 55.9942 22.7192C56.0431 22.0028 56.0432 21.103 56.0432 19.8917C56.0432 18.6804 56.0431 17.7806 55.9942 17.0642C55.9454 16.3493 55.8486 15.8297 55.6613 15.3775C55.1522 14.1484 54.1757 13.1718 52.9466 12.6627C52.4943 12.4754 51.9747 12.3786 51.2599 12.3299C50.5434 12.281 49.6436 12.2809 48.4323 12.2809ZM8.17869 15.3113C7.78369 16.2649 7.78369 17.4738 7.78369 19.8917C7.78369 22.3096 7.78369 23.5185 8.17869 24.4721C8.70537 25.7436 9.71557 26.7538 10.9871 27.2805C11.9407 27.6755 13.1496 27.6755 15.5675 27.6755H48.4323C50.8502 27.6755 52.0591 27.6755 53.0128 27.2805C54.2843 26.7538 55.2945 25.7436 55.8211 24.4721C56.2161 23.5185 56.2161 22.3096 56.2161 19.8917C56.2161 17.4738 56.2161 16.2649 55.8211 15.3113C55.2945 14.0398 54.2843 13.0296 53.0128 12.5029C52.0591 12.1079 50.8502 12.1079 48.4323 12.1079H15.5675C13.1496 12.1079 11.9407 12.1079 10.9871 12.5029C9.71557 13.0296 8.70537 14.0398 8.17869 15.3113Z" fill="url(#paint2_linear_657_12325)"/>
</g>
<g filter="url(#filter4_bii_657_12325)">
<path d="M7.78369 31.1352C7.78369 26.2428 7.78369 23.7966 9.30357 22.2767C10.8234 20.7568 13.2697 20.7568 18.1621 20.7568H45.8377C50.7302 20.7568 53.1764 20.7568 54.6962 22.2767C56.2161 23.7966 56.2161 26.2428 56.2161 31.1352V40.2163C56.2161 45.1087 56.2161 47.5549 54.6962 49.0748C53.1764 50.5947 50.7302 50.5947 45.8377 50.5947H18.1621C13.2697 50.5947 10.8234 50.5947 9.30357 49.0748C7.78369 47.5549 7.78369 45.1087 7.78369 40.2163V31.1352Z" fill="url(#paint3_linear_657_12325)" fillOpacity="0.24"/>
</g>
<g filter="url(#filter5_bii_657_12325)">
<path fillRule="evenodd" clipRule="evenodd" d="M45.8377 20.9298H18.1621C15.711 20.9298 13.8901 20.9302 12.4912 21.1183C11.0973 21.3057 10.1483 21.6766 9.42588 22.399C8.70344 23.1215 8.33251 24.0704 8.14511 25.4643C7.95703 26.8632 7.95666 28.6841 7.95666 31.1352V40.2163C7.95666 42.6674 7.95703 44.4883 8.14511 45.8872C8.33251 47.2811 8.70344 48.23 9.42588 48.9525C10.1483 49.6749 11.0973 50.0459 12.4912 50.2333C13.8901 50.4213 15.711 50.4217 18.1621 50.4217H45.8377C48.2888 50.4217 50.1098 50.4213 51.5086 50.2333C52.9026 50.0459 53.8515 49.6749 54.5739 48.9525C55.2964 48.23 55.6673 47.2811 55.8547 45.8872C56.0428 44.4883 56.0432 42.6674 56.0432 40.2163V31.1352C56.0432 28.6841 56.0428 26.8632 55.8547 25.4643C55.6673 24.0704 55.2964 23.1215 54.5739 22.399C53.8515 21.6766 52.9026 21.3057 51.5086 21.1183C50.1098 20.9302 48.2888 20.9298 45.8377 20.9298ZM9.30357 22.2767C7.78369 23.7966 7.78369 26.2428 7.78369 31.1352V40.2163C7.78369 45.1087 7.78369 47.5549 9.30357 49.0748C10.8234 50.5947 13.2697 50.5947 18.1621 50.5947H45.8377C50.7302 50.5947 53.1764 50.5947 54.6962 49.0748C56.2161 47.5549 56.2161 45.1087 56.2161 40.2163V31.1352C56.2161 26.2428 56.2161 23.7966 54.6962 22.2767C53.1764 20.7568 50.7302 20.7568 45.8377 20.7568H18.1621C13.2697 20.7568 10.8234 20.7568 9.30357 22.2767Z" fill="url(#paint4_linear_657_12325)"/>
</g>
<g filter="url(#filter6_d_657_12325)">
<path d="M11.2432 16.8648C11.2432 15.6707 12.2112 14.7026 13.4053 14.7026V14.7026C14.5995 14.7026 15.5675 15.6707 15.5675 16.8648V16.8648C15.5675 18.0589 14.5995 19.027 13.4053 19.027V19.027C12.2112 19.027 11.2432 18.0589 11.2432 16.8648V16.8648Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter7_d_657_12325)">
<path fillRule="evenodd" clipRule="evenodd" d="M11.4161 16.8648C11.4161 17.9634 12.3067 18.854 13.4053 18.854C14.5039 18.854 15.3945 17.9634 15.3945 16.8648C15.3945 15.7662 14.5039 14.8756 13.4053 14.8756C12.3067 14.8756 11.4161 15.7662 11.4161 16.8648ZM13.4053 14.7026C12.2112 14.7026 11.2432 15.6707 11.2432 16.8648C11.2432 18.0589 12.2112 19.027 13.4053 19.027C14.5995 19.027 15.5675 18.0589 15.5675 16.8648C15.5675 15.6707 14.5995 14.7026 13.4053 14.7026Z" fill="url(#paint5_linear_657_12325)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter8_d_657_12325)">
<path d="M17.2974 16.8648C17.2974 15.6707 18.2654 14.7026 19.4595 14.7026V14.7026C20.6537 14.7026 21.6217 15.6707 21.6217 16.8648V16.8648C21.6217 18.0589 20.6537 19.027 19.4595 19.027V19.027C18.2654 19.027 17.2974 18.0589 17.2974 16.8648V16.8648Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter9_d_657_12325)">
<path fillRule="evenodd" clipRule="evenodd" d="M17.4703 16.8648C17.4703 17.9634 18.3609 18.854 19.4595 18.854C20.5581 18.854 21.4487 17.9634 21.4487 16.8648C21.4487 15.7662 20.5581 14.8756 19.4595 14.8756C18.3609 14.8756 17.4703 15.7662 17.4703 16.8648ZM19.4595 14.7026C18.2654 14.7026 17.2974 15.6707 17.2974 16.8648C17.2974 18.0589 18.2654 19.027 19.4595 19.027C20.6537 19.027 21.6217 18.0589 21.6217 16.8648C21.6217 15.6707 20.6537 14.7026 19.4595 14.7026Z" fill="url(#paint6_linear_657_12325)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter10_d_657_12325)">
<path d="M23.3516 16.8648C23.3516 15.6707 24.3196 14.7026 25.5137 14.7026V14.7026C26.7079 14.7026 27.6759 15.6707 27.6759 16.8648V16.8648C27.6759 18.0589 26.7079 19.027 25.5137 19.027V19.027C24.3196 19.027 23.3516 18.0589 23.3516 16.8648V16.8648Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter11_d_657_12325)">
<path fillRule="evenodd" clipRule="evenodd" d="M23.5245 16.8648C23.5245 17.9634 24.4151 18.854 25.5137 18.854C26.6123 18.854 27.5029 17.9634 27.5029 16.8648C27.5029 15.7662 26.6123 14.8756 25.5137 14.8756C24.4151 14.8756 23.5245 15.7662 23.5245 16.8648ZM25.5137 14.7026C24.3196 14.7026 23.3516 15.6707 23.3516 16.8648C23.3516 18.0589 24.3196 19.027 25.5137 19.027C26.7079 19.027 27.6759 18.0589 27.6759 16.8648C27.6759 15.6707 26.7079 14.7026 25.5137 14.7026Z" fill="url(#paint7_linear_657_12325)" shapeRendering="crispEdges"/>
</g>
<g filter="url(#filter12_d_657_12325)">
<path fillRule="evenodd" clipRule="evenodd" d="M49.0656 32.8743C49.8405 32.7288 50.0326 31.7072 49.3634 31.2902L39.6206 25.2193C39.0333 24.8534 38.2755 25.2909 38.2988 25.9824L38.6849 37.4555C38.7114 38.2434 39.6923 38.5879 40.2057 37.9896L41.6973 36.2511C42.0856 35.7986 42.8045 35.8655 43.1027 36.3819L44.813 39.3442C45.0518 39.7578 45.5807 39.8996 45.9944 39.6607L47.6185 38.723C48.0322 38.4842 48.1739 37.9553 47.9351 37.5416L46.2248 34.5793C45.9267 34.0629 46.2282 33.4068 46.8142 33.2968L49.0656 32.8743Z" fill="#E0E0E0"/>
</g>
<g filter="url(#filter13_d_657_12325)">
<path fillRule="evenodd" clipRule="evenodd" d="M49.272 31.437L39.5291 25.3661C39.0593 25.0734 38.4531 25.4234 38.4717 25.9766L38.8578 37.4496C38.879 38.08 39.6637 38.3556 40.0744 37.8769L41.566 36.1385C42.0319 35.5954 42.8947 35.6757 43.2525 36.2954L44.9628 39.2577C45.1538 39.5886 45.577 39.702 45.9079 39.5109L47.532 38.5732C47.863 38.3822 47.9764 37.959 47.7853 37.6281L46.075 34.6658C45.7172 34.0461 46.0791 33.2588 46.7823 33.1268L49.0337 32.7043C49.6536 32.5879 49.8073 31.7706 49.272 31.437ZM49.3634 31.2902C50.0326 31.7072 49.8405 32.7288 49.0656 32.8743L46.8142 33.2968C46.2282 33.4068 45.9267 34.0629 46.2248 34.5793L47.9351 37.5416C48.1739 37.9553 48.0322 38.4842 47.6185 38.723L45.9944 39.6607C45.5807 39.8996 45.0518 39.7578 44.813 39.3442L43.1027 36.3819C42.8045 35.8655 42.0856 35.7986 41.6973 36.2511L40.2057 37.9896C39.6923 38.5879 38.7114 38.2434 38.6849 37.4555L38.2988 25.9824C38.2755 25.2909 39.0333 24.8534 39.6206 25.2193L49.3634 31.2902Z" fill="url(#paint8_linear_657_12325)" shapeRendering="crispEdges"/>
</g>
<defs>
<filter id="filter0_d_657_12325" x="17.8921" y="42.6758" width="27.7837" height="15.6758" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_657_12325" result="shape"/>
</filter>
<filter id="filter1_d_657_12325" x="17.8921" y="42.6758" width="27.7837" height="15.6758" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_657_12325" result="shape"/>
</filter>
<filter id="filter2_ii_657_12325" x="5.78369" y="10.1079" width="52.4326" height="19.5674" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_657_12325"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_657_12325" result="effect2_innerShadow_657_12325"/>
</filter>
<filter id="filter3_ii_657_12325" x="5.78369" y="10.1079" width="52.4326" height="19.5674" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_657_12325"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_657_12325" result="effect2_innerShadow_657_12325"/>
</filter>
<filter id="filter4_bii_657_12325" x="-0.216309" y="12.7568" width="64.4326" height="45.8379" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_657_12325" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_657_12325"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_657_12325" result="effect3_innerShadow_657_12325"/>
</filter>
<filter id="filter5_bii_657_12325" x="-0.216309" y="12.7568" width="64.4326" height="45.8379" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_657_12325" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2" dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_657_12325"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_657_12325" result="effect3_innerShadow_657_12325"/>
</filter>
<filter id="filter6_d_657_12325" x="9.24316" y="13.7026" width="8.32422" height="8.32422" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_657_12325" result="shape"/>
</filter>
<filter id="filter7_d_657_12325" x="9.24316" y="13.7026" width="8.32422" height="8.32422" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_657_12325" result="shape"/>
</filter>
<filter id="filter8_d_657_12325" x="15.2974" y="13.7026" width="8.32422" height="8.32422" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_657_12325" result="shape"/>
</filter>
<filter id="filter9_d_657_12325" x="15.2974" y="13.7026" width="8.32422" height="8.32422" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_657_12325" result="shape"/>
</filter>
<filter id="filter10_d_657_12325" x="21.3516" y="13.7026" width="8.32422" height="8.32422" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_657_12325" result="shape"/>
</filter>
<filter id="filter11_d_657_12325" x="21.3516" y="13.7026" width="8.32422" height="8.32422" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_657_12325" result="shape"/>
</filter>
<filter id="filter12_d_657_12325" x="37.2983" y="25.0869" width="13.4731" height="16.6899" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_657_12325" result="shape"/>
</filter>
<filter id="filter13_d_657_12325" x="37.2983" y="25.0869" width="13.4731" height="16.6899" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_657_12325"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_657_12325" result="shape"/>
</filter>
<linearGradient id="paint0_linear_657_12325" x1="19.8921" y1="53.8379" x2="38.3906" y2="53.8379" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_657_12325" x1="9.36301" y1="13.2755" x2="51.3212" y2="36.2326" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="#3787FF"/>
</linearGradient>
<linearGradient id="paint2_linear_657_12325" x1="-2.15227" y1="-44.7837" x2="10.4507" y2="24.1166" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_657_12325" x1="31.9999" y1="21.8998" x2="31.9999" y2="52.2568" gradientUnits="userSpaceOnUse">
<stop stopColor="#005EF5"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_657_12325" x1="2.5193" y1="18.0443" x2="16.5069" y2="64.1772" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="0.765625" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_657_12325" x1="13.4053" y1="14.7026" x2="13.4053" y2="18.066" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_657_12325" x1="19.4595" y1="14.7026" x2="19.4595" y2="18.066" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear_657_12325" x1="25.5137" y1="14.7026" x2="25.5137" y2="18.066" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_657_12325" x1="38.2443" y1="24.3617" x2="44.7515" y2="44.8159" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
</defs>
</svg>

)
