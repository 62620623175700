export const YouTubeSvg = () => (
  <svg
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.5" cy="17" r="16" stroke="#D5D5D5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4153 11.3626C24.1659 11.5604 24.7597 12.1429 24.9613 12.8791C25.331 14.2198 25.3422 17 25.3422 17C25.3422 17 25.3422 19.7912 24.9725 21.1209C24.7709 21.8571 24.1771 22.4396 23.4265 22.6374C22.0709 23 16.615 23 16.615 23C16.615 23 11.159 23 9.80344 22.6374C9.05283 22.4396 8.45906 21.8571 8.2574 21.1209C7.8877 19.7802 7.8877 17 7.8877 17C7.8877 17 7.8877 14.2198 8.2462 12.8901C8.44785 12.1538 9.04162 11.5714 9.79224 11.3736C11.1478 11.011 16.6038 11 16.6038 11C16.6038 11 22.0597 11 23.4153 11.3626ZM19.3923 17.0001L14.8662 19.5716V14.4287L19.3923 17.0001Z"
      fill="#8C8C8C"
    />
  </svg>
);
